import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { ConceptUtils } from '../../../../domain/utils/concept.utils';
import { GenericUtils } from '../../../../domain/utils/generic.utils';
import { ProductRuleSet } from '../../../../models';

export interface ConceptRuleSetsModalData {
  productRuleSets: ProductRuleSet[];
  selections: string[];
}

export interface SelectableRuleSetFamily {
  ruleSetFamilyId: string;
  name: string;
  ruleSets: SelectableRuleSet[];
  selected: boolean;
  visible: boolean;
  selectedRuleSetsCount: number;
}

export interface SelectableRuleSet {
  ruleSetId: string;
  name: string;
  visible: boolean;
  selected: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-concept-rule-sets-modal',
  templateUrl: './concept-rule-sets-modal.component.html',
  styleUrls: ['./concept-rule-sets-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConceptRuleSetsModalComponent implements OnInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '512px'
  };

  public templateData: TemplateModalData;
  public footNote: string;
  public isLoading$: Observable<boolean>;

  public ruleSetFamilies: SelectableRuleSetFamily[];
  public anyRuleSet = true;

  constructor(
    public dialogRef: MatDialogRef<ConceptRuleSetsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConceptRuleSetsModalData
  ) {
    this.templateData = {
      title: 'Rule Sets',
      hideClose: false,
      disableClose: false
    };
  }

  private retrieveRuleSets() {
    const selectableRuleSetFamilies: SelectableRuleSetFamily[] = [];
    this.data.productRuleSets?.forEach(item => {
      if (!item.ruleSet || !item.ruleSetFamily) {
        return;
      }
      const found = selectableRuleSetFamilies.find(obj => isEqual(obj.ruleSetFamilyId, item.ruleSetFamily.id));
      if (!found) {
        const ruleSets = [
          {
            ruleSetId: item.ruleSet.id,
            name: item.ruleSet.label,
            visible: true,
            selected: this.data.selections.some(obj => isEqual(obj, item.ruleSetFamily.id + '#' + item.ruleSet.id))
          }
        ];
        const ruleSetFamily = {
          ruleSetFamilyId: item.ruleSetFamily.id,
          name: item.ruleSetFamily.label,
          ruleSets: ruleSets,
          selected: ruleSets.every(obj => obj.selected),
          visible: true,
          selectedRuleSetsCount: 0
        };

        ruleSetFamily.selectedRuleSetsCount = ruleSetFamily.ruleSets.filter(obj => obj.selected).length;
        selectableRuleSetFamilies.push(ruleSetFamily);
      } else {
        found.ruleSets.push({
          ruleSetId: item.ruleSet.id,
          name: item.ruleSet.label,
          visible: true,
          selected: this.data.selections.some(obj => isEqual(obj, item.ruleSetFamily.id + '#' + item.ruleSet.id))
        });

        found.selected = found.ruleSets.every(obj => obj.selected);
        found.selectedRuleSetsCount = found.ruleSets.filter(obj => obj.selected).length;
      }
    });

    this.ruleSetFamilies = selectableRuleSetFamilies;

    this.ruleSetFamilies = GenericUtils.sortCollection(this.ruleSetFamilies, 'name');
    this.ruleSetFamilies.map(rsf => {
      rsf.ruleSets = GenericUtils.sortCollection(rsf.ruleSets, 'name');
    });

    this.processChanges();
  }

  ngOnInit() {
    this.retrieveRuleSets();
  }

  public setAll(checked: boolean, ruleSetFamily: SelectableRuleSetFamily) {
    ruleSetFamily.ruleSets.forEach(ruleSet => (ruleSet.selected = checked));
    ruleSetFamily.selected = checked;
    this.processChanges();
  }

  public someComplete(ruleSetFamily: SelectableRuleSetFamily): boolean {
    if (ruleSetFamily == null) {
      return false;
    }
    return ruleSetFamily.ruleSets.filter(ruleSet => ruleSet.selected).length > 0 && !ruleSetFamily.selected;
  }

  public updateAllComplete(ruleSetFamily: SelectableRuleSetFamily) {
    ruleSetFamily.selected = ruleSetFamily.ruleSets.every(ruleSet => ruleSet.selected);
    this.processChanges();
  }

  private resetFootnote() {
    if (this.anyRuleSet) {
      this.footNote = 'Assigning any Rule Set from any Rule Set Family.';
      return;
    }

    const numberOfSelectedProducts = this.ruleSetFamilies
      .flatMap(ruleSetFamily => ruleSetFamily.ruleSets)
      .filter(ruleSet => ruleSet.selected).length;
    const numberOfFamiliesWithSelectedProducts = this.ruleSetFamilies.filter(ruleSetFamily =>
      ruleSetFamily.ruleSets.find(ruleSet => ruleSet.selected)
    ).length;

    this.footNote =
      'Assigning ' +
      numberOfSelectedProducts +
      (numberOfSelectedProducts === 1 ? ' Rule Set ' : ' Rule Sets ') +
      'from ' +
      numberOfFamiliesWithSelectedProducts +
      (numberOfFamiliesWithSelectedProducts === 1 ? ' Rule Set Family.' : ' Different Rule Set Families.');
  }

  private getSelected(): string[] {
    const selectedIds = this.ruleSetFamilies.flatMap(ruleSetFamily => {
      return ruleSetFamily.ruleSets
        .filter(ruleSet => ruleSet.selected)
        .map(ruleSet => ruleSetFamily.ruleSetFamilyId + '#' + ruleSet.ruleSetId);
    });

    if (selectedIds.length === 0) {
      return [ConceptUtils.RULESET_ANY.id + '#' + ConceptUtils.RULESET_ANY.id];
    }

    return selectedIds;
  }

  public onConfirmation() {
    this.dialogRef.close(this.getSelected());
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

  public onSearch(value: string) {
    this.ruleSetFamilies
      .flatMap(ruleSetFamily => ruleSetFamily.ruleSets)
      .forEach(ruleSet => (ruleSet.visible = ruleSet.name.toLowerCase().includes(value.toLowerCase())));

    this.ruleSetFamilies.forEach(
      ruleSetFamily =>
        (ruleSetFamily.visible =
          !!ruleSetFamily.ruleSets.find(ruleSet => ruleSet.visible) ||
          ruleSetFamily.name.toLowerCase().includes(value.toLowerCase()))
    );
  }

  private processChanges() {
    this.anyRuleSet = !this.hasSelection();
    this.resetFootnote();
    this.ruleSetFamilies.forEach(
      ruleSetFamily =>
        (ruleSetFamily.selectedRuleSetsCount = ruleSetFamily.ruleSets.filter(ruleSet => ruleSet.selected).length)
    );
  }

  public hasSelection(): boolean {
    return (
      this.ruleSetFamilies?.filter(ruleSetFamily => ruleSetFamily.selected)?.length > 0 ||
      this.ruleSetFamilies?.flatMap(ruleSetFamily => ruleSetFamily.ruleSets)?.filter(ruleSet => ruleSet.selected)
        ?.length > 0
    );
  }

  public setAny(checked: boolean) {
    const hasSelection = this.hasSelection();

    if (!checked && !hasSelection) {
      this.anyRuleSet = true;
      return;
    }

    this.anyRuleSet = checked;

    if (this.anyRuleSet) {
      this.ruleSetFamilies.forEach(ruleSetFamily => {
        ruleSetFamily.selected = false;
        ruleSetFamily.ruleSets.forEach(ruleSet => (ruleSet.selected = false));
      });
    }

    this.processChanges();
  }

  public onEvent($event: MouseEvent) {
    $event.stopPropagation();
  }
}
