import { TaxonomyExportDataStateModel } from '../taxonomy-export-data.state';
import { StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { FileUtils } from '../../../utils/file.utils';
import { throwError } from 'rxjs';
import { ExportDataApiService } from '../../../../../generated/v3';
import { PartialConceptExport } from '../taxonomy-export-data.action';
import { TaxonomyExportDataMapper } from '../../../mappers/taxonomy-export-data-mapper';

export class TaxonomyExportDataStateUseCases {
  constructor(private exportDataApiService: ExportDataApiService) {}

  fullConceptExport(context: StateContext<TaxonomyExportDataStateModel>) {
    context.patchState({
      isPerformingFullExport: true
    });
    return this.exportDataApiService.performFullExport().pipe(
      tap(response => {
        context.patchState({
          isPerformingFullExport: false
        });
        FileUtils.downloadFile(response, 'fullExport');
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  partialConceptExport(context: StateContext<TaxonomyExportDataStateModel>, action: PartialConceptExport) {
    context.patchState({
      isPerformingPartialExport: true
    });
    const performConceptPartialExportCriteria = TaxonomyExportDataMapper.mapToPartialExportRequest(
      action.payload.type,
      action.payload.values
    );
    return this.exportDataApiService.performPartialExport(performConceptPartialExportCriteria).pipe(
      tap(response => {
        context.patchState({
          isPerformingPartialExport: false
        });
        FileUtils.downloadFile(response, 'partialExport');
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
