import { SearchCriteria } from '@apiax/web-commons';
import {
  ConceptRuleSearch,
  ConceptSearchOnRulesExportCriteria,
  ConceptSearchOnRulesRequest,
  RuleSearchRequestCriteria
} from '../../../generated/v3';
import { ConceptSearchResult } from '../../models';
import DocumentTypesEnum = RuleSearchRequestCriteria.DocumentTypesEnum;
import ActivationStatesEnum = RuleSearchRequestCriteria.ActivationStatesEnum;

export class TaxonomySearchOnRulesMapper {
  // Search

  static mapToConceptRuleSearchRequest(searchCriteria: SearchCriteria): ConceptSearchOnRulesRequest {
    return {
      page: searchCriteria.page,
      pageSize: searchCriteria.pageSize,
      criteria: {
        searchText: searchCriteria.searchFilter,
        ruleSetFamilyIds: searchCriteria.contentFilters['ruleSetFamilies'] || [],
        ruleSetIds: searchCriteria.contentFilters['ruleSets'] || [],
        jurisdictions: searchCriteria.contentFilters['jurisdictions'] || [],
        contentBy: searchCriteria.contentFilters['organizations'] || [],
        documentTypes: searchCriteria.contentFilters['documentTypes']?.map(v => this.mapToDocumentType(v)) || [],
        activationStates:
          searchCriteria.contentFilters['documentActivationStatus']?.map(v => this.mapToDocumentActivationStates(v)) ||
          [],
        versions: searchCriteria.contentFilters['documentVersions'] || []
      }
    };
  }

  static mapToDocumentType(value: string): DocumentTypesEnum {
    return DocumentTypesEnum[value];
  }

  static mapToDocumentActivationStates(value: string): ActivationStatesEnum {
    return ActivationStatesEnum[value];
  }

  // Export
  static mapToConceptSearchOnRulesExportResult(searchCriteria: SearchCriteria): ConceptSearchOnRulesExportCriteria {
    return {
      searchText: searchCriteria.searchFilter,
      ruleSetFamilyIds: searchCriteria.contentFilters['ruleSetFamilies'] || [],
      ruleSetIds: searchCriteria.contentFilters['ruleSets'] || [],
      jurisdictions: searchCriteria.contentFilters['jurisdictions'] || [],
      contentBy: searchCriteria.contentFilters['organizations'] || [],
      documentTypes: searchCriteria.contentFilters['documentTypes']?.map(v => this.mapToDocumentType(v)) || [],
      activationStates:
        searchCriteria.contentFilters['documentActivationStatus']?.map(v => this.mapToDocumentActivationStates(v)) ||
        [],
      versions: searchCriteria.contentFilters['documentVersions'] || []
    };
  }

  static mapToConceptSearchOnRulesResult(concept: ConceptRuleSearch): ConceptSearchResult {
    return {
      id: concept.id,
      termId: concept.termId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      categoriesLabel: concept.categoriesLabel,
      isPrivate: concept.isPrivate,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      ownerLabel: concept.ownerLabel,
      state: concept.state,
      ruleSetsLabel: concept.ruleSetRefsLabel
    };
  }
}
