import { StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetLanguagesResponse, LanguagesApiService } from '../../../../../generated/v3';
import { TranslationLanguagesMapper } from '../../../mappers/translation-languages-mapper';
import {
  AddConceptTranslation,
  LoadConceptTranslation,
  UpdateConceptLocally,
  UpdateConceptTermAndDefinition,
  UpdateIsDirty,
  UpdateLanguageTermAndDefinition,
  UpdateSelectedLanguage
} from '../taxonomy-concept.action';
import { TaxonomyConceptStateModel } from '../taxonomy-concept.state';

export class TaxonomyConceptTranslationsStateUseCases {
  private DEFAULT_LANGUAGE = 'en';

  constructor(private languagesApiService: LanguagesApiService) {}

  public loadTranslationLanguages(context: StateContext<TaxonomyConceptStateModel>) {
    context.patchState({ isLoadingTranslationLanguages: true });

    return this.languagesApiService.getLanguages().pipe(
      tap((response: GetLanguagesResponse) => {
        const data = response.languages.map(language => TranslationLanguagesMapper.mapToTranslationLanguage(language));
        context.patchState({
          translationLanguages: data,
          isLoadingTranslationLanguages: false
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public updateConceptLocally(context: StateContext<TaxonomyConceptStateModel>, action: UpdateConceptLocally) {
    const concept = cloneDeep(context.getState().concept);

    concept.termId = action.payload.concept.termId;
    concept.term = action.payload.concept.term;
    concept.definition = action.payload.concept.definition;
    concept.owner = action.payload.concept.owner;
    concept.ruleSets = action.payload.concept.ruleSets;
    concept.jurisdictions = action.payload.concept.jurisdictions;
    concept.categories = action.payload.concept.categories;
    concept.isPrivate = action.payload.concept.isPrivate;
    concept.state = action.payload.concept.state;

    return context.patchState({
      concept: concept,
      isDirty: action.payload.isDirty,
      isTermDirty: action.payload.isTermDirty,
      isDefinitionDirty: action.payload.isDefinitionDirty
    });
  }

  public loadConceptTranslation(context: StateContext<TaxonomyConceptStateModel>, action: LoadConceptTranslation) {
    const translationLanguages = cloneDeep(context.getState().translationLanguages);
    const availableConceptTranslations = cloneDeep(context.getState().translations.availableConceptTranslations);
    const selectedTranslation = action.payload.selectedTranslation;
    const conceptTranslations = availableConceptTranslations
      ? availableConceptTranslations
      : action.payload.existingTranslations;
    const selectedTranslationLanguage = translationLanguages.find(t => t.code === selectedTranslation);
    const existingConceptsLanguages = conceptTranslations ? Object.keys(conceptTranslations) : [];
    const conceptLanguages = [this.DEFAULT_LANGUAGE, ...existingConceptsLanguages];
    const availableTranslationLanguages = translationLanguages.filter(tl => conceptLanguages.includes(tl.code));

    if (!conceptLanguages.includes(selectedTranslationLanguage.code)) {
      availableTranslationLanguages.push(selectedTranslationLanguage);
    }

    return context.patchState({
      translations: {
        availableConceptTranslations: conceptTranslations,
        availableTranslationLanguages: availableTranslationLanguages,
        selectedTranslationLanguage: selectedTranslationLanguage
      }
    });
  }

  public addConceptTranslation(context: StateContext<TaxonomyConceptStateModel>, action: AddConceptTranslation) {
    const selectedLanguage = action.payload.selectedLanguage;
    const concept = cloneDeep(context.getState().concept);
    const translationLanguages = cloneDeep(context.getState().translations.availableTranslationLanguages);
    const allLanguages = cloneDeep(context.getState().translationLanguages);
    const chosenLanguage = allLanguages.find(tl => tl.code === selectedLanguage);
    const translations = cloneDeep(context.getState().translations);
    translations.availableTranslationLanguages.push(chosenLanguage);
    const availableTranslations = translations.availableConceptTranslations || {};
    availableTranslations[chosenLanguage.code] = {
      term: concept.term,
      definition: concept.definition
    };

    translationLanguages.push(chosenLanguage);

    return context.patchState({
      translations: {
        availableConceptTranslations: availableTranslations,
        availableTranslationLanguages: translationLanguages,
        selectedTranslationLanguage: chosenLanguage
      }
    });
  }

  public deleteConceptTranslation(context: StateContext<TaxonomyConceptStateModel>) {
    const selectedLanguageCode = cloneDeep(context.getState().translations.selectedTranslationLanguage.code);
    const availableConceptTranslations = cloneDeep(context.getState().translations.availableConceptTranslations);
    delete availableConceptTranslations[selectedLanguageCode];
    const availableTranslationLanguages = cloneDeep(
      context.getState().translations.availableTranslationLanguages.filter(l => l.code !== selectedLanguageCode)
    );
    delete availableTranslationLanguages[selectedLanguageCode];
    const englishLanguage = availableTranslationLanguages.find(l => l?.code === this.DEFAULT_LANGUAGE);
    const nonEnglishLanguages = availableTranslationLanguages.filter(l => l?.code !== this.DEFAULT_LANGUAGE);

    return context.patchState({
      translations: {
        availableConceptTranslations: availableConceptTranslations,
        availableTranslationLanguages: availableTranslationLanguages,
        selectedTranslationLanguage: nonEnglishLanguages?.[nonEnglishLanguages.length - 1] || englishLanguage
      }
    });
  }

  public updateSelectedLanguage(context: StateContext<TaxonomyConceptStateModel>, action: UpdateSelectedLanguage) {
    const selectedLanguageCode = action.payload.selectedLanguage;
    const selectedLanguage = cloneDeep(
      context.getState().translationLanguages.find(t => t.code === selectedLanguageCode)
    );

    return context.patchState({
      translations: {
        availableConceptTranslations: cloneDeep(context.getState().translations.availableConceptTranslations),
        availableTranslationLanguages: cloneDeep(context.getState().translations.availableTranslationLanguages),
        selectedTranslationLanguage: selectedLanguage
      }
    });
  }

  public updateLanguageTermAndDefinition(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateLanguageTermAndDefinition
  ) {
    const selectedLanguageCode = cloneDeep(context.getState().translations.selectedTranslationLanguage.code);

    if (selectedLanguageCode === 'en') {
      return of(false);
    }

    const selectedLanguage = cloneDeep(
      context.getState().translationLanguages.find(t => t.code === selectedLanguageCode)
    );
    const availableConceptTranslations = cloneDeep(context.getState().translations.availableConceptTranslations);
    const currentTerm = availableConceptTranslations[selectedLanguageCode]?.term;
    const currentDefinition = availableConceptTranslations[selectedLanguageCode]?.definition;

    availableConceptTranslations[selectedLanguageCode] = {
      ...availableConceptTranslations[selectedLanguageCode],
      term: action.payload?.term || currentTerm,
      definition: action.payload?.definition || currentDefinition
    };

    return context.patchState({
      translations: {
        availableConceptTranslations: availableConceptTranslations,
        availableTranslationLanguages: cloneDeep(context.getState().translations.availableTranslationLanguages),
        selectedTranslationLanguage: selectedLanguage
      }
    });
  }

  public updateConceptTermAndDefinition(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptTermAndDefinition
  ) {
    const currentConcept = context.getState().concept;

    if (!currentConcept) {
      return;
    }
    if (action.payload?.term) {
      currentConcept.term = action.payload.term;
    }
    if (action.payload?.definition) {
      currentConcept.definition = action.payload.definition;
    }

    return context.patchState({ concept: currentConcept });
  }

  public updateIsDirty(context: StateContext<TaxonomyConceptStateModel>, action: UpdateIsDirty) {
    return context.patchState({ isDirty: action.payload.isDirty });
  }
}
