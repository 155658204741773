import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompareViewService } from '../../../../generated/v3';
import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';
import { CollectionsCompareViewStateUseCases } from './usecases/collections-compare-view-usecases.state';
import {
  ExportCollectionsCompareView,
  ResetCollectionsCompareViewData,
  SearchCollectionsCompareView,
  SetCollectionsCompareViewCollection,
  SetCollectionsCompareViewFilterStorageData,
  SetCollectionsCompareViewStorageData
} from './collections-compare-view.action';
import { ConceptValuesForTranslationWeb } from '../../../models/concept-values-for-translation-web';

export interface CollectionsCompareViewStateModel {
  collectionId: string;
  searchResults: ConceptValuesForTranslationWeb[];
  totalResults: number;
  isLoadingSearch: boolean;
  searchCriteria: SearchCriteria;
  storageData: StorageData;
  filterStorageData: { [key: string]: FilterData };
}

export const DEFAULT_STATE: CollectionsCompareViewStateModel = {
  collectionId: undefined,
  searchResults: [],
  totalResults: undefined,
  isLoadingSearch: false,
  searchCriteria: undefined,
  storageData: {
    filtersSelectionData: undefined,
    sortBy: undefined,
    selected: [],
    sortOrder: 'ASC',
    isSeeingSelection: false,
    searchValue: undefined,
    page: undefined
  },
  filterStorageData: {}
};

@State<CollectionsCompareViewStateModel>({
  name: 'collectionsCompareView',
  defaults: DEFAULT_STATE
})
@Injectable()
export class CollectionsCompareViewState {
  private collectionsCompareViewStateUseCases: CollectionsCompareViewStateUseCases;

  @Selector()
  public static collectionId(state: CollectionsCompareViewStateModel): string {
    return state.collectionId;
  }

  @Selector()
  public static searchResults(state: CollectionsCompareViewStateModel): ConceptValuesForTranslationWeb[] {
    return state.searchResults;
  }

  @Selector()
  public static isLoadingSearch(state: CollectionsCompareViewStateModel): boolean {
    return state.isLoadingSearch;
  }

  @Selector()
  public static totalResults(state: CollectionsCompareViewStateModel): number {
    return state.totalResults;
  }

  @Selector()
  public static searchCriteria(state: CollectionsCompareViewStateModel): SearchCriteria {
    return state.searchCriteria;
  }

  @Selector()
  public static storageData(state: CollectionsCompareViewStateModel): StorageData {
    return state.storageData;
  }

  @Selector()
  public static filterStorageData(state: CollectionsCompareViewStateModel): { [key: string]: FilterData } {
    return state.filterStorageData;
  }

  constructor(compareViewApiService: CompareViewService) {
    this.collectionsCompareViewStateUseCases = new CollectionsCompareViewStateUseCases(compareViewApiService);
  }

  @Action(SetCollectionsCompareViewCollection)
  public setCollectionId(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewCollection
  ) {
    return this.collectionsCompareViewStateUseCases.setCollectionId(context, action);
  }

  @Action(ResetCollectionsCompareViewData)
  public resetData(context: StateContext<CollectionsCompareViewStateModel>, action: ResetCollectionsCompareViewData) {
    return this.collectionsCompareViewStateUseCases.resetData(context, action);
  }

  @Action(SearchCollectionsCompareView, { cancelUncompleted: true })
  public search(context: StateContext<CollectionsCompareViewStateModel>, action: SearchCollectionsCompareView) {
    return this.collectionsCompareViewStateUseCases.search(context, action);
  }

  @Action(ExportCollectionsCompareView)
  public export(context: StateContext<CollectionsCompareViewStateModel>, action: ExportCollectionsCompareView) {
    return this.collectionsCompareViewStateUseCases.export(context, action);
  }

  @Action(SetCollectionsCompareViewStorageData)
  public setStorageData(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewStorageData
  ) {
    return this.collectionsCompareViewStateUseCases.setStorageData(context, action);
  }

  @Action(SetCollectionsCompareViewFilterStorageData)
  public setFilterStorageData(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewFilterStorageData
  ) {
    return this.collectionsCompareViewStateUseCases.setFilterStorageData(context, action);
  }
}
