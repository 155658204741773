import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, NoPreloading, provideRouter, Router, RouterModule } from '@angular/router';
import { AnalyticsService, BaseConfigService, NavigationBarService, WebCommonsModule } from '@apiax/web-commons';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularSplitModule } from 'angular-split';

import { AppComponent } from './presentation/app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  LocalStorageService,
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage
} from 'ngx-webstorage';
import { getConfig } from 'shared/app/config';
import { AuthGuard } from 'shared/app/guards';
import { LoadingGuard } from 'shared/app/guards/loading.guard';
import { TaxonomyAllowedGuard } from 'shared/app/guards/taxonomy-allowed-guard.service';
import { ConsoleService, WindowService } from 'shared/core';
import { CoreModule } from 'shared/core/core.module';
import { ApiModule as TaxonomyV3Module } from '../generated/v3';
import version from '../version';
import { cons, win } from './app.advance';
import { DataResolver } from './app.resolver';
import { ROUTES } from './app.routes';
import { ErrorInterceptor } from './domain/interceptors/error.interceptor';
import { UnauthorizedInterceptor } from './domain/interceptors/unauthorized.interceptor';
import { buildConfigTaxonomyV3 } from './presentation/pages/taxonomy-index/taxonomy.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { debounceTime, filter } from 'rxjs/operators';

export const GUARDS_PROVIDERS = [AuthGuard, LoadingGuard, TaxonomyAllowedGuard];

export const APP_RESOLVER_PROVIDERS = [DataResolver];

export const MY_APP_PROVIDERS: any[] = [GUARDS_PROVIDERS];

// Application wide providers
const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS, ...MY_APP_PROVIDERS];

export const MY_APP_IMPORTS: any[] = [TaxonomyV3Module.forRoot(buildConfigTaxonomyV3)];

export const ADVANCE_MODULES = [
  CoreModule.forRoot([
    { provide: WindowService, useFactory: win },
    { provide: ConsoleService, useFactory: cons }
  ]),
  RouterModule.forRoot(ROUTES, {
    useHash: getConfig('.useHash'),
    preloadingStrategy: NoPreloading,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })
];

export function kcInitializer(
  keycloak: KeycloakService,
  ngZone: NgZone,
  localStorage: LocalStorageService
): () => Promise<any> {
  return (): Promise<any> => {
    localStorage.clear('kc-logout');
    localStorage.observe('kc-logout').subscribe(() => {
      keycloak.logout();
    });
    return ngZone.runOutsideAngular(() => keycloak.init(getConfig('keycloakOptions')));
  };
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSidenavModule,
    ...ADVANCE_MODULES,
    ...MY_APP_IMPORTS,
    KeycloakAngularModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([], {
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    AngularSplitModule,
    WebCommonsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: kcInitializer,
      multi: true,
      deps: [KeycloakService, NgZone, LocalStorageService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    NavigationBarService,
    ...APP_PROVIDERS,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(ROUTES),
    provideNgxWebstorage(
      withNgxWebstorageConfig({
        prefix: 'apx',
        separator: '.'
      }),
      withLocalStorage(),
      withSessionStorage()
    )
  ]
})
export class AppModule {
  constructor(
    private router: Router,
    baseConfigService: BaseConfigService,
    analytics: AnalyticsService
  ) {
    baseConfigService.load();
    baseConfigService.set('apm.serviceName', 'taxonomy-web');
    baseConfigService.set('apm.serviceVersion', version);
    analytics.initialize();
    if (baseConfigService.get('posthog.capture_pageview')) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          debounceTime(200)
        )
        .subscribe(event => {
          // @ts-ignore
          window.posthog?.capture('$pageview');
        });
    }
  }
}
