<div class="full-export-data-wrapper">
  <p class="title">Full CSV export</p>
  <p class="info">Export all Taxonomy data in CSV format</p>

  <apx-action-button
    [loadingName]="'Exporting...'"
    [colorClass]="'primary-action'"
    [name]="'Full export'"
    [enabled]="!(isPerformingOtherExports$ | async)"
    [method]="performFullExport"
  ></apx-action-button>
</div>
