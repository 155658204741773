import { Component, Inject } from '@angular/core';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { of } from 'rxjs';
import { Store } from '@ngxs/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CollectionsState } from '../../../../domain/stores/collections/collections.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  LoadAffectMyCollectionNames,
  RemoveFromMyCollection
} from '../../../../domain/stores/collections/collections.action';
import { first } from 'rxjs/operators';

export interface Data {
  selectedIds: string[];
  excludedIds: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-remove-from-my-collection-modal',
  templateUrl: './remove-from-my-collection-modal.component.html',
  styleUrls: ['./remove-from-my-collection-modal.component.scss']
})
export class RemoveFromMyCollectionModalComponent {
  public static modalConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '600px',
    maxHeight: '80vh'
  };

  public data: TemplateModalData = {
    title: 'Remove from collections',
    hideCloseIcon: false,
    disableClose: false
  } as unknown as TemplateModalData;

  public isLoading$ = of(true);

  public affectedCollections: string[] = [];

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<RemoveFromMyCollectionModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public removeData: Data
  ) {
    this.isLoading$ = this.store.select(CollectionsState.isLoadingAffectedMyCollectionNames);
    this.store
      .select(CollectionsState.affectMyCollectionNames)
      .pipe(untilDestroyed(this))
      .subscribe(value => (this.affectedCollections = value));

    this.fetchAffectedCollections();
  }

  private fetchAffectedCollections() {
    this.store.dispatch(
      new LoadAffectMyCollectionNames({
        selectedIds: this.removeData.selectedIds,
        excludedIds: this.removeData.excludedIds
      })
    );
  }

  public remove() {
    return (callback: () => void) => {
      this.store
        .dispatch(
          new RemoveFromMyCollection({
            selectedIds: this.removeData.selectedIds,
            excludedIds: this.removeData.excludedIds
          })
        )
        .pipe(first())
        .subscribe(_result => {
          callback();
          this.dialogRef.close(true);
        });
    };
  }
}
