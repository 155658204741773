import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Activity, AuthorizationService } from 'shared/core';
import {
  LoadMoreChangeLog,
  SearchChangeLog
} from '../../../../../domain/stores/taxonomy-concept/taxonomy-concept.action';
import { TaxonomyConceptState } from '../../../../../domain/stores/taxonomy-concept/taxonomy-concept.state';
import { Concept } from '../../../../../models/concept';
import { ChangeLogResultByDay, ConceptChangeLog } from '../../../../../models/concept-changelog-models';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-change-log',
  templateUrl: './taxonomy-change-log.component.html',
  styleUrls: ['./taxonomy-change-log.component.scss']
})
export class TaxonomyChangeLogComponent implements OnInit {
  public readonly PAGE_SIZE = 20;
  public previewOnly: Observable<boolean>;
  private currentPage = 0;
  concept: Concept;
  conceptChangeLogByDay: ChangeLogResultByDay;
  isLoading$: Observable<boolean>;
  isLoadingConcept$: Observable<boolean>;
  isLoadingConceptChangeLog$: Observable<boolean>;

  constructor(
    private store: Store,
    private authorizationService: AuthorizationService
  ) {
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);
    this.isLoadingConceptChangeLog$ = this.store.select(TaxonomyConceptState.isLoadingConceptChangeLog);
  }

  ngOnInit(): void {
    this.isLoading$ = combineLatest([this.isLoadingConcept$, this.isLoadingConceptChangeLog$]).pipe(
      untilDestroyed(this),
      map(([isLoadingConcept, isLoadingConceptChangeLog]) => {
        return isLoadingConcept || isLoadingConceptChangeLog;
      })
    );
    this.subscribeToConcept();
    this.previewOnly = this.authorizationService.isAllowed(Activity.ChangeLogAccess).pipe(map(allowed => !allowed));
    this.store
      .select(TaxonomyConceptState.conceptChangeLogByDay)
      .pipe(untilDestroyed(this))
      .subscribe(conceptChangeLogByDay => {
        this.conceptChangeLogByDay = conceptChangeLogByDay;
      });
  }

  private subscribeToConcept() {
    this.store
      .select(TaxonomyConceptState.concept)
      .pipe(
        untilDestroyed(this),
        filter(concept => !!concept)
      )
      .subscribe(concept => {
        this.concept = concept;
        this.store.dispatch(
          new SearchChangeLog({
            conceptId: this.concept.id,
            pageNumber: this.currentPage,
            pageSize: this.PAGE_SIZE
          })
        );
      });
  }

  loadMore() {
    this.currentPage++;
    this.store.dispatch(
      new LoadMoreChangeLog({
        conceptId: this.concept.id,
        pageNumber: this.currentPage,
        pageSize: this.PAGE_SIZE
      })
    );
  }

  replaceMessage(changeLogItem: ConceptChangeLog) {
    let commandText = changeLogItem.commandText;
    while (commandText.includes('<orgId>')) {
      const orgText = commandText.substring(commandText.indexOf('<orgId>'), commandText.indexOf('</orgId>'));
      commandText = commandText.replace(orgText + '</orgId>', changeLogItem.userOrganizationName);
    }
    return commandText + ' by <b>' + changeLogItem.userName + '</b>';
  }

  hasMoreDetails(changeLog: ConceptChangeLog) {
    return (
      changeLog.commandName !== 'CreateConceptCommand' &&
      (((changeLog.oldValue?.length > 49 || changeLog.newValue?.length > 49) &&
        changeLog.commandText.includes('...')) ||
        changeLog.language !== 'en')
    );
  }

  toggleDetails(changeLog: ConceptChangeLog) {
    changeLog.expanded = !changeLog.expanded;
  }
}
