import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TemplateModalComponent, TemplateModalData, TemplatePickerOption } from '@apiax/web-commons';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { TaxonomyConceptState } from '../../../../domain/stores/taxonomy-concept/taxonomy-concept.state';
import { TranslationLanguage } from '../../../../models/translation-language';

export interface TranslationLanguageModalData {
  translationLanguages: TranslationLanguage[];
}

enum TranslationLanguageForm {
  Language = 'language'
}

@Component({
  selector: 'app-translation-language-modal',
  templateUrl: './translation-language-modal.component.html',
  styleUrls: ['./translation-language-modal.component.scss']
})
export class TranslationLanguageModalComponent implements OnInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '645px',
    minHeight: 220
  };

  public templateData: TemplateModalData;

  public isLoading$: Observable<boolean>;
  public form = new UntypedFormGroup({});
  public formNames = TranslationLanguageForm;
  public availableTranslationLanguages$: Observable<TemplatePickerOption[]>;

  constructor(
    public dialogRef: MatDialogRef<TranslationLanguageModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: TranslationLanguageModalData,
    private store: Store
  ) {
    this.isLoading$ = this.store.select(TaxonomyConceptState.isLoadingTranslationLanguages);

    this.templateData = {
      title: 'Add new language to term',
      hideClose: false,
      disableClose: false
    };

    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({});

    this.form.addControl(TranslationLanguageForm.Language, new UntypedFormControl(undefined, Validators.required));
  }

  private loadAvailableTranslationLanguages() {
    const availableTranslationLanguages = this.data.translationLanguages?.map(obj => {
      return {
        id: obj.code,
        label: obj.name,
        image: null
      };
    });

    this.availableTranslationLanguages$ = of(availableTranslationLanguages);
  }

  ngOnInit() {
    this.loadAvailableTranslationLanguages();
  }

  public onConfirmation() {
    this.dialogRef.close(this.form.get(TranslationLanguageForm.Language).value?.id);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }
}
