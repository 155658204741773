<div class="label">Relation type: broader / narrower</div>

<div class="tree-container">
  <mat-progress-bar
    *ngIf="isLoadingHierarchyTree$ | async"
    class="tree-progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>
  <cdk-virtual-scroll-viewport itemSize="25" class="view-port">
    <ng-container *cdkVirtualFor="let node of dataSource">
      <ng-template [ngIf]="node.hasChildren && node.id !== currentConcept?.id" [ngIfElse]="templateElse">
        <div class="hierarchy-tree-node" [style.padding-left]="node.level * 20 + 4 + 'px'">
          <mat-icon
            class="mat-icon-rtl-mirror"
            (click)="toggleExpand(node)"
            [attr.aria-label]="'Toggle ' + node.concept.term"
          >
            {{ node.isExpanded ? 'remove' : 'add' }}
          </mat-icon>
          <span [title]="node.tooltip" (click)="navigateToConceptRelations(node)">
            {{ node.concept.term }}
          </span>
        </div>
      </ng-template>
      <ng-template #templateElse>
        <ng-template [ngIf]="node.id === currentConcept?.id" [ngIfElse]="defaultTemplate">
          <div class="hierarchy-tree-node current" [style.padding-left]="node.level * 20 + 4 + 'px'">
            <mat-icon
              *ngIf="node.hasChildren; else noChildren"
              class="mat-icon-rtl-mirror"
              (click)="toggleExpand(node)"
              [attr.aria-label]="'Toggle ' + node.concept.term"
            >
              {{ node.isExpanded ? 'remove' : 'add' }}
            </mat-icon>
            <ng-template #noChildren>
              <button mat-icon-button disabled></button>
            </ng-template>
            <span [title]="node.tooltip">
              {{ node.concept.term }}
            </span>
            <mat-icon
              *ngIf="!node.hasSiblingsLoaded && node.concept?.parent?.id"
              class="mat-icon-rtl-mirror"
              (click)="loadSiblingConcepts(node)"
              title="Load sibling concepts"
            >
              supervisor_account
            </mat-icon>
          </div>
        </ng-template>
        <ng-template #defaultTemplate>
          <div class="hierarchy-tree-node" [style.padding-left]="node.level * 20 + 'px'">
            <span
              [style.margin-left]="'' + (nodePadding + 4) + 'px'"
              [title]="node.tooltip"
              (click)="navigateToConceptRelations(node)"
            >
              {{ node.concept.term }}
            </span>
            <mat-icon
              *ngIf="!node.hasChildrenLoaded"
              class="mat-icon-rtl-mirror"
              (click)="loadNarrowerConcepts(node)"
              title="Load narrower concepts"
            >
              sync
            </mat-icon>
            <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate"></mat-progress-bar>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>
