import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ExportDataApiService } from '../../../../generated/v3';
import { FullConceptExport, PartialConceptExport } from './taxonomy-export-data.action';
import { TaxonomyExportDataStateUseCases } from './usecases/taxonomy-export-data-usecases.state';

export interface TaxonomyExportDataStateModel {
  isPerformingFullExport: boolean;
  isPerformingPartialExport: boolean;
}

export const DEFAULT_STATE: TaxonomyExportDataStateModel = {
  isPerformingFullExport: false,
  isPerformingPartialExport: false
};

@State<TaxonomyExportDataStateModel>({
  name: 'taxonomyExportData',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyExportDataState {
  private taxonomyExportDataStateUseCases: TaxonomyExportDataStateUseCases;

  @Selector()
  public static isPerformingFullExport(state: TaxonomyExportDataStateModel): boolean {
    return state.isPerformingFullExport;
  }

  @Selector()
  public static isPerformingPartialExport(state: TaxonomyExportDataStateModel): boolean {
    return state.isPerformingPartialExport;
  }

  constructor(exportDataApiService: ExportDataApiService) {
    this.taxonomyExportDataStateUseCases = new TaxonomyExportDataStateUseCases(exportDataApiService);
  }

  @Action(FullConceptExport)
  public fullConceptExport(context: StateContext<TaxonomyExportDataStateModel>) {
    return this.taxonomyExportDataStateUseCases.fullConceptExport(context);
  }

  @Action(PartialConceptExport)
  public partialConceptExport(context: StateContext<TaxonomyExportDataStateModel>, action: PartialConceptExport) {
    return this.taxonomyExportDataStateUseCases.partialConceptExport(context, action);
  }
}
