import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@apiax/web-commons';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsDao } from '../daos/errors.dao';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  public static readonly SKIP_ERROR_INTERCEPTOR_HEADER_NAME = 'SkipErrorInterceptor';

  /**
   *
   * @param api the api that the callGen will use
   * @param apiCall a lambda that will perform the call
   */
  public static performCallSkippingInterceptor<T>(api: any, apiCall: () => Observable<T>): Observable<T> {
    api.defaultHeaders = api.defaultHeaders.set(this.SKIP_ERROR_INTERCEPTOR_HEADER_NAME, 'true');
    const observable = apiCall.call(this);
    api.defaultHeaders = api.defaultHeaders.delete(this.SKIP_ERROR_INTERCEPTOR_HEADER_NAME);
    return observable;
  }

  constructor(private notificationService: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (request.headers.get(ErrorInterceptor.SKIP_ERROR_INTERCEPTOR_HEADER_NAME)) {
          // Request is marked to not be handled by this interceptor
          return throwError(err);
        }

        if (err.error.message === ErrorsDao.OUTDATED_CONCEPT_ERROR) {
          // If it is related to outdatedConcept the interceptor ignores the error
          return throwError(err);
        }

        if (err.error.data?.exceptionName === ErrorsDao.NOT_ALLOWED_CONCEPT_ERROR) {
          return throwError(err);
        }

        this.notificationService.showError(err);
        return throwError(err);
      })
    );
  }
}
