<div class="collection-content-wrapper">
  <apx-loading-spinner [isLoading]="this.isLoadingData$">
    <ng-template [ngIf]="totalData === 0" [ngIfElse]="table">
      <div *ngIf="_selectedCollectionType !== 'sharedCollectionsWithMe'" class="buttons-wrapper">
        <apx-action-button
          colorClass="plain-action secondary-plain no-padding"
          hint="Edit collection"
          name="Edit collection"
          icon="edit"
          [method]="editCollectionCallback()"
        ></apx-action-button>
      </div>
      <div class="no-terms-message-container">
        This collection doesn’t have any terms. You may add terms to this collection under "My Terms".
      </div>
    </ng-template>
    <ng-template #table>
      <apx-data-table
        [data]="data"
        [totalData]="totalData"
        [columns]="columns"
        [pageSize]="pageSize"
        [noResultsMessage]="'No terms found for this collection.'"
        [idFetcher]="idFetcher"
        [menuActions]="menuActions"
        [allowSelection]="_selectedCollectionType !== 'sharedCollectionsWithMe'"
        (onFetch)="onFetch($event)"
        [entity]="'Term'"
        [initialLoadingState]="false"
        [showDownload]="true"
        [downloadPermission]="downloadPermission"
        [downloadTooltip]="'Download collection list'"
        [isDownloading$]="isDownloading$"
        [sortBy]="defaultSortBy"
        [rowIcon]="rowIcon"
        [sortOrder]="defaultSortOrder"
        [currentUserPermissions]="currentUserPermissions"
        [saveToStorage]="saveToStorageStore"
        [loadFromStorage]="loadFromStorageStore"
        (onDownload)="downloadData($event)"
        (onSelect)="onSelect($event)"
      ></apx-data-table>
    </ng-template>
  </apx-loading-spinner>
</div>
