<div class="changelog-row skeleton">
  <div class="changelog-timestamp">
    <span></span>
  </div>
  <div class="changelog-detail">
    <span [ngStyle]="{ 'width.px': messageWidth }"></span>
  </div>
  <div class="changelog-info">
    <span></span>
    <span></span>
  </div>
</div>
