<apx-template-modal [data]="templateData" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <mat-dialog-content class="concept-jurisdictions-modal-wrapper">
        <mat-tab-group
          animationDuration="0ms"
          [(selectedIndex)]="selectedTabIndex"
          mat-stretch-tabs="false"
          mat-align-tabs="start"
        >
          <mat-tab label="Company data">
            <div class="variables-wrapper">
              <div *ngFor="let variable of companyVariables" class="variable-container">
                <div>{{ variable.value }}</div>
                <apx-action-button
                  [colorClass]="'secondary-action'"
                  [name]="'Add variable'"
                  [method]="onAddVariable(variable)"
                ></apx-action-button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Instruments data">
            <app-instruments-data-variables
              [generalVariables]="generalVariables"
              [investmentFundsVariables]="investmentFundsVariables"
              (onVariableSelected)="onVariableSelected($event)"
            ></app-instruments-data-variables>
          </mat-tab>
          <mat-tab label="Legal Entity">
            <div class="variables-wrapper">
              <div *ngFor="let variable of legalEntityVariables" class="variable-container">
                <div>{{ variable.value }}</div>
                <apx-action-button
                  [colorClass]="'secondary-action'"
                  [name]="'Add variable'"
                  [method]="onAddVariable(variable)"
                ></apx-action-button>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="System">
            <div class="variables-wrapper">
              <div *ngFor="let variable of systemVariables" class="variable-container">
                <div>{{ variable.value }}</div>
                <apx-action-button
                  [colorClass]="'secondary-action'"
                  [name]="'Add variable'"
                  [method]="onAddVariable(variable)"
                ></apx-action-button>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-dialog-content>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
