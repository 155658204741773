import { ConceptValuesForTranslationWeb } from '../../models/concept-values-for-translation-web';
import {
  CompareViewExportFromCollectionCriteria,
  ConceptValuesForTranslation,
  SearchConceptValuesForTranslationsCompareViewRequest
} from '../../../generated/v3';
import {
  ExportCollectionsCompareView,
  SearchCollectionsCompareView
} from '../stores/collections-compare-view/collections-compare-view.action';

export class CollectionsCompareViewMapper {
  static mapToConceptValuesForTranslationWeb(
    concept: ConceptValuesForTranslation
  ): ConceptValuesForTranslationWeb {
    return {
      id: concept.id,
      conceptId: concept.conceptId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      translationLabel: concept.translationLabel,
      translationCode: concept.translationCode
    };
  }

  static mapToSearchRequest(
    action: SearchCollectionsCompareView
  ): SearchConceptValuesForTranslationsCompareViewRequest {
    return {
      collectionId: action.payload.collectionId,
      filters: {
        owners: action.payload.searchCriteria?.contentFilters?.['organizations'] || [],
        jurisdictions: action.payload.searchCriteria?.contentFilters?.['jurisdictions'] || [],
        ruleSetFamilyIds: action.payload.searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: action.payload.searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: action.payload.searchCriteria?.contentFilters?.['categories'] || [],
        translations: action.payload.searchCriteria?.contentFilters?.['translations'] || []
      },
      page: action.payload.searchCriteria.page,
      pageSize: action.payload.searchCriteria.pageSize,
      sortBy: this.mapToSearchSortBy(action.payload.searchCriteria.sortBy),
      sortDirection: this.mapToSearchSortOrder(action.payload.searchCriteria.sortOrder)
    };
  }

  static mapToExportRequest(action: ExportCollectionsCompareView): CompareViewExportFromCollectionCriteria {
    return {
      collectionId: action.payload.collectionId,
      sortBy: this.mapToExportSortBy(action.payload.searchCriteria.sortBy),
      sortDirection: this.mapToExportSortOrder(action.payload.searchCriteria.sortOrder),
      excludedConceptIds: action.payload.searchCriteria.excludedIds || [],
      includedConceptIds: action.payload.searchCriteria.selectedIds || [],
      filters: {
        owners: action.payload.searchCriteria?.contentFilters?.['organizations'] || [],
        jurisdictions: action.payload.searchCriteria?.contentFilters?.['jurisdictions'] || [],
        ruleSetFamilyIds: action.payload.searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: action.payload.searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: action.payload.searchCriteria?.contentFilters?.['categories'] || [],
        translations: action.payload.searchCriteria?.contentFilters?.['translations'] || []
      }
    };
  }

  static mapToSearchSortOrder(sortOrder: string) {
    return SearchConceptValuesForTranslationsCompareViewRequest.SortDirectionEnum[sortOrder];
  }

  static mapToSearchSortBy(sortBy: string) {
    switch (sortBy) {
      case 'term':
        return SearchConceptValuesForTranslationsCompareViewRequest.SortByEnum.TERM;
      case 'rawDefinition':
        return SearchConceptValuesForTranslationsCompareViewRequest.SortByEnum.DEFINITION;
      case 'jurisdictionsLabel':
        return SearchConceptValuesForTranslationsCompareViewRequest.SortByEnum.JURISDICTION;
      case 'translationLabel':
        return SearchConceptValuesForTranslationsCompareViewRequest.SortByEnum.TRANSLATION;
      default:
        return undefined;
    }
  }

  static mapToExportSortOrder(sortOrder: string) {
    return CompareViewExportFromCollectionCriteria.SortDirectionEnum[sortOrder];
  }

  static mapToExportSortBy(sortBy: string) {
    switch (sortBy) {
      case 'term':
        return CompareViewExportFromCollectionCriteria.SortByEnum.TERM;
      case 'rawDefinition':
        return CompareViewExportFromCollectionCriteria.SortByEnum.DEFINITION;
      case 'jurisdictionsLabel':
        return CompareViewExportFromCollectionCriteria.SortByEnum.JURISDICTION;
      case 'translationLabel':
        return CompareViewExportFromCollectionCriteria.SortByEnum.TRANSLATION;
      default:
        return undefined;
    }
  }
}
