import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@apiax/web-commons';
import { UntilDestroy } from '@ngneat/until-destroy';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from 'shared/core';
import { ErrorsDao } from '../daos/errors.dao';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authorizationService: AuthorizationService,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let result = throwError(err);
        if (err.status === 401 && !request.url.includes('logout')) {
          // auto logout if 401 response returned from api
          this.authorizationService.logout();
        } else if (err.status === 403) {
          if (err.error.data?.exceptionName === ErrorsDao.NOT_ALLOWED_CONCEPT_ERROR) {
            return throwError(err);
          } else {
            this.notificationService.showAlert({
              type: 'error',
              message: 'Access denied'
            });
            result = EMPTY;
          }
        }
        return result;
      })
    );
  }
}
