import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConceptApiService } from '../../../../generated/v3';
import { ConceptSearchResult } from '../../../models';
import { ConceptSearchMode } from '../../mappers/taxonomy-search-terms-mapper';
import { Export, Search, SetFilterStorageData, SetSearchMode, SetStorageData } from './taxonomy-my-terms.action';
import { TaxonomySearchTermsStateUseCases } from './usecases/taxonomy-my-terms-usecases.state';
import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';

export interface TaxonomyMyTermsStateModel {
  searchResults: ConceptSearchResult[];
  totalResults: number;
  isLoadingSearch: boolean;
  searchMode: ConceptSearchMode;
  searchCriteria: SearchCriteria;
  storageData: StorageData;
  filterStorageData: { [key: string]: FilterData };
}

export const DEFAULT_STATE: TaxonomyMyTermsStateModel = {
  searchResults: [],
  totalResults: undefined,
  isLoadingSearch: false,
  searchMode: ConceptSearchMode.Standard,
  searchCriteria: undefined,
  storageData: {
    filtersSelectionData: undefined,
    sortBy: undefined,
    selected: [],
    sortOrder: 'ASC',
    isSeeingSelection: false,
    searchValue: undefined,
    page: undefined
  },
  filterStorageData: {}
};

@State<TaxonomyMyTermsStateModel>({
  name: 'taxonomySearchTerms',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyMyTermsState {
  private taxonomySearchTermsStateUseCases: TaxonomySearchTermsStateUseCases;

  @Selector()
  public static searchResults(state: TaxonomyMyTermsStateModel): ConceptSearchResult[] {
    return state.searchResults;
  }

  @Selector()
  public static isLoadingSearch(state: TaxonomyMyTermsStateModel): boolean {
    return state.isLoadingSearch;
  }

  @Selector()
  public static totalResults(state: TaxonomyMyTermsStateModel): number {
    return state.totalResults;
  }

  @Selector()
  public static searchMode(state: TaxonomyMyTermsStateModel): ConceptSearchMode {
    return state.searchMode;
  }

  @Selector()
  public static searchCriteria(state: TaxonomyMyTermsStateModel): SearchCriteria {
    return state.searchCriteria;
  }

  @Selector()
  public static storageData(state: TaxonomyMyTermsStateModel): StorageData {
    return state.storageData;
  }

  @Selector()
  public static filterStorageData(state: TaxonomyMyTermsStateModel): { [key: string]: FilterData } {
    return state.filterStorageData;
  }

  constructor(taxonomyV3ApiService: ConceptApiService) {
    this.taxonomySearchTermsStateUseCases = new TaxonomySearchTermsStateUseCases(taxonomyV3ApiService);
  }

  @Action(SetSearchMode)
  public setSearchMode(context: StateContext<TaxonomyMyTermsStateModel>, { searchMode }: SetSearchMode) {
    return this.taxonomySearchTermsStateUseCases.setSearchMode(context, searchMode);
  }

  @Action(Search, { cancelUncompleted: true })
  public search(context: StateContext<TaxonomyMyTermsStateModel>, { searchCriteria }: Search) {
    return this.taxonomySearchTermsStateUseCases.search(context, searchCriteria);
  }

  @Action(Export)
  public export(context: StateContext<TaxonomyMyTermsStateModel>) {
    return this.taxonomySearchTermsStateUseCases.export(context);
  }

  @Action(SetStorageData)
  public setStorageData(context: StateContext<TaxonomyMyTermsStateModel>, { storageData }: SetStorageData) {
    return this.taxonomySearchTermsStateUseCases.setStorageData(context, storageData);
  }

  @Action(SetFilterStorageData)
  public setFilterStorageData(context: StateContext<TaxonomyMyTermsStateModel>, { storageData }: SetFilterStorageData) {
    return this.taxonomySearchTermsStateUseCases.setFilterStorageData(context, storageData);
  }
}
