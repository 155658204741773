/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PerformConceptPartialExportCriteria { 
    conceptPartialExportType?: PerformConceptPartialExportCriteria.ConceptPartialExportTypeEnum;
    values?: Array<string>;
}
export namespace PerformConceptPartialExportCriteria {
    export type ConceptPartialExportTypeEnum = 'CONCEPT_ID' | 'CONCEPT_TERM_ID' | 'ORGANIZATION_ID';
    export const ConceptPartialExportTypeEnum = {
        CONCEPT_ID: 'CONCEPT_ID' as ConceptPartialExportTypeEnum,
        CONCEPT_TERM_ID: 'CONCEPT_TERM_ID' as ConceptPartialExportTypeEnum,
        ORGANIZATION_ID: 'ORGANIZATION_ID' as ConceptPartialExportTypeEnum
    };
}


