/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateConceptTranslation } from './updateConceptTranslation';
import { RuleSetFamily } from './ruleSetFamily';


export interface UpdateConceptRequest { 
    id?: string;
    termId?: string;
    term?: string;
    definition?: string;
    owner?: string;
    ruleSetFamilyList?: Array<RuleSetFamily>;
    jurisdictions?: Array<string>;
    categories?: Array<string>;
    isPrivate?: boolean;
    state?: UpdateConceptRequest.StateEnum;
    updateDate?: string;
    translations?: { [key: string]: UpdateConceptTranslation; };
}
export namespace UpdateConceptRequest {
    export type StateEnum = 'DEPRECATED' | 'ACTIVE' | 'INACTIVE';
    export const StateEnum = {
        DEPRECATED: 'DEPRECATED' as StateEnum,
        ACTIVE: 'ACTIVE' as StateEnum,
        INACTIVE: 'INACTIVE' as StateEnum
    };
}


