<apx-template-modal [data]="isEdit ? editData : addToCollectionData" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <form [formGroup]="form" (ngSubmit)="saveCollection()">
        <mat-dialog-content class="add-to-collection-modal--content">
          <div>
            <ng-template [ngIf]="!(isLoadingCollections$ | async)">
              <ng-template [ngIf]="isEdit" [ngIfElse]="addToCollection">
                <apx-simple-input
                  class="name-input"
                  [control]="formControlName"
                  [placeholder]="'Collection name'"
                  [message]="warningMessage"
                >
                </apx-simple-input>
              </ng-template>
              <ng-template #addToCollection>
                <apx-autocomplete
                  #autocomplete
                  ngDefaultControl
                  formControlName="{{ formNames.Name }}"
                  [placeholder]="'Select a collection'"
                  [options]="getCollectionsData()"
                  [actionOption]="actionOption"
                  (onOptionSelected)="onCollectionSelection($event)"
                  (onDropdownClose)="onDropDownClose()"
                  [warningMessage]="warningMessage"
                ></apx-autocomplete>
              </ng-template>
            </ng-template>
            <mat-checkbox
              formControlName="{{ formNames.IsShared }}"
              class="share-input"
              (change)="sharedClick()"
              (click)="$event.stopPropagation()"
              [checked]="form?.get(formNames.IsShared)?.value"
            >
              Share this collection
            </mat-checkbox>
          </div>
          <div *ngIf="shouldSeeSharedContent()">
            <span>Share collection with groups</span>
            <ng-template [ngIf]="!(isLoadingGroups$ | async)">
              <apx-input-multiselect-chips
                ngDefaultControl
                class="shared-groups"
                [placeholder]="'Search groups'"
                [items]="groups"
                [maxChips]="5"
                [removable]="!form.get(formNames.Groups)?.disabled"
                [control]="form.get(formNames.Groups)"
              ></apx-input-multiselect-chips>
            </ng-template>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="add-to-collection-modal--footer">
          <div [className]="isEdit ? 'edit-button-wrapper' : 'button-wrapper'">
            <button
              *ngIf="isEdit"
              class="btn-action secondary-action critical"
              [type]="'button'"
              (click)="performDelete($event)"
            >
              Delete this collection
            </button>
            <button
              class="btn-action primary-action"
              type="submit"
              [disabled]="!form.valid || invalidSharedOptions() || !form.dirty || (isLoadingGroups$ | async)"
            >
              Save
            </button>
          </div>
        </mat-dialog-actions>
      </form>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
