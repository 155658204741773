import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChipsWithAdditionOption, NotificationService } from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, finalize, first, map, switchMap } from 'rxjs/operators';
import { GetEditorConceptHierarchyRequest } from '../../../../../../generated/v3';
import { TaxonomyUtilsService } from '../../../../../domain/services/taxonomy-utils.service';

import {
  GetConceptRelations,
  LoadAndAppendNarrowerConceptHierarchy,
  LoadConceptHierarchy,
  TaxonomyConceptState,
  UpdateConceptRelations,
  UpdateConceptsRelationsLocally
} from '../../../../../domain/stores/taxonomy-concept';
import { Concept, ConceptRelation, ConceptRelationType } from '../../../../../models';
import {
  ConceptRelationsModalComponent,
  ConceptRelationsModalData
} from '../../../../shared-components/modals/concept-relations/concept-relations-modal.component';
import RelationTypeEnum = GetEditorConceptHierarchyRequest.RelationTypeEnum;

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-relations',
  templateUrl: './taxonomy-relations.component.html',
  styleUrls: ['./taxonomy-relations.component.scss']
})
export class TaxonomyRelationsComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading$: Observable<boolean>;
  isLoadingConcept$: Observable<boolean>;
  isLoadingRelations$: Observable<boolean>;
  isLoadingFormValues$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  concept: Concept;
  form = new UntypedFormGroup({});
  conceptRelatedRelations: ConceptRelation[] = [];
  conceptNarrowerRelations: ConceptRelation[] = [];
  conceptBroaderRelations: ConceptRelation[] = [];
  canEdit = true;

  readonly conceptRelationType = ConceptRelationType;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private taxonomyUtilsService: TaxonomyUtilsService,
    private elemRef: ElementRef
  ) {
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);
    this.isLoadingRelations$ = this.store.select(TaxonomyConceptState.isLoadingConceptChangeLog);
  }

  ngOnDestroy(): void {
    this.store.dispatch(
      new UpdateConceptsRelationsLocally({
        conceptRelationChanged: this.form.dirty,
        relatedRelations: this.conceptRelatedRelations,
        narrowerRelations: this.conceptNarrowerRelations,
        broaderRelations: this.conceptBroaderRelations
      })
    );
  }

  ngOnInit(): void {
    this.canEdit = this.taxonomyUtilsService.canUpdateConcept();
    this.initForm();
    this.isLoading$ = combineLatest([this.isLoadingConcept$, this.isLoadingRelations$, this.isLoadingFormValues$]).pipe(
      untilDestroyed(this),
      map(([isLoadingConcept, isLoadingRelations, isLoadingFormValues$]) => {
        return isLoadingConcept || isLoadingRelations || isLoadingFormValues$;
      })
    );
    this.subscribeToConcept();
    this.subscribeToConceptRelations();
    this.subscribeToIsConceptRelationsDirty();
  }

  private subscribeToIsConceptRelationsDirty() {
    this.store
      .select(TaxonomyConceptState.isConceptRelationsDirty)
      .pipe(untilDestroyed(this))
      .subscribe(changed => {
        if (changed) {
          this.form.markAsDirty();
        } else {
          this.form.markAsPristine();
        }
      });
  }

  ngAfterViewInit(): void {
    const gutterElem = this.elemRef.nativeElement.querySelector('as-split > .as-split-gutter');
    gutterElem.style.height = 'auto';
    gutterElem.style.margin = '0 1rem';
  }

  private subscribeToConceptRelations() {
    this.store
      .select(TaxonomyConceptState.conceptRelations)
      .pipe(
        untilDestroyed(this),
        filter(r => r !== undefined)
      )
      .subscribe(relations => {
        const narrowerRelations = relations.filter(r => r.relationType === ConceptRelationType.NARROWER);
        const relatedRelations = relations.filter(r => r.relationType === ConceptRelationType.RELATED);
        const broaderRelations = relations.filter(r => r.relationType === ConceptRelationType.BROADER);
        this.conceptNarrowerRelations = narrowerRelations;
        this.setFormRelationValues(ConceptRelationType.NARROWER, this.conceptNarrowerRelations);
        this.conceptRelatedRelations = relatedRelations;
        this.setFormRelationValues(ConceptRelationType.RELATED, this.conceptRelatedRelations);
        this.conceptBroaderRelations = broaderRelations;
        this.setFormRelationValues(ConceptRelationType.BROADER, this.conceptBroaderRelations);

        this.isLoadingFormValues$.next(false);
      });
  }

  private setFormRelationValues(formName: string, relations: ConceptRelation[]) {
    const formValues = relations.map(r => {
      return {
        id: r.targetConceptId,
        label: r.targetConceptTerm,
        removable: true
      } as ChipsWithAdditionOption;
    });
    this.form.get(formName).setValue(formValues, { emitEvent: false });
  }

  private subscribeToConcept() {
    this.store
      .select(TaxonomyConceptState.concept)
      .pipe(
        untilDestroyed(this),
        filter(concept => !!concept)
      )
      .subscribe(concept => {
        this.concept = concept;
        const conceptRelations = this.store.selectSnapshot(TaxonomyConceptState.conceptRelations);
        const isLoading = this.store.selectSnapshot(TaxonomyConceptState.isLoadingConceptRelations);
        if (!isLoading && conceptRelations === undefined) {
          this.store.dispatch(
            new GetConceptRelations({
              conceptId: this.concept.id
            })
          );
        }
      });
  }

  openRelationsModal(relationFormField: ConceptRelationType) {
    let relations: ConceptRelation[];
    if (ConceptRelationType.RELATED === relationFormField) {
      relations = this.conceptRelatedRelations;
    } else if (ConceptRelationType.NARROWER === relationFormField) {
      relations = this.conceptNarrowerRelations;
    } else {
      relations = this.conceptBroaderRelations;
    }

    const data: ConceptRelationsModalData = {
      conceptId: this.concept.id,
      relationType: relationFormField,
      conceptRelations: relations
    };

    const dialogRef = this.dialog.open(ConceptRelationsModalComponent, {
      data: data,
      ...ConceptRelationsModalComponent.DEFAULT_CONFIG
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(response => {
        if (response) {
          const values = response.map(obj => {
            return {
              id: obj.targetConceptId,
              label: obj.targetConceptTerm,
              removable: true
            } as ChipsWithAdditionOption;
          });
          if (ConceptRelationType.RELATED === relationFormField) {
            this.conceptRelatedRelations = response;
          } else if (ConceptRelationType.NARROWER === relationFormField) {
            this.conceptNarrowerRelations = response;
          } else {
            this.conceptBroaderRelations = response;
          }
          this.form.get(relationFormField).setValue(values);
        }
      });
  }

  private initForm() {
    this.form = new UntypedFormGroup({});

    this.form.addControl(
      ConceptRelationType.RELATED,
      new UntypedFormControl({ value: undefined, disabled: !this.canEdit })
    );
    this.form.addControl(
      ConceptRelationType.NARROWER,
      new UntypedFormControl({ value: undefined, disabled: !this.canEdit })
    );
    this.form.addControl(
      ConceptRelationType.BROADER,
      new UntypedFormControl({ value: undefined, disabled: !this.canEdit })
    );

    this.form
      .get(ConceptRelationType.RELATED)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        this.conceptRelatedRelations = this.conceptRelatedRelations.filter(relation =>
          values.find(value => value.id === relation.targetConceptId)
        );
        this.form.get(ConceptRelationType.RELATED).markAsDirty();
      });

    this.form
      .get(ConceptRelationType.NARROWER)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        this.conceptNarrowerRelations = this.conceptNarrowerRelations.filter(relation =>
          values.find(value => value.id === relation.targetConceptId)
        );
        this.form.get(ConceptRelationType.NARROWER).markAsDirty();
      });

    this.form
      .get(ConceptRelationType.BROADER)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        this.conceptBroaderRelations = this.conceptBroaderRelations.filter(relation =>
          values.find(value => value.id === relation.targetConceptId)
        );
        this.form.get(ConceptRelationType.BROADER).markAsDirty();
      });
  }

  onSubmit() {
    return (callback: () => void) => {
      this.store
        .dispatch(
          new UpdateConceptRelations({
            conceptId: this.concept.id,
            relatedRelations: this.conceptRelatedRelations,
            narrowerRelations: this.conceptNarrowerRelations,
            broaderRelations: this.conceptBroaderRelations
          })
        )
        .pipe(
          first(),
          switchMap(() =>
            this.store.dispatch(
              new LoadConceptHierarchy({
                conceptId: this.concept.id,
                relationType: RelationTypeEnum.BROADER
              })
            )
          ),
          switchMap(_ =>
            this.store.dispatch(new LoadAndAppendNarrowerConceptHierarchy({ conceptId: this.concept.id }))
          ),
          finalize(() => {
            callback();
          })
        )
        .subscribe(_response => {
          this.form.markAsPristine();
          this.notificationService.showSimpleAlert('Concept relations updated successfully', 'success');
        });
    };
  }
}
