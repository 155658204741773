import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-changelog-skeleton-entry',
  templateUrl: './changelog-skeleton-entry.component.html',
  styleUrls: ['./changelog-skeleton-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangelogSkeletonEntryComponent {
  @Input()
  public messageWidth: number;
}
