import {
  FilterActivationStateOption,
  FilterRuleSet,
  FilterRuleSetFamily,
  FilterUsageTypeGroupOption,
  Option
} from '../../../generated/v3';
import { FilterOption } from '../../models';
import { RuleSetFilterOption } from '../../models/rule-set-filter-option';

export class FilterOptionMapper {
  static mapToFilterOption(option: Option): FilterOption {
    return {
      id: option.id,
      name: option.label
    };
  }

  static mapStringToFilterOption(option: string): FilterOption {
    return {
      id: option,
      name: option
    };
  }

  static mapFilterUsageTypeGroupOptionToFilterOption(option: FilterUsageTypeGroupOption): FilterOption {
    return {
      id: option.id,
      name: option.label
    };
  }

  static mapFilterActivationStateOptionToFilterOption(option: FilterActivationStateOption): FilterOption {
    return {
      id: option.id,
      name: option.label
    };
  }

  static mapRuleSetFamilyToFilterOption(option: FilterRuleSetFamily): FilterOption {
    return {
      id: option.familyId,
      name: option.nameLabel
    };
  }

  static mapRuleSetToFilterOption(option: FilterRuleSet): RuleSetFilterOption {
    return {
      id: option.ruleSetId,
      name: option.nameLabel,
      ruleSetFamilyId: option.ruleSetFamilyId
    };
  }
}
