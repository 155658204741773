export enum CollectionsCompareViewFiltersAction {
  LoadCollectionsCompareViewFilterOptions = '[Load collections compare view filters options] loads all the filter options for the collections compare view',
  ResetCollectionsCompareViewFiltersData = '[Reset Collections Compare View Filters Data] resets all data related to collections compare view filters'
}

export class LoadCollectionsCompareViewFilterOptions {
  public static readonly type = CollectionsCompareViewFiltersAction.LoadCollectionsCompareViewFilterOptions;

  constructor(public collectionId: string) {}
}

export class ResetCollectionsCompareViewFiltersData {
  public static readonly type = CollectionsCompareViewFiltersAction.ResetCollectionsCompareViewFiltersData;
}
