import { CollectionDetailModel, CollectionModel, CollectionType } from '../../models/collection-model';
import { AddToCollection, CreateCollection } from '../stores/collections/collections.action';
import { SearchCriteria } from '@apiax/web-commons';
import {
  AddToCollectionRequest,
  Concept,
  CreateCollectionRequest,
  CreateCollectionSearchCriteria,
  SearchConceptsByCollectionRequest
} from '../../../generated/v3';
import { ConceptCollection } from '../../models/concept-collection';

export type TypesEnum = 'USER_ONLY' | 'SHARED_BY_ME' | 'SHARED_WITH_ME';
export const TypesEnum = {
  USER_ONLY: 'USER_ONLY' as TypesEnum,
  SHARED_BY_ME: 'SHARED_BY_ME' as TypesEnum,
  SHARED_WITH_ME: 'SHARED_WITH_ME' as TypesEnum
};

export class CollectionsMapper {
  static mapToSortOrder(sortOrder: string) {
    return SearchConceptsByCollectionRequest.SortDirectionEnum[sortOrder];
  }

  static mapToSortBy(sortBy: string) {
    switch (sortBy) {
      case 'term':
        return SearchConceptsByCollectionRequest.SortByEnum.TERM;
      case 'termId':
        return SearchConceptsByCollectionRequest.SortByEnum.TERM_ID;
      case 'rawDefinition':
        return SearchConceptsByCollectionRequest.SortByEnum.DEFINITION;
      case 'ruleSetsLabel':
        return SearchConceptsByCollectionRequest.SortByEnum.RULESET;
      case 'jurisdictionsLabel':
        return SearchConceptsByCollectionRequest.SortByEnum.JURISDICTION;
      case 'ownerLabel':
        return SearchConceptsByCollectionRequest.SortByEnum.OWNER;
      case 'categoriesLabel':
        return SearchConceptsByCollectionRequest.SortByEnum.CATEGORY;
      case 'isPrivate':
        return SearchConceptsByCollectionRequest.SortByEnum.PRIVACY;
      case 'state':
        return SearchConceptsByCollectionRequest.SortByEnum.STATUS;

      default:
        return undefined;
    }
  }

  static mapToCollectionModel(collections: any[]): CollectionModel[] {
    return collections.map(d => {
      return {
        id: d.id,
        name: d.name
      };
    });
  }

  static mapToCollectionDetailModel(results: any[]): CollectionDetailModel[] {
    return results.map(r => {
      return {
        id: r.id,
        name: r.name,
        sharedGroups: r.sharedGroups,
        type: CollectionsMapper.mapToCollectionType(r.type)
      };
    });
  }

  static mapToCollectionType(type: any): CollectionType {
    switch (type) {
      case 'USER_ONLY':
        return CollectionType.USER_ONLY;
      case 'SHARED_WITH_ME':
        return CollectionType.SHARED_WITH_ME;
      case 'SHARED_BY_ME':
        return CollectionType.SHARED_BY_ME;
    }
  }

  static mapFromCollectionType(type: CollectionType): any {
    switch (type) {
      case CollectionType.SHARED_BY_ME:
        return TypesEnum.SHARED_BY_ME;
      case CollectionType.SHARED_WITH_ME:
        return TypesEnum.SHARED_WITH_ME;
      case CollectionType.USER_ONLY:
        return TypesEnum.USER_ONLY;
    }
  }

  static mapToAddDocumentToCollectionRequest(action: AddToCollection): AddToCollectionRequest {
    return {
      id: action.payload.id,
      name: action.payload.name,
      sharedGroups: action.payload.sharedGroups,
      exclusions: action.payload.exclusions,
      selections: action.payload.selections,
      searchCriteria: this.mapToSearchCriteria(action.payload.searchText, action.payload.searchCriteria),
      isExactMatch: action.payload.isExactMatch
    } as AddToCollectionRequest;
  }

  static mapToCreateCollectionRequest(action: CreateCollection): CreateCollectionRequest {
    return {
      name: action.payload.name,
      sharedGroups: action.payload.sharedGroups,
      exclusions: action.payload.exclusions,
      selections: action.payload.selections,
      searchCriteria: this.mapToSearchCriteria(action.payload.searchText, action.payload.searchCriteria),
      isExactMatch: action.payload.isExactMatch
    } as CreateCollectionRequest;
  }

  static mapToSearchCriteria(searchText: string, searchCriteria: SearchCriteria): CreateCollectionSearchCriteria {
    return {
      searchText: searchText,
      jurisdictions: searchCriteria?.contentFilters?.['jurisdictions'] || [],
      organizations: searchCriteria?.contentFilters?.['organizations'] || [],
      ruleSetFamilyIds: searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
      ruleSetIds: searchCriteria?.contentFilters?.['ruleSets'] || [],
      categories: searchCriteria?.contentFilters?.['categories'] || []
    };
  }

  static mapToConceptCollection(concept: Concept): ConceptCollection {
    return {
      id: concept.id,
      termId: concept.termId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      categoriesLabel: concept.categoriesLabel,
      isPrivate: concept.isPrivate,
      state: concept.state,
      ruleSetRefsLabel: concept.ruleSetRefsLabel,
      ownerLabel: concept.ownerLabel
    };
  }
}
