/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchConceptsByCollectionRequest { 
    collectionId?: string;
    page?: number;
    pageSize?: number;
    sortBy?: SearchConceptsByCollectionRequest.SortByEnum;
    sortDirection?: SearchConceptsByCollectionRequest.SortDirectionEnum;
    selectedIds?: Array<string>;
    excludedIds?: Array<string>;
}
export namespace SearchConceptsByCollectionRequest {
    export type SortByEnum = 'TERM' | 'TERM_ID' | 'DEFINITION' | 'JURISDICTION' | 'RULESET' | 'OWNER' | 'CATEGORY' | 'PRIVACY' | 'STATUS';
    export const SortByEnum = {
        TERM: 'TERM' as SortByEnum,
        TERM_ID: 'TERM_ID' as SortByEnum,
        DEFINITION: 'DEFINITION' as SortByEnum,
        JURISDICTION: 'JURISDICTION' as SortByEnum,
        RULESET: 'RULESET' as SortByEnum,
        OWNER: 'OWNER' as SortByEnum,
        CATEGORY: 'CATEGORY' as SortByEnum,
        PRIVACY: 'PRIVACY' as SortByEnum,
        STATUS: 'STATUS' as SortByEnum
    };
    export type SortDirectionEnum = 'ASC' | 'DESC';
    export const SortDirectionEnum = {
        ASC: 'ASC' as SortDirectionEnum,
        DESC: 'DESC' as SortDirectionEnum
    };
}


