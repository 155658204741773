import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { VariableConcept } from '../../../../../models/variable-concept';

@Component({
  selector: 'app-instruments-data-variables',
  templateUrl: './instruments-data-variables.component.html',
  styleUrl: './instruments-data-variables.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstrumentsDataVariablesComponent implements OnInit {
  @Input() generalVariables: VariableConcept[];
  @Input() investmentFundsVariables: VariableConcept[];

  @Output() onVariableSelected: EventEmitter<VariableConcept> = new EventEmitter<VariableConcept>();

  searchedGeneralVariables: VariableConcept[] = [];
  searchedInvestmentFundsVariables: VariableConcept[] = [];

  readonly generalPanelState = signal<boolean>(true);
  readonly investmentPanelState = signal<boolean>(true);
  isInvestmentFundsExpanded: boolean;
  searchText: string = '';

  ngOnInit(): void {
    this.searchedGeneralVariables = this.generalVariables;
    this.searchedInvestmentFundsVariables = this.investmentFundsVariables;
  }

  toggleInvestmentFundsExpand() {
    this.isInvestmentFundsExpanded = !this.isInvestmentFundsExpanded;
  }

  onSearchTextChange() {
    this.searchedGeneralVariables = this.generalVariables.filter(variable =>
      variable.value.toLowerCase().includes(this.searchText.toLowerCase())
    );
    this.searchedInvestmentFundsVariables = this.investmentFundsVariables.filter(variable =>
      variable.value.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  onSelectVariable(variable: VariableConcept) {
    const that = this;
    return function () {
      that.onVariableSelected.emit(variable);
    };
  }
}
