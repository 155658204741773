import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ChangelogSkeletonEntryComponent } from './changelog-skeleton-entry/changelog-skeleton-entry.component';
import { TeaserModalComponent } from './teaser-modal/teaser-modal.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [ChangelogSkeletonEntryComponent, TeaserModalComponent],
  exports: [ChangelogSkeletonEntryComponent, TeaserModalComponent]
})
export class ChangelogModule {}
