import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChipsSelectionOption, NotificationService } from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { getConfig } from 'shared/app';
import { TaxonomyUtilsService } from '../../../../../domain/services/taxonomy-utils.service';
import {
  GetConceptLLM,
  LoadAvailableLLMOptions,
  UpdateConceptLLMLocally,
  UpsertConceptLLM
} from '../../../../../domain/stores/llm-term-definitions/concept-llm.action';
import { ConceptLlmState } from '../../../../../domain/stores/llm-term-definitions/concept-llm.state';
import { CheckDeployedUsages, TaxonomyConceptState } from '../../../../../domain/stores/taxonomy-concept';
import { ConceptUtils } from '../../../../../domain/utils/concept.utils';
import { Concept } from '../../../../../models';
import { ConceptLLM } from '../../../../../models/concept-llm';
import {
  DeployedUsageModalComponent,
  DeployedUsageModalData
} from '../../../../shared-components/modals/deployed-usage/deployed-usage-modal.component';

enum LLMDefinitionForm {
  TermId = 'termId',
  Term = 'term',
  Definition = 'definition',
  ConceptLLMList = 'conceptLLMList',
  LLMS = 'llms'
}

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-llm-definitions',
  templateUrl: './taxonomy-llm-definitions.component.html',
  styleUrls: ['./taxonomy-llm-definitions.component.scss']
})
export class TaxonomyLlmDefinitionsComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public form = new UntypedFormGroup({});
  public formNames = LLMDefinitionForm;
  public addLLMDefinition = this.onAddLLMDefinition();
  public updateLLMDefinition = this.onUpdateLLMDefinition();
  public availableOptions$: Observable<ChipsSelectionOption[]>;
  public hasEditAccess: boolean;
  public allOptionsProviders: ChipsSelectionOption[] = [];
  public isAddLLMDefinitionBtnEnabled: boolean;
  public isFromValueChanged: boolean;
  public isLoadingUpdateLLMDefinition: boolean;

  private isLoadingConcept$: Observable<boolean>;
  private isLoadingConceptLLM$: Observable<boolean>;
  private isLoadingAvailableLLMProviderOptions$: Observable<boolean>;
  private conceptLLms: ConceptLLM[] = [];
  private concept: Concept;
  private oldValueForm: any;
  private areLLMTermOptionsLoaded$ = new BehaviorSubject(false);
  private areConceptLLmLoaded$ = new BehaviorSubject(false);
  private isFormLoaded = false;
  private isFormDirty = false;
  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);
    this.isLoadingConceptLLM$ = this.store.select(ConceptLlmState.isLoadingConceptLLM);
    this.isFormDirty = this.store.selectSnapshot(ConceptLlmState.isConceptLLMDirty);
    this.isLoadingAvailableLLMProviderOptions$ = this.store.select(ConceptLlmState.isLoadingAvailableLLMOptions);
    this.hasEditAccess = this.taxonomyUtilsService.canUpdateConcept();
    this.isLoading$ = combineLatest([
      this.isLoadingConcept$,
      this.isLoadingAvailableLLMProviderOptions$,
      this.isLoadingConceptLLM$,
      this.areLLMTermOptionsLoaded$,
      this.areConceptLLmLoaded$
    ]).pipe(
      untilDestroyed(this),
      map(
        ([
          isLoadingConcept,
          isLoadingLLMProviderOptions,
          isLoadingConceptLLM,
          areLLMTermOptionsLoaded,
          areConceptLLmLoaded$
        ]) => {
          return (
            (isLoadingConcept && isLoadingLLMProviderOptions && isLoadingConceptLLM) ||
            !(areLLMTermOptionsLoaded && areConceptLLmLoaded$)
          );
        }
      )
    );

    this.isLoading$
      .pipe(
        filter(isLoading => !isLoading),
        untilDestroyed(this)
      )
      .subscribe(_isLoading => {
        this.setForm();
      });

    this.subscribeToConcept();
  }

  ngOnInit(): void {
    this.initForm();
    this.loadConceptLLm();
    this.loadLLMOptionProviders();
    this.listenToFormChanges();
  }

  ngOnDestroy(): void {
    // @ts-ignore
    this.updateConceptLLMLocally().pipe(first()).subscribe();
  }

  listenToFormChanges() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const currentValueFormString = JSON.stringify(value);
      this.isAddLLMDefinitionBtnEnabled = value.term && value.definition;
      this.isFromValueChanged = this.oldValueForm !== currentValueFormString;
      if (value.conceptLLMList?.length > 0) {
        const currentProviders: ChipsSelectionOption[] = value.conceptLLMList.flatMap(
          llmDefinition => llmDefinition.llms || []
        );
        this.allOptionsProviders = this.store
          .selectSnapshot(ConceptLlmState.availableLLMOptions)
          .filter(oP => !currentProviders.some(cP => cP.id === oP.id));
        this.availableOptions$ = of(this.allOptionsProviders);
      }
    });
  }

  onAddLLMDefinition(): (callback: () => void) => void {
    return (_callback: () => void) => {
      this.form.addControl(LLMDefinitionForm.ConceptLLMList, new FormArray([]));
      const llmDefinitions = this.form.get(LLMDefinitionForm.ConceptLLMList) as FormArray;
      llmDefinitions.push(
        new FormGroup({
          [LLMDefinitionForm.LLMS]: new FormControl(undefined, [Validators.required]),
          [LLMDefinitionForm.Term]: new FormControl(undefined, Validators.required),
          [LLMDefinitionForm.Definition]: new FormControl(undefined, Validators.required)
        })
      );
    };
  }

  onUpdateLLMDefinition(): (callback: () => void) => void {
    return (callback: () => void) => {
      const conceptLLmValues = this.formatValue();
      this.isLoadingUpdateLLMDefinition = true;
      return this.checkDeployedAndUpdateAction(conceptLLmValues)
        .pipe(
          catchError(err => {
            return throwError(err.error);
          }),
          finalize(() => {
            callback();
          })
        )
        .subscribe(result => {
          this.isLoadingUpdateLLMDefinition = false;
          if (result) {
            this.form.markAsPristine();
            this.form.markAsUntouched();
            this.notificationService.showSimpleAlert('LLM definition updated successfully', 'success');
          }
        });
    };
  }

  get llmDefinitionsControls() {
    return (this.form.get(LLMDefinitionForm.ConceptLLMList) as FormArray).controls;
  }

  onDeleteLLMDefinition(index: number) {
    return () => {
      const llmDefinitions = this.form.get(LLMDefinitionForm.ConceptLLMList) as FormArray;
      if (llmDefinitions.length > 0) {
        llmDefinitions.removeAt(index);
      }
      this.form.markAsDirty();
    };
  }

  @ViewChild('addLLM', { static: false }) addLLMDefinitionBtn: ElementRef;
  scrollToAddLLMButton() {
    setTimeout(() => {
      this.addLLMDefinitionBtn.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }

  private checkDeployedAndUpdateAction(conceptLLmsValues) {
    let shouldRedirectToLatestDeployed = false;
    return this.store.dispatch(new CheckDeployedUsages(this.concept.id)).pipe(
      first(),
      switchMap(() => {
        const hasDeployedUsages = this.store.selectSnapshot(TaxonomyConceptState.hasDeployedUsages);
        if (hasDeployedUsages) {
          return this.openDeployedUsageModal().pipe(
            tap(value => {
              shouldRedirectToLatestDeployed = value;
            })
          );
        } else {
          return of(true);
        }
      }),
      switchMap(response => {
        if (response) {
          if (shouldRedirectToLatestDeployed) {
            window.open(`${getConfig('rulesWebUrl')}/latestDeployedDocuments?conceptId=${this.concept.id}`, '_blank');
          }
          return this.store.dispatch(
            new UpsertConceptLLM({
              conceptId: this.concept.id,
              conceptLLMList: conceptLLmsValues
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

  private openDeployedUsageModal() {
    const data: DeployedUsageModalData = {
      title: 'Warning',
      message:
        'The term you’re updating is being used in deployed versions of rules. If you want these changes to be reflected in those rules, new versions must be created.' +
        '<br><br>By continuing the term will be updated and you’ll be prompted to create new versions of the latest versions of deployed impacted rules.',
      actionLabel: 'Update term and continue'
    };

    const dialogRef = this.dialog.open(DeployedUsageModalComponent, {
      data: data,
      ...DeployedUsageModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed();
  }

  private initForm() {
    this.form = new FormGroup({});
    this.form.addControl(
      LLMDefinitionForm.Term,
      new FormControl(
        {
          value: undefined,
          disabled: !this.hasEditAccess
        },
        Validators.required
      )
    );
    this.form.addControl(
      LLMDefinitionForm.TermId,
      new FormControl(
        {
          value: undefined,
          disabled: true
        },
        [Validators.required, Validators.pattern(ConceptUtils.TERM_ID_PATTERN)]
      )
    );
    this.form.addControl(
      LLMDefinitionForm.Definition,
      new FormControl(
        {
          value: undefined,
          disabled: !this.hasEditAccess
        },
        Validators.required
      )
    );
  }

  private subscribeToConcept() {
    this.store
      .select(TaxonomyConceptState.concept)
      .pipe(
        untilDestroyed(this),
        filter(concept => !!concept)
      )
      .subscribe(concept => {
        this.concept = concept;
      });
  }

  private setForm() {
    const allProviders = this.store.selectSnapshot(ConceptLlmState.availableLLMOptions);
    const defaultConceptLLM = this.conceptLLms.find(cLLM => cLLM.llms.length === 0);
    const specificConceptLLM = this.conceptLLms.filter(cLLM => cLLM.llms.length > 0);
    this.form.get(LLMDefinitionForm.TermId).setValue(this.concept.termId);
    if (defaultConceptLLM) {
      this.form.get(LLMDefinitionForm.Term).setValue(defaultConceptLLM.term);
      this.form.get(LLMDefinitionForm.Definition).setValue(defaultConceptLLM.definition);
      this.isAddLLMDefinitionBtnEnabled = true;
    }
    if (specificConceptLLM) {
      this.form.addControl(LLMDefinitionForm.ConceptLLMList, new FormArray([]));
      const llmDefinitions = this.form.get(LLMDefinitionForm.ConceptLLMList) as FormArray;
      specificConceptLLM.forEach(value => {
        const llms = allProviders.filter(oP => value.llms.some(llmValue => llmValue === oP.id));
        const formGroup = new FormGroup({
          [LLMDefinitionForm.LLMS]: new FormControl(
            { value: llms || null, disabled: !this.hasEditAccess },
            Validators.required
          ),
          [LLMDefinitionForm.Term]: new FormControl(
            { value: value.term, disabled: !this.hasEditAccess },
            Validators.required
          ),
          [LLMDefinitionForm.Definition]: new FormControl(
            { value: value.definition, disabled: !this.hasEditAccess },
            Validators.required
          )
        });
        llmDefinitions.push(formGroup);
      });
    }
    this.oldValueForm = JSON.stringify(this.form.value);
    this.isFormLoaded = true;
    if (this.isFormDirty) {
      this.form.markAsDirty();
      this.isFromValueChanged = true;
    }
  }

  private loadLLMOptionProviders() {
    const llmOptionProviders = this.store.selectSnapshot(ConceptLlmState.availableLLMOptions);
    if (llmOptionProviders?.length === 0) {
      this.store
        .dispatch(new LoadAvailableLLMOptions(this.concept.owner))
        .pipe(first(), untilDestroyed(this))
        .subscribe(() => {
          const llmOptions = this.store.selectSnapshot(ConceptLlmState.availableLLMOptions);
          this.areLLMTermOptionsLoaded$.next(true);
          this.allOptionsProviders = llmOptions;
          this.availableOptions$ = of(this.allOptionsProviders);
        });
    } else {
      this.areLLMTermOptionsLoaded$.next(true);
      this.allOptionsProviders = llmOptionProviders;
      this.availableOptions$ = of(this.allOptionsProviders);
    }
  }
  private loadConceptLLm() {
    if (this.isFormDirty) {
      const conceptLLmsStore = this.store.selectSnapshot(ConceptLlmState.conceptLLMList);
      this.conceptLLms = cloneDeep(conceptLLmsStore);
      this.areConceptLLmLoaded$.next(true);
    } else {
      this.store
        .dispatch(new GetConceptLLM(this.concept.id))
        .pipe(first(), untilDestroyed(this))
        .subscribe(() => {
          const conceptLLmsStore = this.store.selectSnapshot(ConceptLlmState.conceptLLMList);
          this.conceptLLms = cloneDeep(conceptLLmsStore);
          this.areConceptLLmLoaded$.next(true);
        });
    }
  }

  private formatValue() {
    const llmDefinitions = this.form.value.conceptLLMList.map(llmDefinition => {
      return {
        term: llmDefinition.term,
        definition: llmDefinition.definition,
        llms: llmDefinition.llms.map(llm => llm.id)
      };
    });
    llmDefinitions.push({
      term: this.form.value.term,
      definition: this.form.value.definition,
      llms: []
    });
    return llmDefinitions;
  }

  public updateConceptLLMLocally() {
    const conceptLLMList = this.store.selectSnapshot(ConceptLlmState.conceptLLMList);
    const conceptLLmValues = this.formatValue();

    if (conceptLLMList && this.isFormLoaded && this.oldValueForm !== JSON.stringify(this.form.value)) {
      return this.store.dispatch(
        new UpdateConceptLLMLocally({
          conceptLLMList: conceptLLmValues
        })
      );
    } else {
      return of(false);
    }
  }
}
