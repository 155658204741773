export enum CollectionType {
  USER_ONLY = 'USER_ONLY',
  SHARED_BY_ME = 'SHARED_BY_ME',
  SHARED_WITH_ME = 'SHARED_WITH_ME'
}

export interface CollectionModel {
  id?: string;
  name?: string;
}

export interface CollectionDetailModel {
  id: string;
  name: string;
  type: CollectionType;
  sharedGroups: string[];
}
