/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SearchConceptValuesForTranslationsCompareViewFilters } from './searchConceptValuesForTranslationsCompareViewFilters';


export interface SearchConceptValuesForTranslationsCompareViewRequest { 
    collectionId?: string;
    filters?: SearchConceptValuesForTranslationsCompareViewFilters;
    page?: number;
    pageSize?: number;
    sortBy?: SearchConceptValuesForTranslationsCompareViewRequest.SortByEnum;
    sortDirection?: SearchConceptValuesForTranslationsCompareViewRequest.SortDirectionEnum;
}
export namespace SearchConceptValuesForTranslationsCompareViewRequest {
    export type SortByEnum = 'TERM' | 'DEFINITION' | 'JURISDICTION' | 'TRANSLATION';
    export const SortByEnum = {
        TERM: 'TERM' as SortByEnum,
        DEFINITION: 'DEFINITION' as SortByEnum,
        JURISDICTION: 'JURISDICTION' as SortByEnum,
        TRANSLATION: 'TRANSLATION' as SortByEnum
    };
    export type SortDirectionEnum = 'ASC' | 'DESC';
    export const SortDirectionEnum = {
        ASC: 'ASC' as SortDirectionEnum,
        DESC: 'DESC' as SortDirectionEnum
    };
}


