<apx-template-modal [data]="data" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <mat-dialog-content class="remove-from-my-collection-modal--content">
        <div>
          If you remove the selected terms, they will also be removed from the 'My collections' that they have been
          added to.
        </div>
        <div>
          The terms you want to remove are part of your following collections:
          <ul class="collection-names">
            <li *ngFor="let collectionName of affectedCollections">{{ collectionName }}</li>
          </ul>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="remove-from-my-collection-modal--footer">
        <div class="button-wrapper">
          <apx-action-button
            [colorClass]="'primary-action'"
            [loadingName]="'Removing...'"
            [name]="'Remove from all the collections'"
            [method]="remove()"
          >
          </apx-action-button>
        </div>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
