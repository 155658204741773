// angular
import { Injectable } from '@angular/core';
// module
import { ConsoleService } from './console.service';
import { getConfig } from 'shared/app/config';

@Injectable()
export class LogService {
  constructor(public log: ConsoleService) {}

  // debug (standard output)
  public debug(msg: any) {
    if (getConfig('logging').DEBUG.LEVEL_4) {
      // console.debug does not work on {N} apps... use `log`
      this.log.log(msg);
    }
  }

  // error
  public error(err: any) {
    if (getConfig('logging').DEBUG.LEVEL_4 || getConfig('logging').DEBUG.LEVEL_3) {
      this.log.error(err);
    }
  }

  // warn
  public warn(err: any) {
    if (getConfig('logging').DEBUG.LEVEL_4 || getConfig('logging').DEBUG.LEVEL_2) {
      this.log.warn(err);
    }
  }

  // info
  public info(err: any) {
    if (getConfig('logging').DEBUG.LEVEL_4 || getConfig('logging').DEBUG.LEVEL_1) {
      this.log.info(err);
    }
  }
}
