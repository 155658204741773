import { ChipsWithAdditionOption, TemplatePickerOption } from '@apiax/web-commons';
import { isEqual } from 'lodash-es';
import {
  GetConceptResponse,
  GetConceptRuleSetRef,
  GetConceptTranslation,
  RuleSetFamily,
  UpdateConceptRequest
} from '../../../generated/v3';
import { Concept, ConceptState, ConceptTranslation, RuleSetRef } from '../../models';
import { ConceptUtils } from '../utils/concept.utils';

export class TaxonomyConceptMapper {
  static mapToConcept(generatedModel: GetConceptResponse): Concept {
    return {
      id: generatedModel.id,
      termId: generatedModel.termId,
      term: generatedModel.term,
      definition: generatedModel.definition,
      owner: generatedModel.owner,
      isPrivate: generatedModel.isPrivate,
      state: this.mapToConceptState(generatedModel.state),
      ruleSets: generatedModel.ruleSets.map(obj => this.mapToRuleSets(obj)),
      jurisdictions: generatedModel.jurisdictions,
      categories: generatedModel.categories,
      translations: this.mapToTranslations(generatedModel.translations),
      updatedAt: generatedModel.updateDate
    };
  }

  static mapToConceptState(generatedModel: any): ConceptState {
    switch (generatedModel) {
      case 'DEPRECATED':
        return ConceptState.Deprecated;
      case 'ACTIVE':
        return ConceptState.Active;
      case 'INACTIVE':
        return ConceptState.Inactive;
    }
  }

  static mapToRuleSets(obj: GetConceptRuleSetRef) {
    return { ruleSetId: obj.ruleSetId, ruleSetFamilyId: obj.ruleSetFamilyId };
  }

  static mapToTranslations(translations: { [p: string]: GetConceptTranslation }): { [p: string]: ConceptTranslation } {
    const result: { [p: string]: ConceptTranslation } = {};
    if (translations) {
      Object.keys(translations).forEach(language => {
        result[language] = {
          term: translations[language].term,
          definition: translations[language].definition
        };
      });
    }
    return result;
  }

  static mapToConceptRuleSetRef(generatedModel: RuleSetFamily): RuleSetRef[] {
    return generatedModel.ruleSetIds.map(r => ({
      ruleSetFamilyId: generatedModel.ruleSetFamilyId,
      ruleSetId: r
    }));
  }

  static mapToGeneratedConceptRuleSets(ruleSets: ChipsWithAdditionOption[]): RuleSetFamily[] {
    const values: RuleSetFamily[] = [];

    ruleSets.forEach(obj => {
      const ruleSetRef: RuleSetRef = ConceptUtils.splitConceptRuleSet(obj.id);
      const index = values.findIndex(item => isEqual(item.ruleSetFamilyId, ruleSetRef.ruleSetFamilyId));

      if (index > -1) {
        values[index].ruleSetIds.push(ruleSetRef.ruleSetId);
      } else {
        values.push({
          ruleSetFamilyId: ruleSetRef.ruleSetFamilyId,
          ruleSetIds: [ruleSetRef.ruleSetId]
        });
      }
    });

    return values;
  }

  static mapToConceptRuleSets(ruleSets: ChipsWithAdditionOption[]): RuleSetRef[] {
    return ruleSets.map(rs => ConceptUtils.splitConceptRuleSet(rs.id));
  }

  static mapToGeneratedConceptJurisdictions(jurisdictions: TemplatePickerOption[]): string[] {
    return jurisdictions ? jurisdictions.map(obj => obj.id) : [];
  }

  static mapToGeneratedConceptCategories(categories: Set<string>): string[] {
    return categories ? Array.from(categories.values()) : [];
  }

  static mapToGeneratedConceptState(model: ConceptState): UpdateConceptRequest.StateEnum {
    switch (model) {
      case ConceptState.Deprecated:
        return UpdateConceptRequest.StateEnum.DEPRECATED;
      case ConceptState.Active:
        return UpdateConceptRequest.StateEnum.ACTIVE;
      case ConceptState.Inactive:
        return UpdateConceptRequest.StateEnum.INACTIVE;
    }
  }
}
