/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RuleSearchRequestCriteria { 
    searchText?: string;
    ruleSetFamilyIds?: Array<string>;
    ruleSetIds?: Array<string>;
    jurisdictions?: Array<string>;
    documentTypes?: Array<RuleSearchRequestCriteria.DocumentTypesEnum>;
    contentBy?: Array<string>;
    activationStates?: Array<RuleSearchRequestCriteria.ActivationStatesEnum>;
    versions?: Array<string>;
}
export namespace RuleSearchRequestCriteria {
    export type DocumentTypesEnum = 'TEMPLATE' | 'COUNTRY_RULE' | 'CUSTOM_RULE';
    export const DocumentTypesEnum = {
        TEMPLATE: 'TEMPLATE' as DocumentTypesEnum,
        COUNTRY_RULE: 'COUNTRY_RULE' as DocumentTypesEnum,
        CUSTOM_RULE: 'CUSTOM_RULE' as DocumentTypesEnum
    };
    export type ActivationStatesEnum = 'INACTIVE' | 'TEST' | 'PROD';
    export const ActivationStatesEnum = {
        INACTIVE: 'INACTIVE' as ActivationStatesEnum,
        TEST: 'TEST' as ActivationStatesEnum,
        PROD: 'PROD' as ActivationStatesEnum
    };
}


