import { CollectionType } from '../../../models/collection-model';
import { SearchCriteria, StorageData } from '@apiax/web-commons';
import { SearchConceptsByCollectionRequest } from '../../../../generated/v3';

export enum CollectionsAction {
  GetMyCollections = '[Get My collections] Get collections that the user has access to',
  GetCollections = '[Get collections] Get collections based on the criteria',
  AddToCollection = '[Add to Collection] Adds Terms to a collection',
  EditCollection = '[Edit Collection] Edits the name and shared groups of a collection',
  DeleteCollection = '[Delete Collection] Deletes a collection',
  IsCollectionNameValid = '[Is Collection Name Valid] Checks if the collection name is valid (not duplicated) based on the collections user has access to',
  IsCollectionNameValidExcludeSelf = '[Is Collection Name Valid Exclude Self] Checks if the collection name is valid (not duplicated) based on the collections user has access to, excluding the collection of the sent id',
  SetSelectedCollection = '[Set Selected collection] Saves the selected collection',
  LoadAffectMyCollectionNames = '[Load Affect My Collection Names] Load affected collection names',
  CreateCollection = '[Create Collection] Creates a new collection of terms',
  RemovedFromMyCollection = '[Remove From My Collection] Remove terms from my collection',
  RemovedFromCollection = '[Remove From Collection] Remove terms from a collection',
  LoadGroups = '[Load Groups] Loads all groups user has access to',
  ExportCollection = '[Export Collection] Export a Collection of terms',
  ExportTerms = '[Export Terms] Export all terms',
  SetCollectionsStorageData = '[Set Collections Storage Data] save the collections storage data',
  SetMyCollectionData = '[Set My Collection Data] set the data of my collections',
  SetDataByCollection = '[Set Data By Collection] set the data by collection',
  SetKeepCollectionState = '[Set Keep Collection State] Must keep collection state (sorts, selections...)'
}

export class GetCollections {
  public static readonly type = CollectionsAction.GetCollections;

  constructor(public collectionTypes: CollectionType[]) {}
}

export class CreateCollection {
  public static readonly type = CollectionsAction.CreateCollection;

  constructor(
    public payload: {
      name: string;
      selections: string[];
      exclusions: string[];
      sharedGroups: string[];
      searchText: string;
      isExactMatch: boolean;
      searchCriteria: SearchCriteria;
    }
  ) {}
}

export class AddToCollection {
  public static readonly type = CollectionsAction.AddToCollection;

  constructor(
    public payload: {
      id: string;
      name: string;
      selections: string[];
      exclusions: string[];
      sharedGroups: string[];
      searchText: string;
      isExactMatch: boolean;
      searchCriteria: SearchCriteria;
    }
  ) {}
}

export class EditCollection {
  public static readonly type = CollectionsAction.EditCollection;

  constructor(public payload: { id: string; name: string; sharedGroups: string[] }) {}
}

export class DeleteCollection {
  public static readonly type = CollectionsAction.DeleteCollection;

  constructor(public id: string) {}
}

export class IsCollectionNameValid {
  public static readonly type = CollectionsAction.IsCollectionNameValid;

  constructor(public name: string) {}
}

export class IsCollectionNameValidExcludeSelf {
  public static readonly type = CollectionsAction.IsCollectionNameValidExcludeSelf;

  constructor(public payload: { collectionId: string; name: string }) {}
}

export class GetMyCollections {
  public static readonly type = CollectionsAction.GetMyCollections;
}

export class SetSelectedCollection {
  public static readonly type = CollectionsAction.SetSelectedCollection;

  constructor(public selectedEntry: string) {}
}

export class LoadAffectMyCollectionNames {
  public static readonly type = CollectionsAction.LoadAffectMyCollectionNames;

  constructor(public payload: { excludedIds: string[]; selectedIds: string[] }) {}
}

export class RemoveFromMyCollection {
  public static readonly type = CollectionsAction.RemovedFromMyCollection;

  constructor(public payload: { excludedIds: string[]; selectedIds: string[] }) {}
}

export class RemoveFromCollection {
  public static readonly type = CollectionsAction.RemovedFromCollection;

  constructor(public payload: { collectionId: string; excludedIds: string[]; includedIds: string[] }) {}
}

export class LoadGroups {
  public static readonly type = CollectionsAction.LoadGroups;
}

export class ExportCollection {
  public static readonly type = CollectionsAction.ExportCollection;

  constructor(
    public payload: {
      collectionId: string;
      sortBy: string;
      sortDirection: string;
      selectedIds: string[];
      excludedIds: string[];
    }
  ) {}
}

export class ExportTerms {
  public static readonly type = CollectionsAction.ExportTerms;

  constructor(
    public payload: { sortBy: string; sortDirection: string; selectedIds: string[]; excludedIds: string[] }
  ) {}
}

export class SetCollectionsStorageData {
  public static readonly type = CollectionsAction.SetCollectionsStorageData;

  constructor(public storageData: StorageData) {}
}

export class SetMyCollectionsData {
  public static readonly type = CollectionsAction.SetMyCollectionData;

  constructor(public searchConceptsByCollectionRequest: SearchConceptsByCollectionRequest) {}
}

export class SetDataByCollection {
  public static readonly type = CollectionsAction.SetDataByCollection;

  constructor(public searchConceptsByCollectionRequest: SearchConceptsByCollectionRequest) {}
}

export class SetKeepCollectionState {
  public static readonly type = CollectionsAction.SetKeepCollectionState;

  constructor(public payload: boolean) {}
}
