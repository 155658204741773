/**
 * Taxonomy API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddToCollectionResponse { 
    id?: string;
    name?: string;
    sharedGroups?: Array<string>;
    createdBy?: string;
    createdAt?: string;
    updatedBy?: string;
    updatedAt?: string;
    type?: AddToCollectionResponse.TypeEnum;
}
export namespace AddToCollectionResponse {
    export type TypeEnum = 'USER_ONLY' | 'SHARED_BY_ME' | 'SHARED_WITH_ME';
    export const TypeEnum = {
        USER_ONLY: 'USER_ONLY' as TypeEnum,
        SHARED_BY_ME: 'SHARED_BY_ME' as TypeEnum,
        SHARED_WITH_ME: 'SHARED_WITH_ME' as TypeEnum
    };
}


