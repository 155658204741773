import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Activity, AuthorizationService } from 'shared/core';
import { OrganizationsMapper } from '../mappers/organizations-mapper';
import { TaxonomyConceptState } from '../stores/taxonomy-concept';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyUtilsService {
  public currentUserPermissions = new Map<Activity, boolean>();
  public rootSelectedTabIndex = 0;

  private _conceptSelectedTabIndex = 0;
  public previousConceptTabIndex = 0;

  private _searchOnRulesSelectedTabIndex = 0;
  public previousSearchOnRulesTabIndex = 0;

  public isCreateMode$ = new BehaviorSubject<boolean>(false);
  public isTermEditorTranslationMode$ = new BehaviorSubject<boolean>(false);

  public get conceptSelectedTabIndex() {
    return this._conceptSelectedTabIndex;
  }

  public set conceptSelectedTabIndex(n: number) {
    this.previousConceptTabIndex = this._conceptSelectedTabIndex;
    this._conceptSelectedTabIndex = n;
  }

  public set searchOnRulesSelectedTabIndex(n: number) {
    this.previousSearchOnRulesTabIndex = this._searchOnRulesSelectedTabIndex;
    this._searchOnRulesSelectedTabIndex = n;
  }

  constructor(
    private authorizationService: AuthorizationService,
    private store: Store
  ) {
    this.getCurrentUserPermissions();
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  public getCurrentUser() {
    return this.authorizationService.currentUser.getValue();
  }

  public hasTaxonomyAccess(): boolean {
    return this.currentUserPermissions.get(Activity.TaxonomyAccess);
  }

  public hasTechnicalAdministration(): boolean {
    return this.currentUserPermissions.get(Activity.TechnicalAdministration);
  }

  public hasTaxonomyLatestChangesAccess(): boolean {
    return this.currentUserPermissions.get(Activity.TaxonomyLatestChanges);
  }

  public hasTaxonomyExportDataAccess(): boolean {
    return this.currentUserPermissions.get(Activity.ExportData);
  }

  public hasAllCompaniesViewAccess(): boolean {
    return this.currentUserPermissions.get(Activity.AllCompaniesView);
  }

  hasTaxonomyMyCollectionAccess() {
    return this.currentUserPermissions.get(Activity.MyCollectionsAccess);
  }

  public canCreateConcept(): boolean {
    return this.currentUserPermissions.get(Activity.EditTerms);
  }

  public canUpdateConcept(checkOnlyPermissions = false): boolean {
    const userOrgId = this.getCurrentUser().organizationId;
    const conceptOwnerId = this.store.selectSnapshot(TaxonomyConceptState.concept)?.owner;

    return checkOnlyPermissions
      ? this.currentUserPermissions.get(Activity.EditTerms) && this.hasAllCompaniesViewAccess()
      : this.currentUserPermissions.get(Activity.EditTerms) &&
          (this.hasAllCompaniesViewAccess() || isEqual(userOrgId, conceptOwnerId));
  }

  public canUpdateConceptPrivacy(conceptOwnerId): boolean {
    const userOrgId = this.getCurrentUser().organizationId;
    const isUserApiax = this.isValueApiax(userOrgId);
    const isConceptOwnerApiax = this.isValueApiax(conceptOwnerId);

    return this.currentUserPermissions.get(Activity.EditTerms) && isUserApiax && isConceptOwnerApiax;
  }

  public canCloneConcept(): boolean {
    return this.currentUserPermissions.get(Activity.EditTerms);
  }

  public isValueApiax(value: string): boolean {
    return OrganizationsMapper.isApiaxCompany(value);
  }
}
