import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class DataResolver {
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return of({
      res:
        /* tslint:disable */
        `Angular 2 Seed Advanced is a starter project that implements best practices in  coding, building and testing Angular 2 apps.
Additionally it adds various enhancements and integrations for large global projects.`
      /* tslint:enable */
    });
  }
}
