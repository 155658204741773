import { PerformConceptPartialExportCriteria } from '../../../generated/v3';
import ConceptPartialExportTypeEnum = PerformConceptPartialExportCriteria.ConceptPartialExportTypeEnum;

export enum PartialExportType {
  CONCEPT_ID = 'CONCEPT_ID',
  CONCEPT_TERM_ID = 'CONCEPT_TERM_ID',
  ORGANIZATION_ID = 'ORGANIZATION_ID'
}

export class TaxonomyExportDataMapper {
  static mapToPartialExportRequest(type: PartialExportType, values: string[]): PerformConceptPartialExportCriteria {
    return {
      conceptPartialExportType: this.mapToRequestType(type),
      values: values
    };
  }

  private static mapToRequestType(type: PartialExportType): ConceptPartialExportTypeEnum {
    switch (type) {
      case PartialExportType.ORGANIZATION_ID:
        return ConceptPartialExportTypeEnum.ORGANIZATION_ID;
      case PartialExportType.CONCEPT_ID:
        return ConceptPartialExportTypeEnum.CONCEPT_ID;
      case PartialExportType.CONCEPT_TERM_ID:
        return ConceptPartialExportTypeEnum.CONCEPT_TERM_ID;
    }
  }
}
