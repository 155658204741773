import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateModalComponent, TemplateModalData } from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ConceptUtils } from '../../../../domain/utils/concept.utils';
import { GenericUtils } from '../../../../domain/utils/generic.utils';
import { Jurisdiction } from '../../../../models/jurisdiction';
import { ProductRuleSet } from '../../../../models/product-rule-set';

export interface ConceptsJurisdictionsModalData {
  productRuleSets: ProductRuleSet[];
  selections: string[];
}

export interface ConceptsJurisdictionsModalTableRow {
  name: string;
  alpha3: string;
}

@UntilDestroy()
@Component({
  selector: 'app-concept-jurisdictions-modal',
  templateUrl: './concept-jurisdictions-modal.component.html',
  styleUrls: ['./concept-jurisdictions-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConceptJurisdictionsModalComponent implements OnInit, AfterViewInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '512px',
    minHeight: 240
  };

  public readonly columns: string[] = ['selected', 'name', 'code'];

  @ViewChild(MatSort) sort: MatSort;

  private searchValue$ = new Subject<string>();
  private rows: ConceptsJurisdictionsModalTableRow[] = [];
  private isGlobalSelected = false;

  public templateData: TemplateModalData;
  public dataSource = new MatTableDataSource<ConceptsJurisdictionsModalTableRow>();
  public selection: SelectionModel<ConceptsJurisdictionsModalTableRow>;
  public isLoading$: Observable<boolean>;
  public footNote: string;

  constructor(
    public dialogRef: MatDialogRef<ConceptJurisdictionsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConceptsJurisdictionsModalData
  ) {
    this.templateData = {
      title: 'Jurisdictions',
      hideClose: false,
      disableClose: false
    };

    this.selection = new SelectionModel<ConceptsJurisdictionsModalTableRow>(true, []);
  }

  private retrieveJurisdictions() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'selected':
          return !this.selection.selected.includes(item);
        default:
          return item[property];
      }
    };

    const jurisdictions: Jurisdiction[] = [];
    this.data.productRuleSets?.forEach(item => {
      item.jurisdictions.forEach(obj => {
        if (!jurisdictions.includes(obj)) {
          jurisdictions.push(obj);
        }
      });
    });

    if (!jurisdictions.some(j => j.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3)) {
      jurisdictions.push({
        name: ConceptUtils.JURISDICTION_GLOBAL.name,
        alpha3: ConceptUtils.JURISDICTION_GLOBAL.alpha3,
        code: ConceptUtils.JURISDICTION_GLOBAL.alpha3
      });
    }

    jurisdictions.forEach(obj => {
      if (!this.rows.find(r => r.alpha3 === obj.alpha3)) {
        this.rows.push({
          name: obj.name,
          alpha3: obj.alpha3
        });
      }
    });

    this.selection = new SelectionModel<ConceptsJurisdictionsModalTableRow>(true, [
      ...this.rows.filter(row => this.data.selections.some(selected => row.alpha3 === selected))
    ]);

    this.rows = GenericUtils.sortCollection(this.rows, 'name');

    this.dataSource.data = this.rows;
  }

  ngOnInit() {
    this.retrieveJurisdictions();

    this.searchValue$.pipe(untilDestroyed(this), debounceTime(200), distinctUntilChanged()).subscribe(searchText => {
      this.dataSource.filter = isEmpty(searchText) ? null : searchText;
    });

    this.isGlobalSelected =
      this.selection.selected.length === 0 ||
      this.selection.selected.find(sel => sel.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3) !== undefined;

    if (this.isGlobalSelected) {
      const global = this.getGlobalRow();
      this.selection.select(global);
      this.isGlobalSelected = true;
    }

    this.updateFootNote();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public onSearch(text: string) {
    this.searchValue$.next(text);
  }

  public onConfirmation() {
    this.dialogRef.close(this.selection.selected);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }

  private getGlobalRow() {
    return this.rows.find(r => r.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3);
  }

  private updateFootNote() {
    this.footNote = 'Assigning ' + this.selection.selected.length + ' jurisdiction(s)';
  }

  public onSelectionChange(event, row: ConceptsJurisdictionsModalTableRow) {
    if (event) {
      let globalIsDeselected = false;
      if (event.checked) {
        if (this.isGlobalSelected) {
          if (row.alpha3 !== ConceptUtils.JURISDICTION_GLOBAL.alpha3) {
            const global = this.selection.selected
              .filter(sel => sel)
              .find(sel => sel.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3);
            this.selection.deselect(global);
            this.isGlobalSelected = false;
          }
        } else {
          if (row.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3) {
            this.selection.clear();
            this.isGlobalSelected = true;
          }
        }
      } else {
        if (this.selection.selected.length === 1) {
          globalIsDeselected = row.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3;

          if (!globalIsDeselected) {
            const global = this.getGlobalRow();
            this.selection.select(global);
            this.isGlobalSelected = true;
          }
        }
      }
      this.selection.toggle(row);

      this.updateFootNote();

      if (globalIsDeselected) {
        setTimeout(() => {
          const global = this.getGlobalRow();
          this.selection.select(global);
        }, 100);
      }
    }
  }

  public toggleAll() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
        if (row.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3) {
          this.selection.select(row);
        } else {
          this.selection.deselect(row);
        }
      });
      this.isGlobalSelected = true;
    } else {
      this.dataSource.data.forEach(row => {
        if (row.alpha3 === ConceptUtils.JURISDICTION_GLOBAL.alpha3) {
          this.selection.deselect(row);
        } else {
          this.selection.select(row);
        }
      });
      this.isGlobalSelected = false;
    }

    this.updateFootNote();
  }

  public isAllSelected() {
    const selectionsSize = this.selection.selected.length;
    const rowsSize = this.dataSource.data.length - 1; // global cannot be selected when all selected

    return selectionsSize === rowsSize;
  }
}
