import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'shared/core';

@Injectable()
export class TaxonomyAllowedGuard implements CanActivate, CanActivateChild {
  constructor(private authorizationService: AuthorizationService) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    if (route.data.hasOwnProperty('activities')) {
      return this.authorizationService.isAllowed(route.data.activities);
    }

    return true;
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute);
  }
}
