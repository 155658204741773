import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { OrganizationsApiService } from '../../../../generated/v3';
import { Owner } from '../../../models/owner';
import { ProductRuleSet } from '../../../models/product-rule-set';
import { ClearOrganizationState, LoadAccessibleOwners, LoadProductRuleSets } from './organizations.action';
import { OrganizationsStateUseCases } from './usecases/organizations-usecases.state';

export interface OrganizationsStateModel {
  accessibleOwners: Owner[];
  isLoadingAccessibleOwners: boolean;
  productRuleSets: Map<string, ProductRuleSet[]>;
  isLoadingProductRuleSets: boolean;
}

export const DEFAULT_STATE: OrganizationsStateModel = {
  accessibleOwners: [],
  isLoadingAccessibleOwners: false,
  productRuleSets: new Map<string, ProductRuleSet[]>(),
  isLoadingProductRuleSets: false
};

@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults: DEFAULT_STATE
})
@Injectable()
export class OrganizationsState {
  private organizationsStateUseCases: OrganizationsStateUseCases;

  @Selector()
  public static accessibleOwners(state: OrganizationsStateModel): Owner[] {
    return cloneDeep(state.accessibleOwners);
  }

  @Selector()
  public static isLoadingAccessibleOwners(state: OrganizationsStateModel): boolean {
    return state.isLoadingAccessibleOwners;
  }

  @Selector()
  public static productRuleSets(state: OrganizationsStateModel): Map<string, ProductRuleSet[]> {
    return cloneDeep(state.productRuleSets);
  }

  @Selector()
  public static isLoadingProductRuleSets(state: OrganizationsStateModel): boolean {
    return state.isLoadingProductRuleSets;
  }

  constructor(organizationsApiService: OrganizationsApiService) {
    this.organizationsStateUseCases = new OrganizationsStateUseCases(organizationsApiService);
  }

  @Action(LoadAccessibleOwners)
  public loadAccessibleOwners(context: StateContext<OrganizationsStateModel>) {
    return this.organizationsStateUseCases.loadAccessibleOwners(context);
  }

  @Action(LoadProductRuleSets)
  public loadProductRuleSets(context: StateContext<OrganizationsStateModel>) {
    return this.organizationsStateUseCases.loadProductRuleSets(context);
  }

  @Action(ClearOrganizationState)
  public clear(context: StateContext<OrganizationsStateModel>) {
    return this.organizationsStateUseCases.clearOrganizationState(context);
  }
}
