import { Language } from '../../../generated/v3';
import { TranslationLanguage } from '../../models/translation-language';

export class TranslationLanguagesMapper {
  static mapToTranslationLanguage(generatedModel: Language): TranslationLanguage {
    return {
      code: generatedModel.code,
      name: generatedModel.name,
      country: generatedModel.country
    };
  }
}
