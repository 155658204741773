import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-teaser-modal',
  templateUrl: './teaser-modal.component.html',
  styleUrls: ['./teaser-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeaserModalComponent {
  @Input()
  public title: string;
  @Input()
  public message: string;
  @Input()
  public topPosition: number;
}
