import { CollectionDetailModel, CollectionModel } from '../../../models/collection-model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  AddToCollection,
  CreateCollection,
  DeleteCollection,
  EditCollection,
  ExportCollection,
  ExportTerms,
  GetCollections,
  GetMyCollections,
  IsCollectionNameValid,
  IsCollectionNameValidExcludeSelf,
  LoadAffectMyCollectionNames,
  LoadGroups,
  RemoveFromCollection,
  RemoveFromMyCollection,
  SetCollectionsStorageData,
  SetDataByCollection,
  SetKeepCollectionState,
  SetMyCollectionsData,
  SetSelectedCollection
} from './collections.action';
import { CollectionsStateUseCases } from './usecases/collections.usecase';
import { GroupWeb } from '../../../models/group-web';
import { CollectionsService } from '../../../../generated/v3';
import { StorageData } from '@apiax/web-commons';
import { ConceptCollection } from '../../../models/concept-collection';

export interface CollectionsStateModel {
  isLoading: boolean;
  myCollections: CollectionModel[];
  mySharedCollections: CollectionModel[];
  collectionsSharedWithMe: CollectionModel[];
  collections: CollectionDetailModel[];
  searchCollections: ConceptCollection[];
  isLoadingSearchCollections: boolean;
  totalSearchCollections: number;
  selectedCollection: string;
  isLoadingAffectedMyCollectionNames: boolean;
  affectMyCollectionNames: string[];
  isLoadingCollections: boolean;
  isLoadingGroups: boolean;
  isCollectionNameValidExcludeSelf: boolean;
  isCollectionNameValid: boolean;
  groups: GroupWeb[];
  storageData: StorageData;
  keepCollectionState: boolean;
}

export const DEFAULT_STATE: CollectionsStateModel = {
  isLoading: false,
  myCollections: [],
  mySharedCollections: [],
  collectionsSharedWithMe: [],
  collections: [],
  searchCollections: [],
  isLoadingSearchCollections: false,
  totalSearchCollections: 0,
  selectedCollection: '',
  isLoadingAffectedMyCollectionNames: false,
  affectMyCollectionNames: [],
  isLoadingCollections: false,
  isCollectionNameValidExcludeSelf: false,
  isCollectionNameValid: false,
  isLoadingGroups: false,
  groups: [],
  storageData: {
    filtersSelectionData: undefined,
    sortBy: undefined,
    selected: [],
    sortOrder: 'ASC',
    isSeeingSelection: false,
    searchValue: undefined,
    page: 0
  },
  keepCollectionState: false
};

@State<CollectionsStateModel>({
  name: 'collections',
  defaults: DEFAULT_STATE
})
@Injectable()
export class CollectionsState {
  collectionsStateUseCases: CollectionsStateUseCases;

  @Selector()
  public static isLoading(state: CollectionsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  public static myCollections(state: CollectionsStateModel): CollectionModel[] {
    return state.myCollections;
  }

  @Selector()
  public static mySharedCollections(state: CollectionsStateModel): CollectionModel[] {
    return state.mySharedCollections;
  }

  @Selector()
  public static collectionsSharedWithMe(state: CollectionsStateModel): CollectionModel[] {
    return state.collectionsSharedWithMe;
  }

  @Selector()
  public static isLoadingAffectedMyCollectionNames(state: CollectionsStateModel): boolean {
    return state.isLoadingAffectedMyCollectionNames;
  }

  @Selector()
  public static affectMyCollectionNames(state: CollectionsStateModel): string[] {
    return state.affectMyCollectionNames;
  }

  @Selector()
  public static selectedCollection(state: CollectionsStateModel): string {
    return state.selectedCollection;
  }

  @Selector()
  public static collections(state: CollectionsStateModel): CollectionDetailModel[] {
    return state.collections;
  }

  @Selector()
  public static isLoadingCollections(state: CollectionsStateModel): boolean {
    return state.isLoadingCollections;
  }

  @Selector()
  public static isLoadingGroups(state: CollectionsStateModel): boolean {
    return state.isLoadingGroups;
  }

  @Selector()
  public static isCollectionNameValidExcludeSelf(state: CollectionsStateModel): boolean {
    return state.isCollectionNameValidExcludeSelf;
  }

  @Selector()
  public static isCollectionNameValid(state: CollectionsStateModel): boolean {
    return state.isCollectionNameValid;
  }

  @Selector()
  public static groups(state: CollectionsStateModel): GroupWeb[] {
    return state.groups;
  }

  @Selector()
  public static storageData(state: CollectionsStateModel): StorageData {
    return state.storageData;
  }

  @Selector()
  public static searchCollections(state: CollectionsStateModel): ConceptCollection[] {
    return state.searchCollections;
  }

  @Selector()
  public static isLoadingSearchCollections(state: CollectionsStateModel): boolean {
    return state.isLoadingSearchCollections;
  }

  @Selector()
  public static totalSearchResults(state: CollectionsStateModel): number {
    return state.totalSearchCollections;
  }

  @Selector()
  public static keepCollectionState(state: CollectionsStateModel): boolean {
    return state.keepCollectionState;
  }

  constructor(collectionsService: CollectionsService) {
    this.collectionsStateUseCases = new CollectionsStateUseCases(collectionsService);
  }

  @Action(GetMyCollections)
  public getMyCollections(context: StateContext<CollectionsStateModel>) {
    return this.collectionsStateUseCases.getMyCollections(context);
  }

  @Action(LoadAffectMyCollectionNames)
  public loadAffectedMyCollectionNames(
    context: StateContext<CollectionsStateModel>,
    action: LoadAffectMyCollectionNames
  ) {
    return this.collectionsStateUseCases.loadAffectedMyCollectionNames(context, action);
  }

  @Action(SetSelectedCollection)
  public setSelectedCollection(context: StateContext<CollectionsStateModel>, action: SetSelectedCollection) {
    return this.collectionsStateUseCases.saveSelectedCollection(context, action);
  }

  @Action(GetCollections)
  public getCollections(context: StateContext<CollectionsStateModel>, action: GetCollections) {
    return this.collectionsStateUseCases.getCollections(context, action);
  }

  @Action(IsCollectionNameValid)
  public isCollectionNameValid(context: StateContext<CollectionsStateModel>, action: IsCollectionNameValid) {
    return this.collectionsStateUseCases.isCollectionNameValid(context, action);
  }

  @Action(IsCollectionNameValidExcludeSelf)
  public isCollectionNameValidExcludeSelf(
    context: StateContext<CollectionsStateModel>,
    action: IsCollectionNameValidExcludeSelf
  ) {
    return this.collectionsStateUseCases.isCollectionNameValidExcludeSelf(context, action);
  }

  @Action(CreateCollection)
  public createCollection(context: StateContext<CollectionsStateModel>, action: CreateCollection) {
    return this.collectionsStateUseCases.createCollection(context, action);
  }

  @Action(AddToCollection)
  public addToCollection(context: StateContext<CollectionsStateModel>, action: AddToCollection) {
    return this.collectionsStateUseCases.addToCollection(context, action);
  }

  @Action(RemoveFromMyCollection)
  public removeFromMyCollection(context: StateContext<CollectionsStateModel>, action: RemoveFromMyCollection) {
    return this.collectionsStateUseCases.removeFromMyCollection(context, action);
  }

  @Action(RemoveFromCollection)
  public removeFromCollection(context: StateContext<CollectionsStateModel>, action: RemoveFromCollection) {
    return this.collectionsStateUseCases.removeFromCollection(context, action);
  }

  @Action(EditCollection)
  public editCollection(context: StateContext<CollectionsStateModel>, action: EditCollection) {
    return this.collectionsStateUseCases.editCollection(context, action);
  }

  @Action(DeleteCollection)
  public deleteCollection(context: StateContext<CollectionsStateModel>, action: DeleteCollection) {
    return this.collectionsStateUseCases.deleteCollection(context, action);
  }

  @Action(LoadGroups)
  public loadGroups(context: StateContext<CollectionsStateModel>) {
    return this.collectionsStateUseCases.loadGroups(context);
  }

  @Action(ExportCollection)
  public exportCollection(context: StateContext<CollectionsStateModel>, action: ExportCollection) {
    return this.collectionsStateUseCases.exportCollection(context, action);
  }

  @Action(ExportTerms)
  public exportTerms(context: StateContext<CollectionsStateModel>, action: ExportTerms) {
    return this.collectionsStateUseCases.exportTerms(context, action);
  }

  @Action(SetCollectionsStorageData)
  public setStorageData(context: StateContext<CollectionsStateModel>, { storageData }: SetCollectionsStorageData) {
    return this.collectionsStateUseCases.setCollectionsStorageData(context, storageData);
  }

  @Action(SetMyCollectionsData, { cancelUncompleted: true })
  public setMyCollectionsData(
    context: StateContext<CollectionsStateModel>,
    { searchConceptsByCollectionRequest }: SetMyCollectionsData
  ) {
    return this.collectionsStateUseCases.setMyCollectionsData(context, searchConceptsByCollectionRequest);
  }

  @Action(SetDataByCollection, { cancelUncompleted: true })
  public setDataByCollection(
    context: StateContext<CollectionsStateModel>,
    { searchConceptsByCollectionRequest }: SetDataByCollection
  ) {
    return this.collectionsStateUseCases.setDataByCollection(context, searchConceptsByCollectionRequest);
  }

  @Action(SetKeepCollectionState)
  public setKeepCollectionState(context: StateContext<CollectionsStateModel>, request: SetKeepCollectionState) {
    return this.collectionsStateUseCases.setKeepCollectionState(context, request);
  }
}
