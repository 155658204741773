import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChipsWithAdditionOption, CountrySliderOption } from '@apiax/web-commons';
import { Store } from '@ngxs/store';
import { cloneDeep, intersectionBy, isEmpty, isEqual } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { Jurisdiction, ProductRuleSet, RuleSetRef, TranslationLanguage } from '../../models';
import { RuleSetFilterOption } from '../../models/rule-set-filter-option';
import {
  ConceptJurisdictionsModalComponent,
  ConceptsJurisdictionsModalData
} from '../../presentation/shared-components/modals/concept-jurisdictions/concept-jurisdictions-modal.component';
import {
  ConceptRuleSetsModalComponent,
  ConceptRuleSetsModalData
} from '../../presentation/shared-components/modals/concept-rule-sets/concept-rule-sets-modal.component';
import { GoBackType, TaxonomyConceptState } from '../stores/taxonomy-concept';
import { VariableConcept } from '../../models/variable-concept';
import {
  ConceptSelectionVariablesComponent,
  ConceptSelectionVariablesModalData
} from '../../presentation/shared-components/modals/concept-selection-variables/concept-selection-variables.component';

export class ConceptUtils {
  public static readonly TERM_ID_PATTERN = /^[a-zA-Z_][\w]*$/;
  public static readonly JURISDICTION_GLOBAL = {
    alpha3: 'GLOBAL',
    name: 'Global'
  };
  public static readonly RULESET_ANY = {
    id: 'any',
    label: 'Any'
  };
  public static readonly FILTER_OPTION_RULESET_ANY: RuleSetFilterOption = {
    id: ConceptUtils.RULESET_ANY.id,
    name: ConceptUtils.RULESET_ANY.label,
    ruleSetFamilyId: ConceptUtils.RULESET_ANY.id
  };
  public static readonly RULESET_ANY_SELECTED_VALUE_OPTION = {
    id: ConceptUtils.RULESET_ANY.id + '#' + ConceptUtils.RULESET_ANY.id,
    label: ConceptUtils.RULESET_ANY.label + '#' + ConceptUtils.RULESET_ANY.label,
    removable: false
  } as ChipsWithAdditionOption;
  public static readonly RULESET_ANY_VALUE = {
    id: ConceptUtils.RULESET_ANY.id,
    label: ConceptUtils.RULESET_ANY.label
  };
  public static readonly RULESET_ANY_VALUE_OPTION = {
    id: ConceptUtils.RULESET_ANY.id,
    label: ConceptUtils.RULESET_ANY.label,
    removable: false
  } as ChipsWithAdditionOption;
  public static readonly JURISDICTION_GLOBAL_VALUE = {
    id: ConceptUtils.JURISDICTION_GLOBAL.alpha3,
    label: ConceptUtils.JURISDICTION_GLOBAL.name
  };
  public static readonly JURISDICTION_GLOBAL_VALUE_OPTION = {
    id: ConceptUtils.JURISDICTION_GLOBAL.alpha3,
    label: ConceptUtils.JURISDICTION_GLOBAL.name,
    removable: false
  } as ChipsWithAdditionOption;
  public static readonly PRIVATE_PRIVACY_VALUE = 'Private';
  public static readonly PUBLIC_PRIVACY_VALUE = 'Public';
  public static readonly PRIVACY_VALUES = [
    {
      id: this.PRIVATE_PRIVACY_VALUE,
      label: this.PRIVATE_PRIVACY_VALUE,
      image: null
    },
    {
      id: this.PUBLIC_PRIVACY_VALUE,
      label: this.PUBLIC_PRIVACY_VALUE,
      image: null
    }
  ];
  public static readonly ACTIVE_STATUS_VALUE = 'Active';
  public static readonly INACTIVE_STATUS_VALUE = 'Inactive';
  public static readonly DEPRECATED_STATUS_VALUE = 'Deprecated';
  public static readonly STATUS_VALUES = [
    {
      id: this.ACTIVE_STATUS_VALUE,
      label: this.ACTIVE_STATUS_VALUE,
      image: null
    },
    {
      id: this.INACTIVE_STATUS_VALUE,
      label: this.INACTIVE_STATUS_VALUE,
      image: null
    },
    {
      id: this.DEPRECATED_STATUS_VALUE,
      label: this.DEPRECATED_STATUS_VALUE,
      image: null
    }
  ];

  static splitConceptRuleSet(value: string): RuleSetRef {
    const split = value.split('#');

    return {
      ruleSetFamilyId: split[0],
      ruleSetId: split[1]
    };
  }

  static privacyTooltip(value: string): string {
    if (isEqual(value, ConceptUtils.PRIVATE_PRIVACY_VALUE)) {
      return 'This concept is only visible to users of the company it belongs.';
    } else {
      return 'This concept is visible to all users.';
    }
  }

  static statusTooltip(value: string): string {
    if (isEqual(value, 'Active')) {
      return 'This concept is searchable and will be resolved in any rule';
    } else if (isEqual(value, 'Deprecated')) {
      return (
        'This concept is not searchable while building rules, ' +
        'but still be resolved and readable if present in previously built rules.'
      );
    } else if (isEqual(value, 'Inactive')) {
      return (
        'This concept is not searchable while building rules, ' +
        'nor will be resolved or readable if present in previously built rules.'
      );
    }
  }

  static convertToCountrySliderOption(translationLanguages: TranslationLanguage[]): CountrySliderOption[] {
    return translationLanguages
      .map(t => ({
        id: t.code,
        label: t.name,
        flagCode: t.country
      }))
      .sort(t => (t.id === 'en' ? -1 : 0));
  }

  static convertToTranslationLanguage(countrySliderOption: CountrySliderOption[]): TranslationLanguage[] {
    return countrySliderOption.map(t => ({ code: t.id, name: t.label, country: t.flagCode }));
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private store: Store
  ) {
  }

  public getBackTypePath(): string {
    const goBackType = this.store.selectSnapshot(TaxonomyConceptState.goBack);
    if (!goBackType) {
      return 'search-terms';
    }
    switch (goBackType) {
      case GoBackType.SEARCH_TERMS:
        return 'search-terms';
      case GoBackType.MY_COLLECTIONS:
        return 'my-collections';
      case GoBackType.MY_COLLECTIONS_COMPARE_VIEW:
        return 'my-collections/compare-view';
      case GoBackType.SEARCH_ON_RULES:
        return 'search-on-rules';
      case GoBackType.LATEST_CHANGES:
        return 'latest-changes';
      case GoBackType.EXPORT_DATA:
        return 'export-data';
      case GoBackType.CONTROL_PANEL:
        return 'control-panel';
    }
  }

  public navigateToConceptTermEditor(conceptId: string) {
    this.router.navigate(['concept', 'term-editor'], {
      queryParams: { conceptId: conceptId }
    });
  }

  public navigateToConceptTermEditorTranslate(conceptId: string, language: string) {
    this.router.navigate(['concept', 'term-editor-translation'], {
      queryParams: { conceptId: conceptId, language: language }
    });
  }

  public replaceUrlToConceptTermEditorTranslate(conceptId: string, language: string) {
    this.location.replaceState('/concept/term-editor-translation?conceptId=' + conceptId + '&language=' + language);
  }

  public mapRuleSets(availableProductRuleSets: Map<string, ProductRuleSet[]>, ruleSets: RuleSetRef[]) {
    const selectedRuleSets = [];
    const values = [];
    const productRuleSets: ProductRuleSet[] = [];
    const availableData: Map<string, ProductRuleSet[]> = cloneDeep(availableProductRuleSets);

    for (const [_key, value] of availableData) {
      value.forEach(val => {
        productRuleSets.push(val);
      });
    }

    ruleSets?.forEach(obj => {
      const ruleSetFamilyLabel = !isEqual(obj.ruleSetFamilyId, ConceptUtils.RULESET_ANY.id)
        ? productRuleSets.find(item => isEqual(item.ruleSetFamily.id, obj.ruleSetFamilyId))?.ruleSetFamily.label
        : ConceptUtils.RULESET_ANY.label;
      const ruleSetLabel = !isEqual(obj.ruleSetId, ConceptUtils.RULESET_ANY.id)
        ? productRuleSets.find(item => isEqual(item.ruleSet.id, obj.ruleSetId))?.ruleSet.label
        : ConceptUtils.RULESET_ANY.label;

      selectedRuleSets.push({
        id: obj.ruleSetFamilyId + '#' + obj.ruleSetId,
        label: ruleSetFamilyLabel + '#' + ruleSetLabel
      });

      if (!values.find(val => isEqual(val.id, obj.ruleSetFamilyId))) {
        values.push({
          id: obj.ruleSetFamilyId,
          label: ruleSetFamilyLabel,
          removable: !isEqual(obj.ruleSetFamilyId, ConceptUtils.RULESET_ANY.id)
        });
      }
    });

    return { selections: selectedRuleSets, newValues: values };
  }

  public mapJurisdictions(availableProductRuleSets: Map<string, ProductRuleSet[]>, jurisdictions: string[]) {
    const productJurisdictions: Jurisdiction[] = [];

    for (const [_key, value] of availableProductRuleSets) {
      value.forEach(val => {
        val.jurisdictions.forEach(jur => {
          productJurisdictions.push(jur);
        });
      });
    }

    return jurisdictions.map(obj => {
      const jurisdictionName = !isEqual(obj, ConceptUtils.JURISDICTION_GLOBAL.alpha3)
        ? productJurisdictions.find(item => isEqual(item.alpha3, obj)).name
        : ConceptUtils.JURISDICTION_GLOBAL.alpha3;

      return {
        id: obj,
        label: jurisdictionName,
        removable: !isEqual(obj, ConceptUtils.JURISDICTION_GLOBAL_VALUE.id)
      };
    });
  }

  public filterProductRuleSets(
    availableData: Map<string, ProductRuleSet[]>,
    owner: any,
    ruleSets: any,
    jurisdictions: any,
    selectedRuleSets: any
  ) {
    let dataMap: Map<string, ProductRuleSet[]> = cloneDeep(availableData);

    if (owner) {
      dataMap = new Map(
        [...dataMap?.entries()].filter(([key, _value]) => {
          return isEqual(key, owner.id);
        })
      );
    }

    const ruleSetsSelections = selectedRuleSets
      ? selectedRuleSets.filter(obj => !isEqual(obj.id, ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION.id))
      : [];

    const isGlobal =
      !isEmpty(jurisdictions) && jurisdictions.find(obj => isEqual(obj.id, ConceptUtils.JURISDICTION_GLOBAL.alpha3));

    if ((isEmpty(ruleSetsSelections) && isGlobal) || !owner) {
      // retrieves all ruleSets and jurisdictions of the selected owner
    } else if (isEmpty(ruleSetsSelections) && !isGlobal) {
      // retrieves all jurisdictions of the selected owner and the ruleSets that have the selected jurisdictions

      const filteredMap: Map<string, ProductRuleSet[]> = new Map();
      const common: Jurisdiction[] = [];

      for (const [key, value] of dataMap) {
        let filteredValue = value.filter(obj => {
          obj.jurisdictions.forEach(jur => {
            common.push(jur);
          });

          return jurisdictions.every(jur => obj.jurisdictions.map(j => j.alpha3).includes(jur.id));
        });

        filteredValue = filteredValue.map(v => {
          return { ...v, jurisdictions: common };
        });

        // must have one value for the available jurisdictions - UN-2364
        if (filteredValue.length === 0 && common.length > 0) {
          filteredValue = [
            {
              ruleSet: undefined,
              ruleSetFamily: undefined,
              jurisdictions: common
            }
          ];
        }

        filteredMap.set(key, filteredValue);
      }

      dataMap = filteredMap;
    } else if (!isEmpty(ruleSetsSelections)) {
      // retrieves all selected ruleSets and their related jurisdictions

      const filteredMap: Map<string, ProductRuleSet[]> = new Map();
      const selectedRuleSetRefs: RuleSetRef[] = [];
      let common: Jurisdiction[] = [];

      ruleSetsSelections.forEach(a => {
        selectedRuleSetRefs.push(ConceptUtils.splitConceptRuleSet(a.id));
      });

      for (const [_key, value] of dataMap) {
        value.forEach(obj => {
          if (
            isEqual(obj.ruleSetFamily.id, selectedRuleSetRefs[0].ruleSetFamilyId) &&
            isEqual(obj.ruleSet.id, selectedRuleSetRefs[0].ruleSetId)
          ) {
            common = obj.jurisdictions;
          }
        });
      }

      for (const [key, value] of dataMap) {
        let filteredValue = value.filter(obj => {
          if (
            selectedRuleSetRefs.length > 1 &&
            selectedRuleSetRefs.some(a => a.ruleSetFamilyId === obj.ruleSetFamily.id && a.ruleSetId === obj.ruleSet.id)
          ) {
            common = intersectionBy(common, obj.jurisdictions, 'alpha3');
          }

          return isGlobal || jurisdictions.every(jur => obj.jurisdictions.map(j => j.alpha3).includes(jur.id));
        });

        filteredValue = filteredValue.map(v => {
          return { ...v, jurisdictions: common };
        });

        filteredMap.set(key, filteredValue);
      }

      dataMap = filteredMap;
    }

    return this.buildProductRuleSets(dataMap);
  }

  public buildProductRuleSets(dataMap: Map<string, ProductRuleSet[]>): ProductRuleSet[] {
    const data: ProductRuleSet[] = [];

    dataMap.forEach((value, _key) => {
      value.forEach(obj => {
        const mainIndex = data.findIndex(
          prs => isEqual(prs.ruleSetFamily, obj.ruleSetFamily) && isEqual(prs.ruleSet, obj.ruleSet)
        );

        if (mainIndex === -1) {
          const newValue = {
            ruleSetFamily: obj.ruleSetFamily,
            ruleSet: obj.ruleSet,
            jurisdictions: obj.jurisdictions
          };

          data.push(newValue);
        } else {
          obj.jurisdictions.forEach(ObjJur => {
            const jurisdictionIndex = data[mainIndex].jurisdictions.findIndex(jur => isEqual(jur, ObjJur));

            if (jurisdictionIndex === -1) {
              data[mainIndex].jurisdictions.push(ObjJur);
            }
          });
        }
      });
    });

    return data;
  }

  public openRuleSetsModal(data: ConceptRuleSetsModalData, selectedRuleSets: any, productRuleSets: any) {
    const dialogRef = this.dialog.open(ConceptRuleSetsModalComponent, {
      data: data,
      ...ConceptRuleSetsModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap(response => {
        if (response) {
          const values: ChipsWithAdditionOption[] = [];

          if (response) {
            selectedRuleSets = response.map(obj => {
              const ruleSetRef: RuleSetRef = ConceptUtils.splitConceptRuleSet(obj);
              const ruleSetFamilyLabel = !isEqual(ruleSetRef.ruleSetFamilyId, ConceptUtils.RULESET_ANY.id)
                ? productRuleSets.find(item => isEqual(item.ruleSetFamily.id, ruleSetRef.ruleSetFamilyId)).ruleSetFamily
                  .label
                : ConceptUtils.RULESET_ANY.label;
              const ruleSetLabel = !isEqual(ruleSetRef.ruleSetId, ConceptUtils.RULESET_ANY.id)
                ? productRuleSets.find(item => isEqual(item.ruleSet.id, ruleSetRef.ruleSetId)).ruleSet.label
                : ConceptUtils.RULESET_ANY.label;

              return {
                id: obj,
                label: ruleSetFamilyLabel + '#' + ruleSetLabel
              } as ChipsWithAdditionOption;
            });

            selectedRuleSets.forEach(item => {
              const ruleSetRefIds: RuleSetRef = ConceptUtils.splitConceptRuleSet(item.id);
              if (!values.find(obj => isEqual(obj.id, ruleSetRefIds.ruleSetFamilyId))) {
                const ruleSetRefLabels: RuleSetRef = ConceptUtils.splitConceptRuleSet(item.label);
                values.push({
                  id: ruleSetRefIds.ruleSetFamilyId,
                  label: ruleSetRefLabels.ruleSetFamilyId,
                  removable: !isEqual(ruleSetRefIds.ruleSetFamilyId, ConceptUtils.RULESET_ANY.id)
                });
              }
            });
          }

          return of({ selections: selectedRuleSets, newValues: values });
        } else {
          return of(response);
        }
      })
    );
  }

  public ruleSetsAdditionalInfo = (selectedRuleSets, value) => {
    if (!isEqual(value.id, ConceptUtils.RULESET_ANY.id)) {
      const count = selectedRuleSets.filter(obj => isEqual(obj.id.split('#')[0], value.id)).length;

      return `(${count} Rule Set(s))`;
    }
  };

  public ruleSetsAdditionalInfoTooltip = (selectedRuleSets, value) => {
    if (!isEqual(value.id, ConceptUtils.RULESET_ANY.id)) {
      const ruleSets = selectedRuleSets.filter(obj => isEqual(obj.id.split('#')[0], value.id));

      return ruleSets.map(item => item.label.split('#')[1]).join(';\n');
    }
  };

  public openJurisdictionsModal(data: ConceptsJurisdictionsModalData) {
    const dialogRef = this.dialog.open(ConceptJurisdictionsModalComponent, {
      data: data,
      ...ConceptJurisdictionsModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap(response => {
        if (response) {
          const values = response.map(obj => {
            return {
              id: obj.alpha3,
              label: obj.name,
              removable: !isEqual(obj.alpha3, ConceptUtils.JURISDICTION_GLOBAL_VALUE.id)
            } as ChipsWithAdditionOption;
          });

          return of(values);
        } else {
          return of(response);
        }
      })
    );
  }

  public openSelectionVariablesModal(data: ConceptSelectionVariablesModalData): Observable<VariableConcept> {
    const dialogRef = this.dialog.open(ConceptSelectionVariablesComponent, {
      data: data,
      ...ConceptSelectionVariablesComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed().pipe(
      first(),
      switchMap(response => {
        return of(response);
      })
    );
  }
}
