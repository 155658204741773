import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ConceptLlmApiService } from '../../../../generated/v3';
import {
  ClearConceptLLMState,
  GetConceptLLM,
  LoadAvailableLLMOptions,
  UpdateConceptLLMLocally,
  UpsertConceptLLM
} from './concept-llm.action';
import { ConceptLLM } from '../../../models/concept-llm';
import { ConceptLlmUseCases } from './usecases/concept-llm-usecases';
import { LLMProviderOption } from '../../../models/llm-provider-option';

export interface ConceptLLMStateModel {
  isLoadingConceptLLM: boolean;
  availableLLMOptions: LLMProviderOption[];
  conceptLLMList: ConceptLLM[];
  isLoadingAvailableLLMOptions: boolean;
  isDirty: boolean;
}

export const DEFAULT_STATE: ConceptLLMStateModel = {
  isLoadingConceptLLM: false,
  availableLLMOptions: [],
  conceptLLMList: [],
  isLoadingAvailableLLMOptions: false,
  isDirty: false
};

@State<ConceptLLMStateModel>({
  name: 'conceptLLM',
  defaults: DEFAULT_STATE
})
@Injectable()
export class ConceptLlmState {
  private conceptLLMUseCases: ConceptLlmUseCases;

  @Selector()
  public static isLoadingConceptLLM(state: ConceptLLMStateModel): boolean {
    return state.isLoadingConceptLLM;
  }

  @Selector()
  public static isLoadingAvailableLLMOptions(state: ConceptLLMStateModel): boolean {
    return state.isLoadingAvailableLLMOptions;
  }

  @Selector()
  public static availableLLMOptions(state: ConceptLLMStateModel): LLMProviderOption[] {
    return state.availableLLMOptions;
  }

  @Selector()
  public static conceptLLMList(state: ConceptLLMStateModel): ConceptLLM[] {
    return state.conceptLLMList;
  }

  @Selector()
  public static isConceptLLMDirty(state: ConceptLLMStateModel): boolean {
    return state.isDirty;
  }

  constructor(conceptLlmApiService: ConceptLlmApiService) {
    this.conceptLLMUseCases = new ConceptLlmUseCases(conceptLlmApiService);
  }

  @Action(GetConceptLLM)
  public getConceptLLM(context: StateContext<ConceptLLMStateModel>, action: GetConceptLLM) {
    return this.conceptLLMUseCases.getConceptLLM(context, action);
  }

  @Action(UpsertConceptLLM)
  public upsertConceptLLM(context: StateContext<ConceptLLMStateModel>, action: UpsertConceptLLM) {
    return this.conceptLLMUseCases.upsertConceptLLM(context, action);
  }

  @Action(LoadAvailableLLMOptions)
  public loadAvailableLLMOptions(context: StateContext<ConceptLLMStateModel>, action: LoadAvailableLLMOptions) {
    return this.conceptLLMUseCases.loadAvailableLLMOptions(context, action);
  }

  @Action(UpdateConceptLLMLocally)
  public updateConceptLLMLocally(context: StateContext<ConceptLLMStateModel>, action: UpdateConceptLLMLocally) {
    return this.conceptLLMUseCases.updateConceptLLMLocally(context, action);
  }

  @Action(ClearConceptLLMState)
  public clearConceptLLMState(context: StateContext<ConceptLLMStateModel>) {
    return this.conceptLLMUseCases.clear(context);
  }
}
