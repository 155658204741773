<ng-template [ngIf]="(isLoading$ | async) === false || isInitialized">
  <div class="my-collections-wrapper">
    <div class="my-collections-tree-wrapper">
      <apx-collapsable-tree
        (selectionChanged)="collectionSelected($event)"
        [entries]="collectionEntries"
        #collectionsMenu
      ></apx-collapsable-tree>
    </div>
    <div class="table-wrapper">
      <ng-template [ngIf]="selectedEntry === 'collections'" [ngIfElse]="collection">
        <app-my-collection-content></app-my-collection-content>
      </ng-template>
      <ng-template #collection>
        <div *ngIf="emptyCollectionTypeMessage" class="no-terms-message-container">
          {{ emptyCollectionTypeMessage }}
        </div>
        <ng-template [ngIf]="isCompareView" [ngIfElse]="collections">
          <app-collections-compare-view></app-collections-compare-view>
        </ng-template>
        <ng-template #collections>
          <app-collection-content
            *ngIf="!emptyCollectionTypeMessage"
            [collectionId]="collectionId"
            (collectionChanged)="reloadCollections()"
            (collectionDeleted)="reloadCollectionsForDelete()"
            [selectedCollectionType]="selectedCollectionType"
          ></app-collection-content>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>
