import { StateContext } from '@ngxs/store';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CompareViewService } from '../../../../../generated/v3';
import { FilterOptionMapper } from '../../../mappers/filter-option-mapper';
import { isEmpty } from 'lodash-es';
import { FilterOption } from '../../../../models';
import { RuleSetFilterOption } from '../../../../models/rule-set-filter-option';
import { CollectionsCompareViewFiltersStateModel } from '../collections-compare-view-filters.state';
import {
  LoadCollectionsCompareViewFilterOptions,
  ResetCollectionsCompareViewFiltersData
} from '../collections-compare-view-filters.action';

export class CollectionsCompareViewFiltersStateUseCases {
  constructor(private compareViewApiService: CompareViewService) {}

  public loadCollectionsCompareViewFilterOptions(
    context: StateContext<CollectionsCompareViewFiltersStateModel>,
    action: LoadCollectionsCompareViewFilterOptions
  ) {
    return combineLatest([
      isEmpty(context.getState().ruleSetFamilyOptions)
        ? this.loadRuleSetOptions(context, action.collectionId)
        : of(context.getState().ruleSetFamilyOptions),
      isEmpty(context.getState().ownerOptions)
        ? this.loadOwnerOptions(context, action.collectionId)
        : of(context.getState().ownerOptions),
      isEmpty(context.getState().jurisdictionOptions)
        ? this.loadJurisdictionOptions(context, action.collectionId)
        : of(context.getState().jurisdictionOptions),
      isEmpty(context.getState().translationOptions)
        ? this.loadTranslationOptions(context, action.collectionId)
        : of(context.getState().translationOptions),
      isEmpty(context.getState().categoryOptions)
        ? this.loadCategoriesOptions(context, action.collectionId)
        : of(context.getState().categoryOptions)
    ]).pipe(
      tap(_response => {
        context.patchState({
          areFiltersLoaded: true
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public resetData(
    context: StateContext<CollectionsCompareViewFiltersStateModel>,
    action: ResetCollectionsCompareViewFiltersData
  ) {
    context.patchState({
      ruleSetFamilyOptions: [],
      ruleSetOptions: [],
      ownerOptions: [],
      jurisdictionOptions: [],
      translationOptions: [],
      categoryOptions: [],
      areFiltersLoaded: false
    });
  }

  private loadRuleSetOptions(context: StateContext<CollectionsCompareViewFiltersStateModel>, collectionId: string) {
    return this.compareViewApiService.accessibleRuleSetFamiliesFromCollection({ collectionId: collectionId }).pipe(
      tap(response => {
        const ruleSets = response.ruleSetFamilies.flatMap(r => r.ruleSets);
        const ruleSetFamilyOptions: FilterOption[] = response.ruleSetFamilies
          .map(FilterOptionMapper.mapRuleSetFamilyToFilterOption)
          .sort((a, b) => this.anyFirstComparator(a.name, b.name));
        const ruleSetOptions: RuleSetFilterOption[] = ruleSets
          .map(FilterOptionMapper.mapRuleSetToFilterOption)
          .sort((a, b) => this.anyFirstComparator(a.name, b.name));
        context.patchState({
          ruleSetFamilyOptions: ruleSetFamilyOptions,
          ruleSetOptions: ruleSetOptions
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadOwnerOptions(context: StateContext<CollectionsCompareViewFiltersStateModel>, collectionId: string) {
    return this.compareViewApiService.accessibleOwnersFromCollection({ collectionId: collectionId }).pipe(
      tap(response => {
        context.patchState({
          ownerOptions: response.options.map(option => FilterOptionMapper.mapToFilterOption(option))
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadJurisdictionOptions(
    context: StateContext<CollectionsCompareViewFiltersStateModel>,
    collectionId: string
  ) {
    return this.compareViewApiService.accessibleJurisdictionsFromCollection({ collectionId: collectionId }).pipe(
      tap(response => {
        context.patchState({
          jurisdictionOptions: response.options.map(option => FilterOptionMapper.mapToFilterOption(option))
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadTranslationOptions(context: StateContext<CollectionsCompareViewFiltersStateModel>, collectionId: string) {
    return this.compareViewApiService.accessibleTranslationsFromCollection({ collectionId: collectionId }).pipe(
      tap(response => {
        context.patchState({
          translationOptions: response.options.map(option => FilterOptionMapper.mapToFilterOption(option))
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private loadCategoriesOptions(context: StateContext<CollectionsCompareViewFiltersStateModel>, collectionId: string) {
    return this.compareViewApiService.accessibleCategoriesFromCollection({ collectionId: collectionId }).pipe(
      tap(response => {
        context.patchState({
          categoryOptions: response.options.map(option => FilterOptionMapper.mapToFilterOption(option))
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  private anyFirstComparator(name1: string, name2: string): number {
    if (name1 === 'Any') {
      return -1;
    }
    if (name2 === 'Any') {
      return 1;
    }
    return name1.localeCompare(name2);
  }
}
