import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ChipsWithAdditionOption,
  NotificationService,
  TemplateModalComponent,
  TemplateModalData,
  TemplatePickerOption
} from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isEmpty, isEqual } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Activity, AuthorizationService, PhotoService } from 'shared/core';
import { PhotoDAO } from '../../../../domain/daos/photo.dao';
import { TaxonomyConceptMapper } from '../../../../domain/mappers/taxonomy-concept-mapper';
import { TaxonomyUtilsService } from '../../../../domain/services/taxonomy-utils.service';
import {
  LoadAccessibleOwners,
  LoadProductRuleSets
} from '../../../../domain/stores/organizations/organizations.action';
import { OrganizationsState } from '../../../../domain/stores/organizations/organizations.state';
import { ConceptUtils } from '../../../../domain/utils/concept.utils';
import { ProductRuleSet, RuleSetRef } from '../../../../models';
import { ConceptsJurisdictionsModalData } from '../concept-jurisdictions/concept-jurisdictions-modal.component';
import { ConceptRuleSetsModalData } from '../concept-rule-sets/concept-rule-sets-modal.component';

export interface CloneConceptModalData {
  id: string;
  termId: string;
  owner: string;
  ruleSets: RuleSetRef[];
  jurisdictions: string[];
}

enum CloneConceptForm {
  Id = 'id',
  TermId = 'termId',
  Owner = 'owner',
  RuleSets = 'RuleSets',
  Jurisdictions = 'Jurisdictions'
}

@UntilDestroy()
@Component({
  selector: 'app-clone-concept-modal',
  templateUrl: './clone-concept-modal.component.html',
  styleUrls: ['./clone-concept-modal.component.scss']
})
export class CloneConceptModalComponent implements OnInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    ...TemplateModalComponent.DEFAULT_CONFIG,
    width: '645px',
    minHeight: 425
  };

  public templateData: TemplateModalData;

  public currentUserPermissions = new Map<Activity, boolean>();

  public isLoading$: Observable<boolean>;

  public isLoadingOwners$: Observable<boolean>;
  public isLoadingProductRuleSets$: Observable<boolean>;

  public form = new UntypedFormGroup({});
  public formNames = CloneConceptForm;

  public availableOwners$: Observable<TemplatePickerOption[]>;
  private availableOwners: TemplatePickerOption[];

  private availableProductRuleSets: Map<string, ProductRuleSet[]>;
  private productRuleSets: ProductRuleSet[];

  private selectedRuleSets: ChipsWithAdditionOption[] = [];

  private photoDAO: PhotoDAO;
  private conceptUtils: ConceptUtils;

  constructor(
    photoService: PhotoService,
    router: Router,
    location: Location,
    dialog: MatDialog,
    public dialogRef: MatDialogRef<CloneConceptModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CloneConceptModalData,
    private authorizationService: AuthorizationService,
    private store: Store,
    private notificationService: NotificationService,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.photoDAO = new PhotoDAO(photoService);

    this.conceptUtils = new ConceptUtils(router, dialog, location, store);

    this.getCurrentUserPermissions();

    this.isLoadingOwners$ = this.store.select(OrganizationsState.isLoadingAccessibleOwners);
    this.isLoadingProductRuleSets$ = this.store.select(OrganizationsState.isLoadingProductRuleSets);

    this.isLoading$ = combineLatest([this.isLoadingOwners$, this.isLoadingProductRuleSets$]).pipe(
      untilDestroyed(this),
      map(([isLoadingOwners, isLoadingProductRuleSets]) => {
        return isLoadingOwners || isLoadingProductRuleSets;
      })
    );

    this.templateData = {
      title: 'Change one of the following fields',
      hideClose: false,
      disableClose: false
    };
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  private initForm() {
    this.form = new UntypedFormGroup({});
    this.form.addControl(CloneConceptForm.Id, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(
      CloneConceptForm.TermId,
      new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ConceptUtils.TERM_ID_PATTERN)])
    );
    this.form.addControl(CloneConceptForm.Owner, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(CloneConceptForm.RuleSets, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(CloneConceptForm.Jurisdictions, new UntypedFormControl(undefined, Validators.required));
  }

  private dispatchStoreActions() {
    this.loadOwners();
    this.loadProductRuleSets();
  }

  private loadOwners() {
    this.store
      .dispatch(new LoadAccessibleOwners())
      .pipe(first())
      .subscribe(() => {
        const owners = this.store.selectSnapshot(OrganizationsState.accessibleOwners);
        this.availableOwners = owners?.map(obj => {
          return {
            id: obj.id,
            label: obj.name,
            image: this.photoDAO.getPhotoUrl(obj.photoId)
          };
        });

        this.setOwnerControl();
      });
  }

  private loadProductRuleSets() {
    this.store
      .dispatch(new LoadProductRuleSets())
      .pipe(first())
      .subscribe(() => {
        this.availableProductRuleSets = this.store.selectSnapshot(OrganizationsState.productRuleSets);

        this.setRuleSetsControl();
        this.setJurisdictionsControl();

        this.filterProductRuleSets();
      });
  }

  private setOwnerControl() {
    if (this.currentUserPermissions.get(Activity.AllCompaniesView)) {
      const owner = this.availableOwners.find(obj => obj.id === this.data.owner);

      this.form.get(CloneConceptForm.Owner).setValue(owner, { emitEvent: false });
      this.form.get(CloneConceptForm.Owner).enable({ emitEvent: false });
    } else {
      const owner = this.availableOwners.find(
        obj => obj.id === this.taxonomyUtilsService.getCurrentUser().organizationId
      );

      this.form.get(CloneConceptForm.Owner).setValue(owner, { emitEvent: false });
      this.form.get(CloneConceptForm.Owner).disable({ emitEvent: false });
    }

    this.availableOwners$ = of(this.availableOwners);
  }

  private setRuleSetsControl() {
    const ruleSets = this.conceptUtils.mapRuleSets(this.availableProductRuleSets, this.data.ruleSets);

    this.selectedRuleSets = ruleSets.selections;

    this.form.get(CloneConceptForm.RuleSets).setValue(ruleSets.newValues, { emitEvent: false });
  }

  private setJurisdictionsControl() {
    const jurisdictions = this.conceptUtils.mapJurisdictions(this.availableProductRuleSets, this.data.jurisdictions);

    this.form.get(CloneConceptForm.Jurisdictions).setValue(jurisdictions, { emitEvent: false });
  }

  private subscribeFormControlsValueChanges() {
    this.onOwnerValueChange();
    this.onRuleSetsValueChange();
    this.onJurisdictionsValueChange();
  }

  private onOwnerValueChange() {
    this.form
      .get(CloneConceptForm.Owner)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        if (typeof value !== 'string') {
          let notifyUser = false;

          const ruleSets = this.form.get(CloneConceptForm.RuleSets).value;

          if (!(ruleSets.length === 1 && isEqual(ruleSets[0].id, ConceptUtils.RULESET_ANY_VALUE_OPTION.id))) {
            this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];
            this.form
              .get(CloneConceptForm.RuleSets)
              .setValue([ConceptUtils.RULESET_ANY_VALUE_OPTION], { emitEvent: false });
            notifyUser = true;
          }

          const jurisdictions = this.form.get(CloneConceptForm.Jurisdictions).value;

          if (
            !(
              jurisdictions.length === 1 &&
              isEqual(jurisdictions[0].id, ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION.id)
            )
          ) {
            this.form
              .get(CloneConceptForm.Jurisdictions)
              .setValue([ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION], { emitEvent: false });
            notifyUser = true;
          }

          if (notifyUser) {
            this.notificationService.showSimpleAlert(
              'Rule Sets / Jurisdictions were cleared because of owner change.',
              'info'
            );
          }

          this.filterProductRuleSets();
        }
      });
  }

  private onRuleSetsValueChange() {
    this.form
      .get(CloneConceptForm.RuleSets)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        if (isEmpty(values)) {
          this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];
          this.form.get(CloneConceptForm.RuleSets).setValue([ConceptUtils.RULESET_ANY_VALUE], { emitEvent: false });
        } else {
          this.selectedRuleSets = this.selectedRuleSets.filter(obj => {
            return values.some(v => obj.id.startsWith(v.id + '#'));
          });

          this.form.get(CloneConceptForm.RuleSets).setValue(values, { emitEvent: false });
        }

        this.filterProductRuleSets();

        this.form.markAsDirty();
      });
  }

  private onJurisdictionsValueChange() {
    this.form
      .get(CloneConceptForm.Jurisdictions)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        if (isEmpty(values)) {
          this.form.get(CloneConceptForm.Jurisdictions).setValue(
            [
              {
                id: ConceptUtils.JURISDICTION_GLOBAL_VALUE.id,
                label: ConceptUtils.JURISDICTION_GLOBAL_VALUE.label,
                removable: false
              }
            ],
            { emitEvent: false }
          );
        } else {
          this.form.get(CloneConceptForm.Jurisdictions).setValue(values, { emitEvent: false });
        }

        this.filterProductRuleSets();

        this.form.markAsDirty();
      });
  }

  private filterProductRuleSets() {
    const owner = this.form.get(CloneConceptForm.Owner).value;
    const ruleSets = this.form.get(CloneConceptForm.RuleSets).value;
    const jurisdictions = this.form.get(CloneConceptForm.Jurisdictions).value;

    this.productRuleSets = this.conceptUtils.filterProductRuleSets(
      this.availableProductRuleSets,
      owner,
      ruleSets,
      jurisdictions,
      this.selectedRuleSets
    );
  }

  ngOnInit() {
    this.initForm();
    this.form.get(CloneConceptForm.Id).setValue(this.data.id, { emitEvent: false });
    this.form.get(CloneConceptForm.TermId).setValue(this.data.termId, { emitEvent: false });
    this.dispatchStoreActions();
    this.subscribeFormControlsValueChanges();
  }

  public openRuleSetsModal() {
    const data: ConceptRuleSetsModalData = {
      productRuleSets: this.productRuleSets,
      selections: this.selectedRuleSets ? this.selectedRuleSets.map(obj => obj.id) : []
    };

    this.conceptUtils
      .openRuleSetsModal(data, this.selectedRuleSets, this.productRuleSets)
      .pipe(first())
      .subscribe(obj => {
        if (obj) {
          this.selectedRuleSets = obj.selections;
          this.form.get(CloneConceptForm.RuleSets).setValue(obj.newValues);
        }
      });
  }

  public ruleSetsAdditionalInfo = value => {
    return this.conceptUtils.ruleSetsAdditionalInfo(this.selectedRuleSets, value);
  };

  public ruleSetsAdditionalInfoTooltip = value => {
    return this.conceptUtils.ruleSetsAdditionalInfoTooltip(this.selectedRuleSets, value);
  };

  public openJurisdictionsModal() {
    const data: ConceptsJurisdictionsModalData = {
      productRuleSets: this.productRuleSets,
      selections: this.form.get(CloneConceptForm.Jurisdictions).value
        ? this.form.get(CloneConceptForm.Jurisdictions).value.map(obj => obj.id)
        : []
    };

    this.conceptUtils
      .openJurisdictionsModal(data)
      .pipe(first())
      .subscribe(values => {
        if (values) {
          this.form.get(CloneConceptForm.Jurisdictions).setValue(values);
        }
      });
  }

  public shouldBeDisabled() {
    if (this.taxonomyUtilsService.hasAllCompaniesViewAccess()) {
      return !this.form.valid || !this.form.dirty;
    } else {
      const userOrgId = this.taxonomyUtilsService.getCurrentUser().organizationId;

      if (isEqual(userOrgId, this.data.owner)) {
        return !this.form.valid || !this.form.dirty;
      } else {
        return !this.form.valid;
      }
    }
  }

  public onConfirmation() {
    this.dialogRef.close({
      id: this.form.get(CloneConceptForm.Id).value,
      termId: this.form.get(CloneConceptForm.TermId).value,
      owner: this.form.get(CloneConceptForm.Owner).value?.id,
      ruleSetFamilyList: TaxonomyConceptMapper.mapToGeneratedConceptRuleSets(this.selectedRuleSets),
      jurisdictions: TaxonomyConceptMapper.mapToGeneratedConceptJurisdictions(
        this.form.get(CloneConceptForm.Jurisdictions).value
      )
    });
  }

  public onCancel() {
    this.dialogRef.close(false);
  }
}
