<apx-template-modal [data]="templateData" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <mat-dialog-content>
        <apx-template-picker
          [control]="form.get(formNames.Language)"
          [placeholder]="'Select language'"
          [label]="'Select language'"
          [removeSelection]="false"
          [isJurisdiction]="false"
          [availableOptions$]="availableTranslationLanguages$"
          [optionTemplate]="languageOptionTemplate"
        ></apx-template-picker>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="btn-action primary-action" (click)="onConfirmation()" [disabled]="!(form.valid && form.dirty)">
          Add language
        </button>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>

<ng-template #languageOptionTemplate let-option>
  <div class="option-wrapper">
    <span>{{ option.label }}</span>
  </div>
</ng-template>
