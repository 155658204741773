<apx-template-modal [data]="templateData" [template]="modalTemplate">
  <ng-template #modalTemplate>
    <apx-loading-spinner [isLoading]="isLoading$">
      <mat-dialog-content class="deployed-usage-modal-wrapper">
        <span [innerHTML]="data.message"></span>
      </mat-dialog-content>
      <mat-dialog-actions class="deployed-usage-modal-actions">
        <button class="btn-action secondary-action" (click)="onCancel()">Cancel</button>
        <button class="btn-action primary-action" (click)="onConfirmation()">
          {{ data.actionLabel }}
        </button>
      </mat-dialog-actions>
    </apx-loading-spinner>
  </ng-template>
</apx-template-modal>
