import { StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  GetAccessibleOwnersResponse,
  GetAccessibleProductsRuleSetsResponse,
  OrganizationsApiService
} from '../../../../../generated/v3';
import { ProductRuleSet } from '../../../../models/product-rule-set';
import { OrganizationsMapper } from '../../../mappers/organizations-mapper';
import { DEFAULT_STATE, OrganizationsStateModel } from '../organizations.state';

export class OrganizationsStateUseCases {
  constructor(private organizationsApiService: OrganizationsApiService) {}

  public loadAccessibleOwners(context: StateContext<OrganizationsStateModel>) {
    context.patchState({
      isLoadingAccessibleOwners: true
    });
    return this.organizationsApiService.accessibleOwners().pipe(
      tap((response: GetAccessibleOwnersResponse) => {
        const data = response.accessibleOwners.map(obj => OrganizationsMapper.mapToOwner(obj));
        context.patchState({
          accessibleOwners: data,
          isLoadingAccessibleOwners: false
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadProductRuleSets(context: StateContext<OrganizationsStateModel>) {
    context.patchState({
      isLoadingProductRuleSets: true
    });
    return this.organizationsApiService.accessibleProductsRuleSets().pipe(
      tap((response: GetAccessibleProductsRuleSetsResponse) => {
        const dataMap = new Map<string, ProductRuleSet[]>();

        Object.keys(response.accessibleRuleSets).forEach(key => {
          const values = response.accessibleRuleSets[key];
          const data = values.map(obj =>
            OrganizationsMapper.mapToProductRuleSet(obj, response.ruleSetFamilyLabels, response.jurisdictionLabels)
          );

          dataMap.set(key, data);
        });

        context.patchState({
          productRuleSets: cloneDeep(dataMap),
          isLoadingProductRuleSets: false
        });
      })
    );
  }

  public clearOrganizationState(context: StateContext<OrganizationsStateModel>) {
    context.patchState(DEFAULT_STATE);
  }
}
