import {
  ConceptLlmApiService,
  GetAvailableLLMOptionsResponse,
  GetConceptLLMResponse
} from '../../../../../generated/v3';
import { StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LlmOptionsMapper } from '../../../mappers/llm-options-mapper';
import {
  GetConceptLLM,
  LoadAvailableLLMOptions,
  UpdateConceptLLMLocally,
  UpsertConceptLLM
} from '../concept-llm.action';
import { ConceptLLMStateModel } from '../concept-llm.state';
import { ConceptLLMMapper } from '../../../mappers/concept-llm-mapper';
import { DEFAULT_STATE } from '../concept-llm.state';

export class ConceptLlmUseCases {
  constructor(private conceptLlmApiService: ConceptLlmApiService) {}

  getConceptLLM(context: StateContext<ConceptLLMStateModel>, action: GetConceptLLM) {
    context.patchState({
      isLoadingConceptLLM: true
    });
    return this.conceptLlmApiService.getConceptLLM({ conceptId: action.conceptId }).pipe(
      tap((response: GetConceptLLMResponse) => {
        context.patchState({
          conceptLLMList: response.conceptLLMList.map(term => ConceptLLMMapper.mapToConceptLLM(term)),
          isLoadingConceptLLM: false,
          isDirty: false
        });
      }),
      catchError(err => {
        context.patchState({
          isLoadingConceptLLM: false,
          isDirty: false
        });
        return throwError(err);
      })
    );
  }

  upsertConceptLLM(context: StateContext<ConceptLLMStateModel>, action: UpsertConceptLLM) {
    return this.conceptLlmApiService
      .upsertConceptLLM({
        conceptId: action.payload.conceptId,
        conceptLLMList: action.payload.conceptLLMList
      })
      .pipe(
        tap(_response => {
          context.patchState({
            conceptLLMList: action.payload.conceptLLMList,
            isDirty: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public loadAvailableLLMOptions(context: StateContext<ConceptLLMStateModel>, action: LoadAvailableLLMOptions) {
    context.patchState({ isLoadingAvailableLLMOptions: true });
    return this.conceptLlmApiService
      .getAvailableLLMOptions({
        organizationId: action.organizationId
      })
      .pipe(
        tap((response: GetAvailableLLMOptionsResponse) => {
          const data = response.llmOptionsList.map(option =>
            LlmOptionsMapper.mapLLMOptionToChipsSelectionOption(option)
          );
          context.patchState({
            availableLLMOptions: data,
            isLoadingAvailableLLMOptions: false
          });
        }),
        catchError(err => {
          context.patchState({
            isLoadingAvailableLLMOptions: false
          });
          return throwError(err);
        })
      );
  }

  updateConceptLLMLocally(context: StateContext<ConceptLLMStateModel>, action: UpdateConceptLLMLocally) {
    return context.patchState({
      conceptLLMList: [...action.payload.conceptLLMList],
      isDirty: true
    });
  }

  clear(context: StateContext<ConceptLLMStateModel>) {
    context.setState(DEFAULT_STATE);
  }
}
