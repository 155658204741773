import { StateContext } from '@ngxs/store';
import {
  ChangeLogApiService,
  CheckDeployedUsageResponse,
  CloneConceptResponse,
  ConceptApiService,
  CreateConceptResponse,
  GetConceptChangeLogResponse,
  GetConceptResponse,
  GetEditorConceptHierarchyRequest,
  GetVariablesResponse,
  SearchUsagesResponse,
  UpdateConceptResponse,
  UsagesApiService,
  VariablesApiService
} from 'generated/v3';
import { cloneDeep } from 'lodash-es';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Concept, ConceptState } from '../../../../models';
import { TaxonomyChangeLogMapper } from '../../../mappers/taxonomy-change-log-mapper';
import { TaxonomyConceptMapper } from '../../../mappers/taxonomy-concept-mapper';
import { TaxonomyHierarchyMapper } from '../../../mappers/taxonomy-hierarchy-mapper';
import { TaxonomyRelationsMapper } from '../../../mappers/taxonomy-relations-mapper';
import { TaxonomyUsagesMapper } from '../../../mappers/taxonomy-usages-mapper';
import { FileUtils } from '../../../utils/file.utils';
import {
  CheckDeployedUsages,
  CloneConcept,
  CreateConcept,
  ExportUsages,
  GetConceptRelations,
  LoadAndAppendNarrowerConceptHierarchy,
  LoadAndAppendSiblingsConceptHierarchy,
  LoadConcept,
  LoadConceptHierarchy,
  LoadMoreChangeLog,
  ResetConcept,
  SearchChangeLog,
  SearchUsages,
  SetGoBack,
  UpdateConcept,
  UpdateConceptRelations,
  UpdateConceptsRelationsLocally
} from '../taxonomy-concept.action';
import { DEFAULT_STATE, TaxonomyConceptStateModel } from '../taxonomy-concept.state';
import RelationTypeEnum = GetEditorConceptHierarchyRequest.RelationTypeEnum;
import { ErrorsDao } from '../../../daos/errors.dao';
import { VariablesMapper } from '../../../mappers/variables-mapper';

export class TaxonomyConceptStateUseCases {
  constructor(
    private taxonomyV3ApiService: ConceptApiService,
    private usagesApiService: UsagesApiService,
    private changeLogApiService: ChangeLogApiService,
    private variablesApiService: VariablesApiService
  ) {}

  public checkDeployedUsages(context: StateContext<TaxonomyConceptStateModel>, action: CheckDeployedUsages) {
    context.patchState({
      isCheckingDeployedUsages: true
    });
    return this.usagesApiService.checkDeployedUsage({ conceptId: action.conceptId }).pipe(
      tap((response: CheckDeployedUsageResponse) => {
        context.patchState({
          hasDeployedUsages: response.hasUsage,
          isCheckingDeployedUsages: false
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public loadConcept(context: StateContext<TaxonomyConceptStateModel>, action: LoadConcept) {
    context.patchState({
      isLoadingConcept: true
    });
    return this.taxonomyV3ApiService.get({ conceptId: action.conceptId }).pipe(
      tap((response: GetConceptResponse) => {
        context.patchState({
          concept: TaxonomyConceptMapper.mapToConcept(response),
          isLoadingConcept: false,
          isDirty: false,
          isDefinitionDirty: false,
          isTermDirty: false,
          hasAccess: true
        });
      }),
      catchError(err => {
        if (err.error.data?.exceptionName === ErrorsDao.NOT_ALLOWED_CONCEPT_ERROR) {
          context.patchState({ hasAccess: false });
        }
        return throwError(err);
      })
    );
  }

  public resetConcept(context: StateContext<TaxonomyConceptStateModel>, _action: ResetConcept) {
    context.patchState({
      concept: undefined,
      conceptRelations: undefined,
      isConceptRelationsDirty: false
    });
  }

  public createConcept(context: StateContext<TaxonomyConceptStateModel>, action: CreateConcept) {
    return this.taxonomyV3ApiService
      .create({
        termId: action.payload.termId,
        term: action.payload.term,
        definition: action.payload.definition,
        owner: action.payload.owner,
        ruleSetFamilyList: action.payload.ruleSetFamilyList,
        jurisdictions: action.payload.jurisdictions,
        categoryTags: action.payload.categoryTags,
        isPrivate: action.payload.isPrivate
      })
      .pipe(
        tap((response: CreateConceptResponse) => {
          const concept: Concept = {
            id: response.conceptId,
            termId: action.payload.termId,
            term: action.payload.term,
            definition: action.payload.definition,
            owner: action.payload.owner,
            ruleSets: action.payload.ruleSetFamilyList.flatMap(v => TaxonomyConceptMapper.mapToConceptRuleSetRef(v)),
            jurisdictions: action.payload.jurisdictions,
            categories: action.payload.categoryTags,
            isPrivate: action.payload.isPrivate,
            state: ConceptState.Active,
            updatedAt: response.updateDate
          };

          context.patchState({
            conceptId: response.conceptId,
            concept: concept,
            isLoadingConcept: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateConcept(context: StateContext<TaxonomyConceptStateModel>, action: UpdateConcept) {
    return this.taxonomyV3ApiService
      .update({
        id: action.payload.id,
        termId: action.payload.termId,
        term: action.payload.term,
        definition: action.payload.definition,
        owner: action.payload.owner,
        ruleSetFamilyList: action.payload.ruleSetFamilyList,
        jurisdictions: action.payload.jurisdictions,
        categories: action.payload.categories,
        isPrivate: action.payload.isPrivate,
        state: action.payload.state,
        updateDate: action.payload.updatedAt,
        translations: action.payload.translations
      })
      .pipe(
        tap((response: UpdateConceptResponse) => {
          const concept = cloneDeep(context.getState().concept);
          concept.termId = action.payload.termId;
          concept.term = action.payload.term;
          concept.definition = action.payload.definition;
          concept.owner = action.payload.owner;
          concept.ruleSets = action.payload.ruleSetFamilyList.flatMap(v =>
            TaxonomyConceptMapper.mapToConceptRuleSetRef(v)
          );
          concept.jurisdictions = action.payload.jurisdictions;
          concept.categories = action.payload.categories;
          concept.isPrivate = action.payload.isPrivate;
          concept.state = TaxonomyConceptMapper.mapToConceptState(action.payload.state.toUpperCase());
          concept.updatedAt = response.updateDate;

          context.patchState({
            conceptId: response.conceptId,
            concept: concept,
            isDirty: false,
            isDefinitionDirty: false,
            isTermDirty: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public cloneConcept(context: StateContext<TaxonomyConceptStateModel>, action: CloneConcept) {
    return this.taxonomyV3ApiService
      .clone({
        baseConceptId: action.payload.id,
        termId: action.payload.termId,
        owner: action.payload.owner,
        ruleSetFamilyList: action.payload.ruleSetFamilyList,
        jurisdictions: action.payload.jurisdictions
      })
      .pipe(
        tap((response: CloneConceptResponse) => {
          context.patchState({
            conceptId: response.conceptId
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public searchUsages(context: StateContext<TaxonomyConceptStateModel>, action: SearchUsages) {
    context.patchState({
      isLoadingConceptUsages: true
    });

    return this.usagesApiService
      .searchUsages(TaxonomyUsagesMapper.mapToSearchRequest(action.payload.searchCriteria, action.payload.conceptId))
      .pipe(
        tap((response: SearchUsagesResponse) => {
          context.patchState({
            conceptUsages: response.searchUsages.map(result => TaxonomyUsagesMapper.mapToUsageSearchResult(result)),
            isLoadingConceptUsages: false,
            conceptUsagesTotal: response.count
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public exportUsages(_context: StateContext<TaxonomyConceptStateModel>, action: ExportUsages) {
    return this.usagesApiService
      .exportUsages(TaxonomyUsagesMapper.mapToExportRequest(action.payload.searchCriteria, action.payload.conceptId))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'usageList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public searchChangeLog(context: StateContext<TaxonomyConceptStateModel>, action: SearchChangeLog) {
    context.patchState({
      isLoadingConceptChangeLog: true
    });
    return this.changeLogApiService
      .getConceptChangeLog({
        conceptId: action.payload.conceptId,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize
      })
      .pipe(
        tap((response: GetConceptChangeLogResponse) => {
          context.patchState({
            conceptChangeLogByDay: TaxonomyChangeLogMapper.convertToChangeLogByDay(
              response,
              action.payload.pageNumber,
              action.payload.pageSize
            ),
            isLoadingConceptChangeLog: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public loadMoreChangeLog(context: StateContext<TaxonomyConceptStateModel>, action: LoadMoreChangeLog) {
    return this.changeLogApiService
      .getConceptChangeLog({
        conceptId: action.payload.conceptId,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize
      })
      .pipe(
        tap((response: GetConceptChangeLogResponse) => {
          const conceptChangeLogByDay = cloneDeep(context.getState().conceptChangeLogByDay);
          context.patchState({
            conceptChangeLogByDay: TaxonomyChangeLogMapper.aggregateChangeLog(
              conceptChangeLogByDay,
              TaxonomyChangeLogMapper.convertToChangeLogByDay(
                response,
                action.payload.pageNumber,
                action.payload.pageSize
              )
            )
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getConceptRelations(context: StateContext<TaxonomyConceptStateModel>, action: GetConceptRelations) {
    context.patchState({
      isLoadingConceptRelations: true
    });
    return this.taxonomyV3ApiService
      .getConceptRelations({
        conceptId: action.payload.conceptId
      })
      .pipe(
        tap(response => {
          context.patchState({
            conceptRelations: TaxonomyRelationsMapper.convertToRelations(response.relations),
            isLoadingConceptRelations: false,
            isConceptRelationsDirty: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateConceptRelations(context: StateContext<TaxonomyConceptStateModel>, action: UpdateConceptRelations) {
    context.patchState({
      conceptRelationsUpdating: true
    });

    const relationsToUpdate = TaxonomyRelationsMapper.convertToUpdateConceptRelation(
      action.payload.narrowerRelations,
      action.payload.broaderRelations,
      action.payload.relatedRelations
    );
    return this.taxonomyV3ApiService
      .updateConceptRelations({
        conceptId: action.payload.conceptId,
        relations: relationsToUpdate
      })
      .pipe(
        tap(response => {
          const addedRelations = TaxonomyRelationsMapper.convertToAddedConceptRelations(response.addedRelations);

          const relations = [
            ...action.payload.narrowerRelations,
            ...action.payload.relatedRelations,
            ...action.payload.broaderRelations
          ];

          addedRelations.forEach(addedRelation => {
            relations
              .filter(relation => addedRelation.relationType === relation.relationType)
              .find(related => related.targetConceptId === addedRelation.targetConceptId).relationId =
              addedRelation.relationId;
          });

          context.patchState({
            conceptRelationsUpdating: false,
            conceptRelations: relations,
            isConceptRelationsDirty: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public updateConceptsRelationsLocally(
    context: StateContext<TaxonomyConceptStateModel>,
    action: UpdateConceptsRelationsLocally
  ) {
    context.patchState({
      conceptRelations: [
        ...action.payload.relatedRelations,
        ...action.payload.broaderRelations,
        ...action.payload.narrowerRelations
      ],
      isConceptRelationsDirty: action.payload.conceptRelationChanged
    });
  }

  public loadConceptHierarchy(context: StateContext<TaxonomyConceptStateModel>, action: LoadConceptHierarchy) {
    context.patchState({
      isLoadingConceptHierarchyTree: true
    });

    return this.taxonomyV3ApiService
      .getHierarchy({
        conceptId: action.payload.conceptId,
        relationType: action.payload.relationType,
        depth: -1
      })
      .pipe(
        tap(response => {
          context.patchState({
            conceptHierarchyTree: TaxonomyHierarchyMapper.buildTreeFromBroaderRelations(response.hierarchyEntries),
            isLoadingConceptHierarchyTree: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public loadAndAppendSiblingsConceptHierarchy(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadAndAppendSiblingsConceptHierarchy
  ) {
    if (
      context
        .getState()
        .conceptHierarchyTree.some(
          c =>
            c.id === action.payload.conceptId &&
            c.parent?.id === action.payload.parentConceptId &&
            c.hasSiblingsLoaded &&
            c.parent.childrenConcepts?.length > 0
        )
    ) {
      // do nothing since concept already has the siblings loaded
      return;
    }

    let conceptHierarchyTree = context.getState().conceptHierarchyTree?.map(c => {
      if (c.id === action.payload.conceptId && c.parent?.id === action.payload.parentConceptId) {
        c.hasSiblingsLoaded = false;
      }
      return c;
    });
    context.patchState({ conceptHierarchyTree });
    return this.taxonomyV3ApiService
      .getHierarchy({
        conceptId: action.payload.parentConceptId,
        relationType: RelationTypeEnum.NARROWER,
        depth: 0
      })
      .pipe(
        tap(response => {
          conceptHierarchyTree = cloneDeep(context.getState().conceptHierarchyTree);
          TaxonomyHierarchyMapper.appendSiblings(
            action.payload.conceptId,
            action.payload.parentConceptId,
            conceptHierarchyTree,
            response.hierarchyEntries
          );
          context.patchState({ conceptHierarchyTree });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public loadAndAppendNarrowerConceptHierarchy(
    context: StateContext<TaxonomyConceptStateModel>,
    action: LoadAndAppendNarrowerConceptHierarchy
  ) {
    context.patchState({
      isAppendingConceptHierarchyTree: true
    });

    return this.taxonomyV3ApiService
      .getHierarchy({
        conceptId: action.payload.conceptId,
        relationType: RelationTypeEnum.NARROWER,
        depth: 0
      })
      .pipe(
        tap(response => {
          const conceptHierarchies = cloneDeep(context.getState().conceptHierarchyTree);
          TaxonomyHierarchyMapper.appendNarrowerRelations(
            action.payload.conceptId,
            conceptHierarchies,
            response.hierarchyEntries
          );
          context.patchState({
            conceptHierarchyTree: conceptHierarchies,
            isAppendingConceptHierarchyTree: false
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public clear(context: StateContext<TaxonomyConceptStateModel>) {
    const goBackType = cloneDeep(context.getState().goBack);

    context.setState(DEFAULT_STATE);

    context.patchState({
      goBack: goBackType
    });
  }

  public setGoBack(context: StateContext<TaxonomyConceptStateModel>, action: SetGoBack) {
    context.patchState({
      goBack: action.payload.type
    });
  }

  public loadVariables(context: StateContext<TaxonomyConceptStateModel>) {
    return this.variablesApiService.getVariables().pipe(
      tap((response: GetVariablesResponse) => {
        const data = response.variables.map(variable => VariablesMapper.mapToVariable(variable));
        context.patchState({
          listVariables: data
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
