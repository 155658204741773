import { StateContext } from '@ngxs/store';
import { CollectionsStateModel } from '../collections.state';
import { CollectionsMapper } from '../../../mappers/collections-mapper';
import {
  AddToCollection,
  CreateCollection,
  DeleteCollection,
  EditCollection,
  ExportCollection,
  ExportTerms,
  GetCollections,
  IsCollectionNameValid,
  IsCollectionNameValidExcludeSelf,
  LoadAffectMyCollectionNames,
  RemoveFromCollection,
  RemoveFromMyCollection,
  SetKeepCollectionState,
  SetSelectedCollection
} from '../collections.action';
import { catchError, finalize, tap } from 'rxjs/operators';
import { GenericUtils } from '../../../utils/generic.utils';
import { throwError } from 'rxjs';
import { LinkableGroupsMapper } from '../../../mappers/linkable-groups-mapper';
import { CollectionsService, SearchConceptsByCollectionRequest } from '../../../../../generated/v3';
import { FileUtils } from '../../../utils/file.utils';
import { StorageData } from '@apiax/web-commons';

export class CollectionsStateUseCases {
  constructor(private collectionsService: CollectionsService) {}

  getMyCollections(context: StateContext<CollectionsStateModel>) {
    context.patchState({ isLoading: true });
    return this.collectionsService.accessibleCollections().pipe(
      finalize(() =>
        context.patchState({
          isLoading: false
        })
      ),
      tap(value => {
        context.patchState({
          myCollections: CollectionsMapper.mapToCollectionModel(value.userLists || []),
          mySharedCollections: CollectionsMapper.mapToCollectionModel(value.userSharedLists || []),
          collectionsSharedWithMe: CollectionsMapper.mapToCollectionModel(value.listsSharedWithUser || [])
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  loadAffectedMyCollectionNames(context: StateContext<CollectionsStateModel>, action: LoadAffectMyCollectionNames) {
    context.patchState({ isLoadingAffectedMyCollectionNames: true });
    return this.collectionsService
      .affectedMyCollectionNames({
        excludedIds: action.payload.excludedIds,
        selectedIds: action.payload.selectedIds
      })
      .pipe(
        finalize(() =>
          context.patchState({
            isLoadingAffectedMyCollectionNames: false
          })
        ),
        tap(names => {
          context.patchState({
            affectMyCollectionNames: names
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  saveSelectedCollection(context: StateContext<CollectionsStateModel>, action: SetSelectedCollection) {
    context.patchState({
      selectedCollection: action.selectedEntry
    });
  }

  getCollections(context: StateContext<CollectionsStateModel>, action: GetCollections) {
    context.patchState({
      isLoadingCollections: true
    });
    return this.collectionsService
      .collectionsGet({
        types: action.collectionTypes.map(t => CollectionsMapper.mapFromCollectionType(t))
      })
      .pipe(
        finalize(() =>
          context.patchState({
            isLoadingCollections: false
          })
        ),
        tap(response => {
          context.patchState({
            collections: CollectionsMapper.mapToCollectionDetailModel(response.results)
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  isCollectionNameValid(context: StateContext<CollectionsStateModel>, action: IsCollectionNameValid) {
    return this.collectionsService.collectionsValidateName(action).pipe(
      tap(response => {
        context.patchState({
          isCollectionNameValid: response.valid
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  isCollectionNameValidExcludeSelf(
    context: StateContext<CollectionsStateModel>,
    action: IsCollectionNameValidExcludeSelf
  ) {
    return this.collectionsService
      .collectionsValidateNameExcludeSelf({
        name: action.payload.name,
        collectionId: action.payload.collectionId
      })
      .pipe(
        tap(response => {
          context.patchState({
            isCollectionNameValidExcludeSelf: response.valid
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  createCollection(context: StateContext<CollectionsStateModel>, action: CreateCollection) {
    return this.collectionsService.collectionCreate(CollectionsMapper.mapToCreateCollectionRequest(action));
  }

  addToCollection(context: StateContext<CollectionsStateModel>, action: AddToCollection) {
    return this.collectionsService.addConceptToCollection(
      CollectionsMapper.mapToAddDocumentToCollectionRequest(action)
    );
  }

  removeFromMyCollection(context: StateContext<CollectionsStateModel>, action: RemoveFromMyCollection) {
    return this.collectionsService
      .removeFromMyCollection({
        excludedIds: action.payload.excludedIds,
        includedIds: action.payload.selectedIds
      })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  removeFromCollection(context: StateContext<CollectionsStateModel>, action: RemoveFromCollection) {
    return this.collectionsService
      .removeConceptFromCollection({
        collectionId: action.payload.collectionId,
        excludedIds: action.payload.excludedIds,
        includedIds: action.payload.includedIds
      })
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }

  editCollection(context: StateContext<CollectionsStateModel>, action: EditCollection) {
    return this.collectionsService.updateCollectionInfo({
      collectionId: action.payload.id,
      name: action.payload.name,
      sharedGroups: action.payload.sharedGroups
    });
  }

  deleteCollection(context: StateContext<CollectionsStateModel>, action: DeleteCollection) {
    return this.collectionsService.deleteCollection(action.id);
  }

  loadGroups(context: StateContext<CollectionsStateModel>) {
    context.patchState({
      isLoadingGroups: true
    });
    return this.collectionsService.getLinkableGroups().pipe(
      finalize(() =>
        context.patchState({
          isLoadingGroups: false
        })
      ),
      tap(response => {
        const data = response.groups.map(obj => LinkableGroupsMapper.mapFromLinkableGroupInfoToModel(obj));
        context.patchState({
          groups: GenericUtils.sortCollection(data, 'name')
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  exportCollection(context: StateContext<CollectionsStateModel>, action: ExportCollection) {
    return this.collectionsService
      .exportCollection({
        collectionId: action.payload.collectionId,
        sortBy: CollectionsMapper.mapToSortBy(action.payload.sortBy),
        sortDirection: CollectionsMapper.mapToSortOrder(action.payload.sortDirection),
        includedConceptIds: action.payload.selectedIds,
        excludedConceptIds: action.payload.excludedIds
      })
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'collectionList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  exportTerms(context: StateContext<CollectionsStateModel>, action: ExportTerms) {
    return this.collectionsService
      .exportTerms({
        sortBy: CollectionsMapper.mapToSortBy(action.payload.sortBy),
        sortDirection: CollectionsMapper.mapToSortOrder(action.payload.sortDirection),
        includedConceptIds: action.payload.selectedIds,
        excludedConceptIds: action.payload.excludedIds
      })
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'collectionList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  setCollectionsStorageData(context: StateContext<CollectionsStateModel>, storageData: StorageData) {
    context.patchState({
      storageData
    });
  }

  setDataByCollection(
    context: StateContext<CollectionsStateModel>,
    searchConceptsByCollectionRequest: SearchConceptsByCollectionRequest
  ) {
    context.patchState({
      isLoadingSearchCollections: true
    });
    return this.collectionsService.searchByCollection(searchConceptsByCollectionRequest).pipe(
      tap(response => {
        context.patchState({
          searchCollections: response.list.map(result => CollectionsMapper.mapToConceptCollection(result)),
          isLoadingSearchCollections: false,
          totalSearchCollections: response.total
        });
      })
    );
  }

  setMyCollectionsData(
    context: StateContext<CollectionsStateModel>,
    searchConceptsByCollectionRequest: SearchConceptsByCollectionRequest
  ) {
    context.patchState({
      isLoadingSearchCollections: true
    });
    return this.collectionsService.searchMyCollection(searchConceptsByCollectionRequest).pipe(
      tap(response => {
        context.patchState({
          searchCollections: response.list.map(result => CollectionsMapper.mapToConceptCollection(result)),
          isLoadingSearchCollections: false,
          totalSearchCollections: response.total
        });
      })
    );
  }

  setKeepCollectionState(context: StateContext<CollectionsStateModel>, request: SetKeepCollectionState) {
    context.patchState({
      keepCollectionState: request.payload
    });
  }
}
