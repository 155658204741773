import { SearchCriteria } from '@apiax/web-commons';
import { v4 as uuidv4 } from 'uuid';
import {
  ExportUsagesFilters,
  SearchUsageConceptReference,
  SearchUsageResult,
  SearchUsagesRequest
} from '../../../generated/v3';
import { ConceptUsage, ConceptUsageReference, ConceptUsageRuleType } from '../../models/concept-usage';

export class TaxonomyUsagesMapper {
  static mapToSearchRequest(searchCriteria: SearchCriteria, conceptId: string): SearchUsagesRequest {
    const mapToSearch = this.mapToUsageRequest(conceptId, searchCriteria);
    mapToSearch['pageNumber'] = searchCriteria.page;
    mapToSearch['pageSize'] = searchCriteria.pageSize;
    return mapToSearch;
  }

  static mapToUsageSearchResult(usage: SearchUsageResult): ConceptUsage {
    return {
      id: uuidv4(),
      conceptId: usage.conceptId,
      termId: usage.termId,
      ruleId: usage.ruleId,
      type: ConceptUsageRuleType[usage.type],
      ruleLabel: usage.ruleLabel,
      usageTypeLabel: usage.usageTypeLabel,
      referencedBy: this.mapToUsageSearchResultConceptReference(usage.referencedBy)
    };
  }

  static mapToUsageSearchResultConceptReference(reference: SearchUsageConceptReference): ConceptUsageReference {
    if (!reference) {
      return undefined;
    }
    return {
      conceptId: reference.conceptId,
      label: reference.label,
      term: reference.term
    };
  }

  static mapToExportRequest(searchCriteria: SearchCriteria, conceptId: string): ExportUsagesFilters {
    return this.mapToUsageRequest(conceptId, searchCriteria);
  }

  private static mapToUsageRequest(conceptId: string, searchCriteria: SearchCriteria) {
    return {
      conceptId: conceptId,
      ruleSetIds: searchCriteria.contentFilters['ruleSets'] || [],
      ruleSetFamilyIds: searchCriteria.contentFilters['ruleSetFamilies'] || [],
      activationStates: searchCriteria.contentFilters['activationStates'] || [],
      jurisdictions: searchCriteria.contentFilters['jurisdictions'] || [],
      contentBy: searchCriteria.contentFilters['contentBy'] || [],
      usageTypeGroups: searchCriteria.contentFilters['usageTypeGroups'] || []
    };
  }
}
