<div class="search-container">
  <span class="search-button" [matTooltip]="'Search'" [matTooltipPosition]="'above'">
    <mat-icon class="search-icon">search</mat-icon>
  </span>
  <input
    #searchNode
    matInput
    (keyup)="onKeyup($event)"
    (search)="clearValue(searchNode)"
    type="search"
    class="search-box"
    [placeholder]="placeholder"
  />
</div>
