import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isEmpty, isNil } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap } from 'rxjs/operators';
import { TaxonomyUtilsService } from '../../../../domain/services/taxonomy-utils.service';
import { ClearOrganizationState } from '../../../../domain/stores/organizations/organizations.action';
import {
  ClearTaxonomyConceptState,
  CloneConcept,
  LoadConcept,
  LoadConceptTranslation,
  LoadTranslationLanguages,
  TaxonomyConceptState,
  UpdateIsDirty
} from '../../../../domain/stores/taxonomy-concept';
import { ConceptUtils } from '../../../../domain/utils/concept.utils';
import { Concept } from '../../../../models';
import {
  CloneConceptModalComponent,
  CloneConceptModalData
} from '../../../shared-components/modals/clone-concept/clone-concept-modal.component';
import { Activity, AuthorizationService } from 'shared/core';
import { ClearConceptLLMState } from '../../../../domain/stores/llm-term-definitions/concept-llm.action';
import { ConceptLlmState } from '../../../../domain/stores/llm-term-definitions/concept-llm.state';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-concept',
  templateUrl: './taxonomy-concept.component.html',
  styleUrls: ['./taxonomy-concept.component.scss']
})
export class TaxonomyConceptComponent implements OnInit {
  hasAccess = false;
  private conceptUtils: ConceptUtils;
  private isTermEditorTranslationMode = false;
  public isConceptLoading$ = new BehaviorSubject<boolean>(true);
  public hasAccessConceptLLM = false;
  public currentUserPermissions = new Map<Activity, boolean>();
  noAccessMessage =
    '<div class="restricted-text">Access to this page is restricted. <br> Please contact <span class="email-customer"> customersuccess@apiax.com </span> in order to grant you access. Thank you</div>';
  hasViewTermPermissions = true;

  constructor(
    private router: Router,
    location: Location,
    private store: Store,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private authorizationService: AuthorizationService,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.conceptUtils = new ConceptUtils(router, dialog, location, store);
    this.getCurrentUserPermissions();
    this.hasAccessConceptLLM =
      this.currentUserPermissions.get(Activity.LLMTermDefinitions) &&
      (this.currentUserPermissions.get(Activity.ViewTerms) || this.currentUserPermissions.get(Activity.EditTerms));
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(val => val instanceof NavigationEnd),
        switchMap(() => {
          const snapshot = this.router.routerState?.snapshot;
          const conceptIdQueryParam = snapshot?.root?.queryParams?.conceptId;
          const language = snapshot?.root?.queryParams?.language;
          const urlParts = snapshot.url.split('?')[0].split('/');
          const urlPath = urlParts[urlParts.length - 1];

          const snapshotConcept: Concept = this.store.selectSnapshot(TaxonomyConceptState.concept);
          // make sure conceptIdQueryParam exists otherwise we could have snapshotConcept?.id (undefined) !== snapshotConcept (null).
          // this condition is true but does not make sense
          if (conceptIdQueryParam && snapshotConcept?.id !== conceptIdQueryParam) {
            this.isConceptLoading$.next(true);
            return this.store.dispatch(new LoadConcept(conceptIdQueryParam)).pipe(
              switchMap(() => this.store.dispatch(new LoadTranslationLanguages())),
              switchMap(() => this.loadTranslations(conceptIdQueryParam, language)),
              map(() => ({
                createMode: false,
                urlPath
              })),
              catchError(err => {
                return of(null);
              })
            );
          } else {
            return of({
              createMode: isNil(conceptIdQueryParam),
              urlPath
            });
          }
        })
      )
      .subscribe((res: { createMode?: boolean; urlPath?: string }) => {
        this.isConceptLoading$.next(false);
        if (!isEmpty(res)) {
          switch (res?.urlPath) {
            case 'term-editor':
              taxonomyUtilsService.conceptSelectedTabIndex = 0;
              taxonomyUtilsService.isCreateMode$.next(res.createMode);
              this.isTermEditorTranslationMode = false;
              taxonomyUtilsService.isTermEditorTranslationMode$.next(this.isTermEditorTranslationMode);
              break;
            case 'term-editor-translation':
              taxonomyUtilsService.conceptSelectedTabIndex = 0;
              taxonomyUtilsService.isCreateMode$.next(res.createMode);
              this.isTermEditorTranslationMode = true;
              taxonomyUtilsService.isTermEditorTranslationMode$.next(this.isTermEditorTranslationMode);
              break;
            case 'llm-definitions':
              const conceptId = this.store.selectSnapshot(TaxonomyConceptState.concept)?.id;
              const selectedLanguages = this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)
                ?.selectedTranslationLanguage?.code;
              if (!this.hasAccessConceptLLM) {
                this.router.navigate(
                  [
                    'concept',
                    this.isTermEditorTranslationMode && selectedLanguages !== 'en'
                      ? 'term-editor-translation'
                      : 'term-editor'
                  ],
                  {
                    queryParams: {
                      conceptId: conceptId,
                      language: selectedLanguages
                    }
                  }
                );
              } else {
                taxonomyUtilsService.conceptSelectedTabIndex = 1;
                taxonomyUtilsService.isCreateMode$.next(res.createMode);
              }
              break;
            case 'relations':
              if (this.hasAccessConceptLLM) {
                taxonomyUtilsService.conceptSelectedTabIndex = 2;
              } else {
                taxonomyUtilsService.conceptSelectedTabIndex = 1;
              }
              taxonomyUtilsService.isCreateMode$.next(res.createMode);
              break;
            case 'usage':
              if (this.hasAccessConceptLLM) {
                taxonomyUtilsService.conceptSelectedTabIndex = 3;
              } else {
                taxonomyUtilsService.conceptSelectedTabIndex = 2;
              }
              taxonomyUtilsService.isCreateMode$.next(res.createMode);
              break;
            case 'change-log':
              if (this.hasAccessConceptLLM) {
                taxonomyUtilsService.conceptSelectedTabIndex = 4;
              } else {
                taxonomyUtilsService.conceptSelectedTabIndex = 3;
              }
              taxonomyUtilsService.isCreateMode$.next(res.createMode);
              break;
          }
        }
      });
  }

  ngOnInit() {
    this.hasViewTermPermissions = this.authorizationService.currentUser
      .getValue()
      .activities.includes(Activity.ViewTerms);
    this.store.dispatch(new UpdateIsDirty({ isDirty: false }));
    this.store
      .select(TaxonomyConceptState.hasAccess)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.hasAccess = res;
      });
  }

  public tabChanged(event: MatTabChangeEvent) {
    if (this.taxonomyUtilsService.previousConceptTabIndex === event.index) {
      return;
    }
    const conceptId = this.store.selectSnapshot(TaxonomyConceptState.concept)?.id;
    const selectedLanguages = this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)
      ?.selectedTranslationLanguage?.code;
    switch (event.index) {
      case 0:
        this.router.navigate(
          [
            'concept',
            this.isTermEditorTranslationMode && selectedLanguages !== 'en' ? 'term-editor-translation' : 'term-editor'
          ],
          {
            queryParams: {
              conceptId: conceptId,
              language: selectedLanguages
            }
          }
        );
        break;
      case 1:
        if (this.hasAccessConceptLLM) {
          this.router.navigate(['concept', 'llm-definitions'], {
            queryParams: { conceptId: conceptId }
          });
        } else {
          this.router.navigate(['concept', 'relations'], {
            queryParams: { conceptId: conceptId }
          });
        }
        break;
      case 2:
        if (this.hasAccessConceptLLM) {
          this.router.navigate(['concept', 'relations'], {
            queryParams: { conceptId: conceptId }
          });
        } else {
          this.router.navigate(['concept', 'usage'], {
            queryParams: { conceptId: conceptId }
          });
        }
        break;
      case 3:
        if (this.hasAccessConceptLLM) {
          this.router.navigate(['concept', 'usage'], {
            queryParams: { conceptId: conceptId }
          });
        } else {
          this.router.navigate(['concept', 'change-log'], {
            queryParams: { conceptId: conceptId }
          });
        }
        break;
      case 4:
        this.router.navigate(['concept', 'change-log'], {
          queryParams: { conceptId: conceptId }
        });
        break;
    }
  }

  public isTermEditorTab() {
    return this.taxonomyUtilsService.conceptSelectedTabIndex === 0;
  }

  public goBack() {
    this.router.navigate([this.conceptUtils.getBackTypePath()]).then(() => {
      this.store.dispatch(new ClearTaxonomyConceptState());
      this.store.dispatch(new ClearOrganizationState());
      this.store.dispatch(new ClearConceptLLMState());
    });
  }

  public onClone() {
    return (callback: () => void) => {
      this.openCloneConceptModal()
        .pipe(
          first(),
          switchMap(response => {
            if (response) {
              return this.store.dispatch(
                new CloneConcept({
                  id: response.id,
                  termId: response.termId,
                  owner: response.owner,
                  ruleSetFamilyList: response.ruleSetFamilyList,
                  jurisdictions: response.jurisdictions
                })
              );
            } else {
              return of(response);
            }
          }),
          switchMap(response => {
            const isLLMDefinitionsFormDirty = this.store.selectSnapshot(ConceptLlmState.isConceptLLMDirty);
            if (isLLMDefinitionsFormDirty) {
              return this.store.dispatch(new ClearConceptLLMState());
            } else {
              return of(response);
            }
          }),
          finalize(() => {
            callback();
          })
        )
        .subscribe(response => {
          if (response) {
            this.notificationService.showSimpleAlert('Concept cloned successfully', 'success');
            this.conceptUtils.navigateToConceptTermEditor(this.store.selectSnapshot(TaxonomyConceptState.conceptId));
          }
        });
    };
  }

  public openCloneConceptModal() {
    const concept = this.store.selectSnapshot(TaxonomyConceptState.concept);
    const data: CloneConceptModalData = {
      id: concept.id,
      termId: concept.termId,
      owner: concept.owner,
      ruleSets: concept.ruleSets,
      jurisdictions: concept.jurisdictions
    };

    const dialogRef = this.dialog.open(CloneConceptModalComponent, {
      data: data,
      ...CloneConceptModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed();
  }

  private loadTranslations(conceptIdQueryParam: string, language: string) {
    const snapshotConcept = this.store.selectSnapshot(TaxonomyConceptState.concept);
    return this.store.dispatch(
      new LoadConceptTranslation({
        existingTranslations: snapshotConcept.translations,
        selectedTranslation: language || 'en'
      })
    );
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }
}
