import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CompareViewService } from '../../../../generated/v3';
import { FilterOption } from '../../../models';
import { RuleSetFilterOption } from '../../../models/rule-set-filter-option';
import { CollectionsCompareViewFiltersStateUseCases } from './usecases/collections-compare-view-filters-usecases.state';
import {
  LoadCollectionsCompareViewFilterOptions,
  ResetCollectionsCompareViewFiltersData
} from './collections-compare-view-filters.action';

export interface CollectionsCompareViewFiltersStateModel {
  ruleSetFamilyOptions: FilterOption[];
  ruleSetOptions: RuleSetFilterOption[];
  ownerOptions: FilterOption[];
  jurisdictionOptions: FilterOption[];
  translationOptions: FilterOption[];
  categoryOptions: FilterOption[];
  areFiltersLoaded: boolean;
}

export const DEFAULT_STATE: CollectionsCompareViewFiltersStateModel = {
  ruleSetFamilyOptions: [],
  ruleSetOptions: [],
  ownerOptions: [],
  jurisdictionOptions: [],
  translationOptions: [],
  categoryOptions: [],
  areFiltersLoaded: false
};

@State<CollectionsCompareViewFiltersStateModel>({
  name: 'collectionsCompareViewFilters',
  defaults: DEFAULT_STATE
})
@Injectable()
export class CollectionsCompareViewFiltersState {
  private collectionsCompareViewFiltersStateUseCases: CollectionsCompareViewFiltersStateUseCases;

  @Selector()
  public static ruleSetFamilyOptions(state: CollectionsCompareViewFiltersStateModel): FilterOption[] {
    return state.ruleSetFamilyOptions;
  }

  @Selector()
  public static ruleSetOptions(state: CollectionsCompareViewFiltersStateModel): RuleSetFilterOption[] {
    return state.ruleSetOptions;
  }

  @Selector()
  public static ownerOptions(state: CollectionsCompareViewFiltersStateModel): FilterOption[] {
    return state.ownerOptions;
  }

  @Selector()
  public static jurisdictionOptions(state: CollectionsCompareViewFiltersStateModel): FilterOption[] {
    return state.jurisdictionOptions;
  }

  @Selector()
  public static translationOptions(state: CollectionsCompareViewFiltersStateModel): FilterOption[] {
    return state.translationOptions;
  }

  @Selector()
  public static categoryOptions(state: CollectionsCompareViewFiltersStateModel): FilterOption[] {
    return state.categoryOptions;
  }

  @Selector()
  public static areFiltersLoaded(state: CollectionsCompareViewFiltersStateModel): boolean {
    return state.areFiltersLoaded;
  }

  constructor(compareViewApiService: CompareViewService) {
    this.collectionsCompareViewFiltersStateUseCases = new CollectionsCompareViewFiltersStateUseCases(
      compareViewApiService
    );
  }

  @Action(LoadCollectionsCompareViewFilterOptions)
  public loadCollectionsCompareViewFilterOptions(
    context: StateContext<CollectionsCompareViewFiltersStateModel>,
    action: LoadCollectionsCompareViewFilterOptions
  ) {
    return this.collectionsCompareViewFiltersStateUseCases.loadCollectionsCompareViewFilterOptions(context, action);
  }

  @Action(ResetCollectionsCompareViewFiltersData)
  public resetData(
    context: StateContext<CollectionsCompareViewFiltersStateModel>,
    action: ResetCollectionsCompareViewFiltersData
  ) {
    return this.collectionsCompareViewFiltersStateUseCases.resetData(context, action);
  }
}
