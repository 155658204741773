<div class="taxonomy-latest-changes-wrapper">
  <apx-loading-spinner [isLoading]="isLoading$">
    <div class="taxonomy-latest-changes-list-container">
      <apx-data-table
        #table
        [search]="search"
        [entity]="'Latest Change'"
        [rowIcon]="rowIcon"
        [initialPage]="page"
        [data]="searchResults"
        [totalData]="totalResults"
        [columns]="columns"
        [pageSize]="PAGE_SIZE"
        [filters]="filters"
        [noResultsMessage]="'No latest changes found'"
        [idFetcher]="idFetcher"
        [initialLoadingState]="true"
        [showDownload]="true"
        [downloadPermission]="downloadPermission"
        [downloadTooltip]="'Download Latest Changes List'"
        [isDownloading$]="isDownloading$"
        [currentUserPermissions]="taxonomyUtilsService.currentUserPermissions"
        (onFetch)="fetchData($event)"
        (onDownload)="downloadData($event)"
      ></apx-data-table>
    </div>
  </apx-loading-spinner>
</div>
