import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ChipsWithAdditionOption,
  CountrySliderOption,
  NotificationService,
  TemplatePickerOption
} from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { camelCase, cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { getConfig } from 'shared/app';
import { PhotoService } from 'shared/core';
import { ErrorsDao } from '../../../../../domain/daos/errors.dao';
import { PhotoDAO } from '../../../../../domain/daos/photo.dao';
import { TaxonomyConceptMapper } from '../../../../../domain/mappers/taxonomy-concept-mapper';
import { TaxonomyUtilsService } from '../../../../../domain/services/taxonomy-utils.service';
import {
  LoadAccessibleOwners,
  LoadProductRuleSets
} from '../../../../../domain/stores/organizations/organizations.action';
import { OrganizationsState } from '../../../../../domain/stores/organizations/organizations.state';
import {
  AddConceptTranslation,
  CheckDeployedUsages,
  CreateConcept,
  LoadConcept,
  LoadConceptTranslation,
  LoadTranslationLanguages,
  TaxonomyConceptState,
  UpdateConcept,
  UpdateConceptLocally,
  UpdateConceptTermAndDefinition,
  UpdateSelectedLanguage
} from '../../../../../domain/stores/taxonomy-concept';
import { ConceptUtils } from '../../../../../domain/utils/concept.utils';
import { Concept, ConceptState, ProductRuleSet } from '../../../../../models';
import { ConceptsJurisdictionsModalData } from '../../../../shared-components/modals/concept-jurisdictions/concept-jurisdictions-modal.component';
import { ConceptRuleSetsModalData } from '../../../../shared-components/modals/concept-rule-sets/concept-rule-sets-modal.component';
import {
  DeployedUsageModalComponent,
  DeployedUsageModalData
} from '../../../../shared-components/modals/deployed-usage/deployed-usage-modal.component';
import {
  OutdatedConceptModalComponent,
  OutdatedConceptModalData
} from '../../../../shared-components/modals/outdated-concept/outdated-concept-modal.component';
import {
  TranslationLanguageModalComponent,
  TranslationLanguageModalData
} from '../../../../shared-components/modals/translation-language/translation-language-modal.component';

enum ConceptForm {
  Id = 'id',
  Term = 'term',
  TermId = 'termId',
  Owner = 'owner',
  RuleSets = 'ruleSets',
  Jurisdictions = 'jurisdictions',
  Categories = 'categories',
  Privacy = 'privacy',
  Status = 'status',
  Definition = 'definition',
  Slider = 'slider'
}

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-term-editor',
  templateUrl: './taxonomy-term-editor.component.html',
  styleUrls: ['./taxonomy-term-editor.component.scss']
})
export class TaxonomyTermEditorComponent implements OnInit, OnDestroy {
  private readonly DEFAULT_LANGUAGE = 'en';

  @Input()
  isConceptAlreadyLoaded: boolean;

  public isLoading$: Observable<boolean>;
  public isFormLoaded = false;

  public isCreateMode: boolean;

  public isLoadingOwners$: Observable<boolean>;
  public isLoadingProductRuleSets$: Observable<boolean>;
  public isLoadingConcept$: Observable<boolean>;

  public areOwnersLoaded$ = new BehaviorSubject(false);
  public areProductRuleSetsLoaded$ = new BehaviorSubject(false);
  public arePrivacyValuesLoaded$ = new BehaviorSubject(false);
  public areStatusValuesLoaded$ = new BehaviorSubject(false);
  public conceptUtils: ConceptUtils;

  private concept: Concept;

  public form = new UntypedFormGroup({});
  public formNames = ConceptForm;

  public availableOwners$: Observable<TemplatePickerOption[]>;
  private availableOwners: TemplatePickerOption[];

  public sliderOptions: CountrySliderOption[] = [];
  public selectSliderAdd = false;

  public availablePrivacyValues$: Observable<TemplatePickerOption[]>;
  private availablePrivacyValues: TemplatePickerOption[];

  public availableStatusValues$: Observable<TemplatePickerOption[]>;
  private availableStatusValues: TemplatePickerOption[];

  private availableProductRuleSets: Map<string, ProductRuleSet[]>;
  private productRuleSets: ProductRuleSet[];

  private selectedRuleSets: ChipsWithAdditionOption[] = [];

  private photoDAO: PhotoDAO;

  public get privacyTooltip(): string {
    return ConceptUtils.privacyTooltip(this.form.get(ConceptForm.Privacy).value?.id);
  }

  public get statusTooltip(): string {
    return ConceptUtils.statusTooltip(this.form.get(ConceptForm.Status).value?.id);
  }

  constructor(
    photoService: PhotoService,
    location: Location,
    private router: Router,
    private store: Store,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.photoDAO = new PhotoDAO(photoService);

    this.conceptUtils = new ConceptUtils(router, dialog, location, store);

    this.initCreateMode();

    this.initLoading();
  }

  ngOnInit() {
    this.loadStaticValues();
    this.initForm();
    this.loadOwnersAndProductRuleSets();
    this.subscribeFormControlsValueChanges();
    this.checkIfConceptIsDirty();
  }

  ngOnDestroy() {
    // Needed to keep changes on concept when changing tab to usages / relations / change logs
    this.updateConceptLocally();
  }

  private initCreateMode() {
    this.taxonomyUtilsService.isCreateMode$.pipe(untilDestroyed(this)).subscribe(value => {
      this.isCreateMode = value;
    });
  }

  private initLoading() {
    this.isLoadingOwners$ = this.store.select(OrganizationsState.isLoadingAccessibleOwners);
    this.isLoadingProductRuleSets$ = this.store.select(OrganizationsState.isLoadingProductRuleSets);
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);

    this.isLoading$ = combineLatest([
      this.isLoadingOwners$,
      this.isLoadingProductRuleSets$,
      this.isCreateMode ? of(false) : this.isLoadingConcept$,
      this.areOwnersLoaded$,
      this.areProductRuleSetsLoaded$,
      this.arePrivacyValuesLoaded$,
      this.areStatusValuesLoaded$
    ]).pipe(
      untilDestroyed(this),
      map(
        ([
          isLoadingOwners,
          isLoadingProductRuleSets,
          isLoadingConcept,
          areOwnersLoaded,
          areProductRuleSetsLoaded,
          arePrivacyValuesLoaded,
          areStatusValuesLoaded
        ]) => {
          const isLoading = isLoadingOwners || isLoadingProductRuleSets || isLoadingConcept;
          const areLoaded =
            areOwnersLoaded && areProductRuleSetsLoaded && arePrivacyValuesLoaded && areStatusValuesLoaded;

          return isLoading || !areLoaded;
        }
      )
    );

    this.isLoading$
      .pipe(
        filter(isLoading => !isLoading),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.setForm();
      });

    this.subscribeConceptAndTranslations$();
  }

  private subscribeConceptAndTranslations$() {
    this.subscribeConcept()
      .pipe(
        filter(concept => !!concept?.id),
        switchMap((concept: Concept) => {
          this.concept = concept;
          return this.subscribeTranslationsLanguages(concept);
        })
      )
      .subscribe(sliderOptions => (this.sliderOptions = sliderOptions));
  }

  private subscribeConcept(): Observable<Concept> {
    return this.store.select(TaxonomyConceptState.concept).pipe(
      untilDestroyed(this),
      filter(concept => !!concept),
      filter(concept => !isEqual(this.concept, concept))
    );
  }

  private subscribeTranslationsLanguages(concept: Concept): Observable<CountrySliderOption[]> {
    const availableTranslationLanguagesInStore = this.store.selectSnapshot(
      TaxonomyConceptState.getConceptTranslations
    )?.availableTranslationLanguages;

    if (!availableTranslationLanguagesInStore?.length) {
      return this.store.dispatch(new LoadTranslationLanguages()).pipe(
        switchMap(() => {
          const selectedLanguage =
            this.router.routerState.snapshot.root.queryParamMap.get('language') || this.DEFAULT_LANGUAGE;
          return this.store.dispatch(
            new LoadConceptTranslation({
              existingTranslations: concept.translations,
              selectedTranslation: selectedLanguage
            })
          );
        }),
        switchMap(() => {
          return [
            ConceptUtils.convertToCountrySliderOption(
              this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)?.availableTranslationLanguages
            )
          ];
        })
      );
    } else {
      return of(
        ConceptUtils.convertToCountrySliderOption(
          this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)?.availableTranslationLanguages
        )
      );
    }
  }

  private initForm() {
    this.form = new UntypedFormGroup({});

    this.form.addControl(
      ConceptForm.Id,
      new UntypedFormControl(undefined, this.isCreateMode ? [] : Validators.required)
    );
    this.form.addControl(ConceptForm.Term, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(
      ConceptForm.TermId,
      new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ConceptUtils.TERM_ID_PATTERN)])
    );
    this.form.addControl(ConceptForm.Owner, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.RuleSets, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Jurisdictions, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Categories, new UntypedFormControl(undefined, []));
    this.form.addControl(ConceptForm.Privacy, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(
      ConceptForm.Status,
      new UntypedFormControl(undefined, this.isCreateMode ? [] : Validators.required)
    );
    this.form.addControl(ConceptForm.Definition, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Slider, new UntypedFormControl());
  }

  private setForm() {
    this.setTranslationLanguagesOptions();

    if (this.isCreateMode) {
      this.setOwnerControlOnCreate();
      this.setRuleSetsControlOnCreate();
      this.setJurisdictionsControlOnCreate();
      this.setPrivacyControlOnCreate();
    } else {
      this.form.get(ConceptForm.Id).setValue(this.concept.id);
      this.form.get(ConceptForm.Id).disable({ emitEvent: false });
      this.form.get(ConceptForm.Term).setValue(this.concept.term);
      this.taxonomyUtilsService.canUpdateConcept()
        ? this.form.get(ConceptForm.Term).enable({ emitEvent: false })
        : this.form.get(ConceptForm.Term).disable({ emitEvent: false });
      this.form.get(ConceptForm.TermId).setValue(this.concept.termId);
      this.taxonomyUtilsService.canUpdateConcept()
        ? this.form.get(ConceptForm.TermId).enable({ emitEvent: false })
        : this.form.get(ConceptForm.TermId).disable({ emitEvent: false });
      this.setOwnerControlOnViewUpdate();
      this.setCategoriesControlOnViewUpdate();
      this.setPrivacyControlOnViewUpdate();
      this.setStatusControlOnViewUpdate();
      this.form.get(ConceptForm.Definition).setValue(this.concept.definition);
      this.taxonomyUtilsService.canUpdateConcept()
        ? this.form.get(ConceptForm.Definition).enable({ emitEvent: false })
        : this.form.get(ConceptForm.Definition).disable({ emitEvent: false });

      this.setRuleSetsControlOnViewUpdate();
      this.setJurisdictionsControlOnViewUpdate();
    }

    this.filterProductRuleSets();
    this.isFormLoaded = true;
  }

  private loadOwnersAndProductRuleSets() {
    this.loadOwners();
    this.loadProductRuleSets();
  }

  private loadStaticValues() {
    this.loadPrivacyValues();
    this.loadStatusValues();
  }

  private loadOwners() {
    const ownersInStore = this.store.selectSnapshot(OrganizationsState.accessibleOwners);
    if (!ownersInStore?.length) {
      this.store
        .dispatch(new LoadAccessibleOwners())
        .pipe(first())
        .subscribe(() => {
          const owners = this.store.selectSnapshot(OrganizationsState.accessibleOwners);
          this.availableOwners = owners?.map(obj => {
            return {
              id: obj.id,
              label: obj.name,
              image: this.photoDAO.getPhotoUrl(obj.photoId)
            };
          });

          this.areOwnersLoaded$.next(true);

          this.availableOwners$ = of(this.availableOwners);
        });
    } else {
      this.availableOwners = ownersInStore?.map(obj => {
        return {
          id: obj.id,
          label: obj.name,
          image: this.photoDAO.getPhotoUrl(obj.photoId)
        };
      });

      this.areOwnersLoaded$.next(true);

      this.availableOwners$ = of(this.availableOwners);
    }
  }

  private loadProductRuleSets() {
    let availableProductRuleSets;

    availableProductRuleSets = this.store.selectSnapshot(OrganizationsState.productRuleSets);

    if (!availableProductRuleSets?.size) {
      this.store
        .dispatch(new LoadProductRuleSets())
        .pipe(first())
        .subscribe(() => {
          availableProductRuleSets = this.store.selectSnapshot(OrganizationsState.productRuleSets);
          this.availableProductRuleSets = cloneDeep(availableProductRuleSets);
          this.areProductRuleSetsLoaded$.next(true);
        });
    } else {
      this.availableProductRuleSets = cloneDeep(availableProductRuleSets);
      this.areProductRuleSetsLoaded$.next(true);
    }
  }

  private loadPrivacyValues() {
    this.availablePrivacyValues = ConceptUtils.PRIVACY_VALUES;

    this.availablePrivacyValues$ = of(this.availablePrivacyValues);

    this.arePrivacyValuesLoaded$.next(true);
  }

  private loadStatusValues() {
    this.availableStatusValues = ConceptUtils.STATUS_VALUES;

    this.areStatusValuesLoaded$.next(true);
  }

  private setOwnerControlOnCreate() {
    if (!this.taxonomyUtilsService.hasAllCompaniesViewAccess()) {
      const value = this.availableOwners.find(
        obj => obj.id === this.taxonomyUtilsService.getCurrentUser().organizationId
      );

      this.form.get(ConceptForm.Owner).setValue(value, { emitEvent: false });
      this.form.get(ConceptForm.Owner).disable({ emitEvent: false });
    }
  }

  private setOwnerControlOnViewUpdate() {
    const owner = this.availableOwners?.find(obj => obj.id === this.concept.owner);

    this.form.get(ConceptForm.Owner).setValue(owner, { emitEvent: false });

    this.taxonomyUtilsService.canUpdateConcept(true)
      ? this.form.get(ConceptForm.Owner).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Owner).disable({ emitEvent: false });
  }

  private setRuleSetsControlOnCreate() {
    this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];

    this.form.get(ConceptForm.RuleSets).setValue([ConceptUtils.RULESET_ANY_VALUE_OPTION], { emitEvent: false });
  }

  private setRuleSetsControlOnViewUpdate() {
    const ruleSets = this.conceptUtils.mapRuleSets(this.availableProductRuleSets, this.concept.ruleSets);

    this.selectedRuleSets = ruleSets.selections;

    this.form.get(ConceptForm.RuleSets).setValue(ruleSets.newValues, { emitEvent: false });

    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.RuleSets).enable({ emitEvent: false })
      : this.form.get(ConceptForm.RuleSets).disable({ emitEvent: false });
  }

  private setJurisdictionsControlOnCreate() {
    this.form
      .get(ConceptForm.Jurisdictions)
      .setValue([ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION], { emitEvent: false });
  }

  private setJurisdictionsControlOnViewUpdate() {
    const jurisdictions = this.conceptUtils.mapJurisdictions(this.availableProductRuleSets, this.concept.jurisdictions);

    this.form.get(ConceptForm.Jurisdictions).setValue(jurisdictions, { emitEvent: false });

    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.Jurisdictions).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Jurisdictions).disable({ emitEvent: false });
  }

  private setCategoriesControlOnViewUpdate() {
    const categories = new Set(this.concept.categories);

    this.form.get(ConceptForm.Categories).setValue(categories);
    // TODO should investigate why web-commons chips is not loading the values correctly without the setTimeout
    setTimeout(() => {
      this.form.get(ConceptForm.Categories).setValue(categories);
    }, 5);
    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.Categories).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Categories).disable({ emitEvent: false });
  }

  private setPrivacyControlOnCreate(shouldEmit = false) {
    if (!this.taxonomyUtilsService.isValueApiax(this.form.get(ConceptForm.Owner).value?.id)) {
      const value = this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PRIVATE_PRIVACY_VALUE);

      this.form.get(ConceptForm.Privacy).setValue(value, { emitEvent: shouldEmit });
      this.form.get(ConceptForm.Privacy).disable({ emitEvent: shouldEmit });
    } else {
      const value = this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PUBLIC_PRIVACY_VALUE);

      this.form.get(ConceptForm.Privacy).setValue(value, { emitEvent: shouldEmit });
      this.form.get(ConceptForm.Privacy).enable({ emitEvent: shouldEmit });
    }
  }

  private setPrivacyControlOnViewUpdate(shouldEmit = false) {
    const conceptOwner = this.form.get(ConceptForm.Owner).value;
    let privacy;

    if (shouldEmit) {
      privacy = !this.taxonomyUtilsService.isValueApiax(conceptOwner?.id)
        ? this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PRIVATE_PRIVACY_VALUE)
        : this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PUBLIC_PRIVACY_VALUE);
    } else {
      privacy = this.concept.isPrivate
        ? this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PRIVATE_PRIVACY_VALUE)
        : this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PUBLIC_PRIVACY_VALUE);
    }

    this.form.get(ConceptForm.Privacy).setValue(privacy, { emitEvent: shouldEmit });

    this.taxonomyUtilsService.canUpdateConceptPrivacy(conceptOwner?.id)
      ? this.form.get(ConceptForm.Privacy).enable({ emitEvent: shouldEmit })
      : this.form.get(ConceptForm.Privacy).disable({ emitEvent: shouldEmit });
  }

  private setStatusControlOnViewUpdate() {
    let value;

    switch (this.concept.state) {
      case ConceptState.Active:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.ACTIVE_STATUS_VALUE);
        break;
      case ConceptState.Deprecated:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.DEPRECATED_STATUS_VALUE);
        break;
      case ConceptState.Inactive:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.INACTIVE_STATUS_VALUE);
        break;
    }

    this.form.get(ConceptForm.Status).setValue(value, { emitEvent: false });

    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.Status).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Status).disable({ emitEvent: false });

    this.availableStatusValues$ = of(this.availableStatusValues);
  }

  private setTranslationLanguagesOptions() {
    const selectedTranslationLanguage = this.store.selectSnapshot(
      TaxonomyConceptState.getConceptTranslations
    )?.selectedTranslationLanguage;
    const sliderSelectedOption = selectedTranslationLanguage?.code
      ? {
          id: selectedTranslationLanguage.code,
          flagCode: selectedTranslationLanguage.country,
          label: selectedTranslationLanguage.name
        }
      : { id: 'en', label: '', flagCode: 'GBR' };

    this.form.get(ConceptForm.Slider).setValue(sliderSelectedOption);
  }

  private subscribeFormControlsValueChanges() {
    this.onTermValueChange();
    this.onDefinitionChange();
    this.onOwnerValueChange();
    this.onRuleSetsValueChange();
    this.onJurisdictionsValueChange();
    this.onCategoriesValueChange();
    this.onLanguageSelectionValueChange();
  }

  private checkIfConceptIsDirty() {
    /**
     * When we change to term-editor-translation we lose context.
     * so when we change to term-editor-translation we need to save if the form is safe
     * we must check this state when we move between term-editor and term-editor-translation
     * */
    if (this.store.selectSnapshot(TaxonomyConceptState.isDirty)) {
      this.form.markAsDirty();
    }
    if (this.store.selectSnapshot(TaxonomyConceptState.isDefinitionDirty)) {
      this.form.get(ConceptForm.Definition).markAsDirty();
    }
    if (this.store.selectSnapshot(TaxonomyConceptState.isTermDirty)) {
      this.form.get(ConceptForm.Term).markAsDirty();
    }
  }

  private onTermValueChange() {
    this.form
      .get(ConceptForm.Term)
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term: string) => {
        if (this.isCreateMode) {
          if (!this.form.get(ConceptForm.TermId).dirty || isEmpty(this.form.get(ConceptForm.TermId).value)) {
            this.store.dispatch(new UpdateConceptTermAndDefinition({ term: term }));
            this.form.get(ConceptForm.TermId).setValue(term ? camelCase(term) : '');
            this.form.get(ConceptForm.TermId).markAsPristine();
          }
        }
      });
  }

  private onDefinitionChange() {
    this.form
      .get(ConceptForm.Definition)
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((definition: string) => {
        this.store.dispatch(new UpdateConceptTermAndDefinition({ definition: definition }));
      });
  }

  private onOwnerValueChange() {
    this.form
      .get(ConceptForm.Owner)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => {
        if (typeof value !== 'string') {
          let notifyUser = false;

          const ruleSets = this.form.get(ConceptForm.RuleSets).value;

          if (!(ruleSets.length === 1 && isEqual(ruleSets[0].id, ConceptUtils.RULESET_ANY_VALUE_OPTION.id))) {
            this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];
            this.form.get(ConceptForm.RuleSets).setValue([ConceptUtils.RULESET_ANY_VALUE_OPTION], { emitEvent: false });
            notifyUser = true;
          }

          const jurisdictions = this.form.get(ConceptForm.Jurisdictions).value;

          if (
            !(
              jurisdictions.length === 1 &&
              isEqual(jurisdictions[0].id, ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION.id)
            )
          ) {
            this.form
              .get(ConceptForm.Jurisdictions)
              .setValue([ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION], { emitEvent: false });
            notifyUser = true;
          }

          if (notifyUser) {
            this.notificationService.showSimpleAlert(
              'Rule Sets / Jurisdictions were cleared because of owner change.',
              'info'
            );
          }

          this.filterProductRuleSets();

          if (this.isCreateMode) {
            this.setPrivacyControlOnCreate(true);
          } else {
            this.setPrivacyControlOnViewUpdate(true);
          }
        }
      });
  }

  private onRuleSetsValueChange() {
    this.form
      .get(ConceptForm.RuleSets)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        if (isEmpty(values)) {
          this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];
          this.form.get(ConceptForm.RuleSets).setValue([ConceptUtils.RULESET_ANY_VALUE], { emitEvent: false });
        } else {
          this.selectedRuleSets = this.selectedRuleSets.filter(obj => {
            return values.some(v => obj.id.startsWith(v.id + '#'));
          });

          this.form.get(ConceptForm.RuleSets).setValue(values, { emitEvent: false });
        }

        this.filterProductRuleSets();

        this.form.markAsDirty();
      });
  }

  private onJurisdictionsValueChange() {
    this.form
      .get(ConceptForm.Jurisdictions)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        if (isEmpty(values)) {
          this.form.get(ConceptForm.Jurisdictions).setValue(
            [
              {
                id: ConceptUtils.JURISDICTION_GLOBAL_VALUE.id,
                label: ConceptUtils.JURISDICTION_GLOBAL_VALUE.label,
                removable: false
              }
            ],
            { emitEvent: false }
          );
        } else {
          this.form.get(ConceptForm.Jurisdictions).setValue(values, { emitEvent: false });
        }

        this.filterProductRuleSets();

        this.form.markAsDirty();
      });
  }

  private onCategoriesValueChange() {
    this.form
      .get(ConceptForm.Categories)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(values => {
        const categories = new Set(this.concept?.categories);

        if (!isEqual(categories, values)) {
          this.form.markAsDirty();
        }
      });
  }

  private onLanguageSelectionValueChange() {
    this.form
      .get(ConceptForm.Slider)
      .valueChanges.pipe(
        untilDestroyed(this),
        switchMap(value => {
          if (value.id !== this.DEFAULT_LANGUAGE) {
            this.updateConceptLocally();
            return this.store
              .dispatch(
                new UpdateSelectedLanguage({
                  selectedLanguage: value.id
                })
              )
              .pipe(map(() => value));
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(value => {
        if (value) {
          const conceptId = this.store.selectSnapshot(TaxonomyConceptState.conceptId);
          this.conceptUtils.navigateToConceptTermEditorTranslate(conceptId, value.id);
        }
      });
  }

  public updateConceptLocally() {
    const concept = this.store.selectSnapshot(TaxonomyConceptState.concept);

    if (concept && this.isFormLoaded) {
      return this.store.dispatch(
        new UpdateConceptLocally({
          concept: this.getUpdateConcept(),
          isDirty: this.form.dirty,
          isTermDirty: this.form.get(ConceptForm.Term).dirty,
          isDefinitionDirty: this.form.get(ConceptForm.Definition).dirty
        })
      );
    } else {
      return of(false);
    }
  }

  private filterProductRuleSets() {
    const owner = this.form.get(ConceptForm.Owner).value;
    const ruleSets = this.form.get(ConceptForm.RuleSets).value;
    const jurisdictions = this.form.get(ConceptForm.Jurisdictions).value;

    this.productRuleSets = this.conceptUtils.filterProductRuleSets(
      this.availableProductRuleSets,
      owner,
      ruleSets,
      jurisdictions,
      this.selectedRuleSets
    );
  }

  public openRuleSetsModal() {
    const data: ConceptRuleSetsModalData = {
      productRuleSets: this.productRuleSets,
      selections: this.selectedRuleSets ? this.selectedRuleSets.map(obj => obj.id) : []
    };

    this.conceptUtils
      .openRuleSetsModal(data, this.selectedRuleSets, this.productRuleSets)
      .pipe(first())
      .subscribe((obj: { selections: any; newValues: ChipsWithAdditionOption[] }) => {
        if (obj) {
          this.selectedRuleSets = obj.selections;
          this.form.get(ConceptForm.RuleSets).setValue(obj.newValues);
        }
      });
  }

  public ruleSetsAdditionalInfo = value => {
    return this.conceptUtils.ruleSetsAdditionalInfo(this.selectedRuleSets, value);
  };

  public ruleSetsAdditionalInfoTooltip = value => {
    return this.conceptUtils.ruleSetsAdditionalInfoTooltip(this.selectedRuleSets, value);
  };

  public openJurisdictionsModal() {
    const data: ConceptsJurisdictionsModalData = {
      productRuleSets: this.productRuleSets,
      selections: this.form.get(ConceptForm.Jurisdictions).value
        ? this.form.get(ConceptForm.Jurisdictions).value.map(obj => obj.id)
        : []
    };

    this.conceptUtils
      .openJurisdictionsModal(data)
      .pipe(first())
      .subscribe(values => {
        if (values) {
          this.form.get(ConceptForm.Jurisdictions).setValue(values);
        }
      });
  }

  public onSubmit() {
    return (callback: () => void) => {
      if (this.isCreateMode) {
        this.store
          .dispatch(this.getCreateConceptAction())
          .pipe(
            first(),
            catchError(err => {
              this.checkIssues(err.error);
              return EMPTY;
            }),
            finalize(() => {
              callback();
            })
          )
          .subscribe(() => {
            this.notificationService.showSimpleAlert('Concept created successfully', 'success');
            this.conceptUtils.navigateToConceptTermEditor(this.store.selectSnapshot(TaxonomyConceptState.conceptId));
            this.taxonomyUtilsService.isCreateMode$.next(false);
            this.setForm();
            this.form.markAsPristine();
            this.form.markAsUntouched();
          });
      } else {
        let updateActionObservable: Observable<any>;
        const translations = this.store.selectSnapshot(TaxonomyConceptState.concept).translations;
        const availableConceptTranslations = this.store.selectSnapshot(
          TaxonomyConceptState.getConceptTranslations
        ).availableConceptTranslations;
        const hasChangedTranslation = !isEqual(translations, availableConceptTranslations);
        if (
          hasChangedTranslation ||
          this.form.get(ConceptForm.Term).dirty ||
          this.form.get(ConceptForm.Definition).dirty
        ) {
          updateActionObservable = this.checkDeployedAndUpdateAction();
        } else {
          updateActionObservable = this.store.dispatch(this.getUpdateConceptAction());
        }

        return updateActionObservable
          .pipe(
            catchError(err => {
              this.checkIssues(err.error);
              return EMPTY;
            }),
            finalize(() => {
              callback();
            })
          )
          .subscribe(result => {
            if (result) {
              this.form.markAsPristine();
              this.form.markAsUntouched();
              this.notificationService.showSimpleAlert('Concept updated successfully', 'success');
              this.conceptUtils.navigateToConceptTermEditor(this.store.selectSnapshot(TaxonomyConceptState.conceptId));
            }
          });
      }
    };
  }

  private checkDeployedAndUpdateAction() {
    let shouldRedirectToLatestDeployed = false;
    return this.store.dispatch(new CheckDeployedUsages(this.concept.id)).pipe(
      first(),
      switchMap(() => {
        const hasDeployedUsages = this.store.selectSnapshot(TaxonomyConceptState.hasDeployedUsages);
        if (hasDeployedUsages) {
          return this.openDeployedUsageModal().pipe(
            tap(value => {
              shouldRedirectToLatestDeployed = value;
            })
          );
        } else {
          return of(true);
        }
      }),
      switchMap(response => {
        if (response) {
          if (shouldRedirectToLatestDeployed) {
            window.open(`${getConfig('rulesWebUrl')}/latestDeployedDocuments?conceptId=${this.concept.id}`, '_blank');
          }
          return this.store.dispatch(this.getUpdateConceptAction());
        } else {
          return of(false);
        }
      })
    );
  }

  private checkIssues(error) {
    if (error?.message === ErrorsDao.OUTDATED_CONCEPT_ERROR) {
      this.openOutdatedConceptModal();
    } else {
      return throwError(error);
    }
  }

  private getCreateConceptAction() {
    return new CreateConcept({
      termId: this.form.get(ConceptForm.TermId).value,
      term: this.form.get(ConceptForm.Term).value,
      definition: this.form.get(ConceptForm.Definition).value,
      owner: this.form.get(ConceptForm.Owner).value.id,
      ruleSetFamilyList: TaxonomyConceptMapper.mapToGeneratedConceptRuleSets(this.selectedRuleSets),
      jurisdictions: TaxonomyConceptMapper.mapToGeneratedConceptJurisdictions(
        this.form.get(ConceptForm.Jurisdictions).value
      ),
      categoryTags: TaxonomyConceptMapper.mapToGeneratedConceptCategories(this.form.get(ConceptForm.Categories).value),
      isPrivate: isEqual(this.form.get(ConceptForm.Privacy).value.id, ConceptUtils.PRIVATE_PRIVACY_VALUE)
    });
  }

  private getUpdateConceptAction() {
    return new UpdateConcept({
      id: this.form.get(ConceptForm.Id).value,
      termId: this.form.get(ConceptForm.TermId).value,
      term: this.form.get(ConceptForm.Term).value,
      definition: this.form.get(ConceptForm.Definition).value,
      owner: this.form.get(ConceptForm.Owner).value.id,
      ruleSetFamilyList: TaxonomyConceptMapper.mapToGeneratedConceptRuleSets(this.selectedRuleSets),
      jurisdictions: TaxonomyConceptMapper.mapToGeneratedConceptJurisdictions(
        this.form.get(ConceptForm.Jurisdictions).value
      ),
      categories: TaxonomyConceptMapper.mapToGeneratedConceptCategories(this.form.get(ConceptForm.Categories).value),
      isPrivate: isEqual(this.form.get(ConceptForm.Privacy).value.id, ConceptUtils.PRIVATE_PRIVACY_VALUE),
      state: TaxonomyConceptMapper.mapToGeneratedConceptState(this.form.get(ConceptForm.Status).value.id),
      updatedAt: this.store.selectSnapshot(TaxonomyConceptState.concept).updatedAt,
      translations: this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)?.availableConceptTranslations
    });
  }

  private getUpdateConcept(): Concept {
    const categories = TaxonomyConceptMapper.mapToGeneratedConceptCategories(
      this.form.get(ConceptForm.Categories).value
    );
    return {
      id: this.form.get(ConceptForm.Id).value,
      termId: this.form.get(ConceptForm.TermId).value,
      term: this.form.get(ConceptForm.Term).value,
      definition: this.form.get(ConceptForm.Definition).value,
      owner: this.form.get(ConceptForm.Owner).value.id,
      ruleSets: TaxonomyConceptMapper.mapToConceptRuleSets(this.selectedRuleSets),
      jurisdictions: TaxonomyConceptMapper.mapToGeneratedConceptJurisdictions(
        this.form.get(ConceptForm.Jurisdictions).value
      ),
      categories: categories,
      isPrivate: isEqual(this.form.get(ConceptForm.Privacy).value.id, ConceptUtils.PRIVATE_PRIVACY_VALUE),
      state: this.form.get(ConceptForm.Status).value.id,
      updatedAt: this.store.selectSnapshot(TaxonomyConceptState.concept).updatedAt,
      translations: this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations).availableConceptTranslations
    };
  }

  public copyID() {
    const conceptId = this.form.get(ConceptForm.Id).value;
    navigator.clipboard.writeText(conceptId);

    this.notificationService.showSimpleAlert('The concept ID has been copied.', 'info');
  }

  public openDeployedUsageModal() {
    const data: DeployedUsageModalData = {
      title: 'Warning',
      message:
        'The term you’re updating is being used in deployed versions of rules. If you want these changes to be reflected in those rules, new versions must be created.' +
        '<br><br>By continuing the term will be updated and you’ll be prompted to create new versions of the latest versions of deployed impacted rules.',
      actionLabel: 'Update term and continue'
    };

    const dialogRef = this.dialog.open(DeployedUsageModalComponent, {
      data: data,
      ...DeployedUsageModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed();
  }

  public openTranslationLanguageModal() {
    this.selectSliderAdd = true;
    const translationLanguagesExcludingSelected = this.store
      .selectSnapshot(TaxonomyConceptState.translationLanguages)
      .filter(tl => !this.sliderOptions.map(lo => lo.id).includes(tl.code) && tl.code !== this.DEFAULT_LANGUAGE);
    const data: TranslationLanguageModalData = {
      translationLanguages: translationLanguagesExcludingSelected
    };

    const dialogRef = this.dialog.open(TranslationLanguageModalComponent, {
      data: data,
      ...TranslationLanguageModalComponent.DEFAULT_CONFIG
    });

    dialogRef
      .afterClosed()
      .pipe(
        first(),
        switchMap(countryCode => {
          this.selectSliderAdd = false;
          if (countryCode) {
            this.form.markAsDirty();
            return combineLatest([
              this.store.dispatch(
                new UpdateConceptLocally({
                  concept: this.getUpdateConcept(),
                  isDirty: this.form.dirty,
                  isTermDirty: this.form.get(ConceptForm.Term).dirty,
                  isDefinitionDirty: this.form.get(ConceptForm.Definition).dirty
                })
              ),
              this.store.dispatch(new AddConceptTranslation({ selectedLanguage: countryCode }))
            ]).pipe(map(() => countryCode));
          }
          return of(undefined);
        })
      )
      .subscribe(countryCode => {
        if (countryCode) {
          this.conceptUtils.navigateToConceptTermEditorTranslate(this.concept.id, countryCode);
        }
      });
  }

  public openOutdatedConceptModal() {
    const data: OutdatedConceptModalData = {
      id: null
    };

    const dialogRef = this.dialog.open(OutdatedConceptModalComponent, {
      data: data,
      ...OutdatedConceptModalComponent.DEFAULT_CONFIG
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new LoadConcept(this.concept.id));
        }
      });
  }
}
