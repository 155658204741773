<div class="compare-view-wrapper">
    <apx-data-table
      [data]="data"
      [totalData]="totalData"
      [columns]="columns"
      [filters]="filters"
      [pageSize]="pageSize"
      [noResultsMessage]="'No terms found'"
      [idFetcher]="idFetcher"
      [menuActions]="menuActions"
      [allowSelection]="false"
      (onFetch)="onFetch($event)"
      [entity]="'Term'"
      [initialLoadingState]="true"
      [showDownload]="true"
      [downloadPermission]="downloadPermission"
      [downloadTooltip]="'Download collection list'"
      [isDownloading$]="isDownloading$"
      [fullHeightRows]="true"
      [sortBy]="defaultSortBy"
      [sortOrder]="defaultSortOrder"
      [currentUserPermissions]="currentUserPermissions"
      [saveToStorage]="saveToStorageStore"
      [loadFromStorage]="loadFromStorageStore"
      (onDownload)="downloadData($event)"
      (onSelect)="onSelect($event)"
    ></apx-data-table>
</div>
