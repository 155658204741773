import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';

export enum CollectionsCompareViewAction {
  SetCollectionsCompareViewCollection = '[Set Collections Compare View Collection] sets the collection id that will be evaluated for the compare view',
  ResetCollectionsCompareViewData = '[Reset Collections Compare View Data] resets all data related to collections compare view',
  SearchCollectionsCompareView = '[Search Collections Compare View] perform a search for collection compare view concepts',
  ExportCollectionsCompareView = '[Export Collections Compare View] perform a export for collection compare view concepts',
  SetCollectionsCompareViewStorageData = '[Set Collections Compare View Storage Data] save the storage data',
  SetCollectionsCompareViewFilterStorageData = '[Set Collections Compare View Filter Storage Data] save the filter storage data'
}

export class SetCollectionsCompareViewCollection {
  public static readonly type = CollectionsCompareViewAction.SetCollectionsCompareViewCollection;

  constructor(public collectionId: string) {}
}

export class ResetCollectionsCompareViewData {
  public static readonly type = CollectionsCompareViewAction.ResetCollectionsCompareViewData;
}

export class SearchCollectionsCompareView {
  public static readonly type = CollectionsCompareViewAction.SearchCollectionsCompareView;

  constructor(public payload: { collectionId: string; searchCriteria: SearchCriteria }) {}
}

export class ExportCollectionsCompareView {
  public static readonly type = CollectionsCompareViewAction.ExportCollectionsCompareView;

  constructor(public payload: { collectionId: string; searchCriteria: SearchCriteria }) {}
}

export class SetCollectionsCompareViewStorageData {
  public static readonly type = CollectionsCompareViewAction.SetCollectionsCompareViewStorageData;

  constructor(public storageData: StorageData) {}
}

export class SetCollectionsCompareViewFilterStorageData {
  public static readonly type = CollectionsCompareViewAction.SetCollectionsCompareViewFilterStorageData;

  constructor(public storageData: Map<string, FilterData>) {}
}
