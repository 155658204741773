import { ArrayDataSource, DataSource } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  GetConceptRelations,
  LoadAndAppendNarrowerConceptHierarchy,
  LoadConceptHierarchy,
  LoadAndAppendSiblingsConceptHierarchy,
  TaxonomyConceptState
} from '../../../../../domain/stores/taxonomy-concept';
import { Concept, ConceptHierarchy, ConceptHierarchyFlatNode } from '../../../../../models';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { GetEditorConceptHierarchyRequest } from '../../../../../../generated/v3';
import RelationTypeEnum = GetEditorConceptHierarchyRequest.RelationTypeEnum;

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-concept-hierarchy',
  templateUrl: './taxonomy-concept-hierarchy.component.html',
  styleUrls: ['./taxonomy-concept-hierarchy.component.scss']
})
export class TaxonomyConceptHierarchyComponent implements OnInit {
  @Input()
  public nodePadding = 20;
  public conceptHierarchyTree$: Observable<ConceptHierarchy[]>;
  public isLoadingHierarchyTree$: Observable<boolean>;
  public dataSource: DataSource<ConceptHierarchyFlatNode>;

  currentConcept: Concept;
  treeData: ConceptHierarchyFlatNode[];
  private isExpanded: Map<string, boolean> = new Map<string, boolean>();
  private mapOfConcepts: Map<string, ConceptHierarchyFlatNode> = new Map<string, ConceptHierarchyFlatNode>();

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToConcept();
    this.initConceptHierarchyTree();
    this.initTree();
  }

  navigateToConceptRelations(node: ConceptHierarchyFlatNode) {
    this.store
      .dispatch(
        new GetConceptRelations({
          conceptId: node.id
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['concept', 'relations'], {
          queryParams: { conceptId: node.id }
        });
      });
  }

  loadNarrowerConcepts(node: ConceptHierarchyFlatNode) {
    return this.store.dispatch(new LoadAndAppendNarrowerConceptHierarchy({ conceptId: node.id }));
  }

  loadSiblingConcepts(node: ConceptHierarchyFlatNode) {
    return node.concept?.parent?.id
      ? this.store.dispatch(
          new LoadAndAppendSiblingsConceptHierarchy({
            conceptId: node.concept.id,
            parentConceptId: node.concept.parent.id
          })
        )
      : of();
  }

  private subscribeToConcept() {
    this.store
      .select(TaxonomyConceptState.concept)
      .pipe(
        untilDestroyed(this),
        filter(concept => !!concept),
        tap(concept => this.setCurrentConcept(concept)),
        switchMap(concept => {
          return this.store
            .dispatch(
              new LoadConceptHierarchy({
                conceptId: concept.id,
                relationType: RelationTypeEnum.BROADER
              })
            )
            .pipe(map(() => concept));
        }),
        switchMap(concept => this.store.dispatch(new LoadAndAppendNarrowerConceptHierarchy({ conceptId: concept.id })))
      )
      .subscribe();
  }

  private initConceptHierarchyTree() {
    this.isLoadingHierarchyTree$ = this.store
      .select(TaxonomyConceptState.isLoadingConceptHierarchyTree)
      .pipe(untilDestroyed(this), distinctUntilChanged());

    this.conceptHierarchyTree$ = this.store.select(TaxonomyConceptState.conceptHierarchyTree).pipe(
      untilDestroyed(this),
      filter(value => value?.length > 0)
    );
  }

  toggleExpand(node: ConceptHierarchyFlatNode) {
    node.isExpanded = !node.isExpanded;
    this.isExpanded.set(node.key, node.isExpanded);
    this.initTree();
  }

  private setCurrentConcept(concept: Concept) {
    this.currentConcept = concept;
  }

  private initTree() {
    this.dataSource = new ArrayDataSource<ConceptHierarchyFlatNode>(
      this.conceptHierarchyTree$.pipe(
        map(hierarchy => {
          const rootElements = ConceptHierarchy.getRootElements(hierarchy);
          this.treeData = rootElements.reduce(ConceptHierarchyFlatNode.flattener, []);
          this.initTreeExpandStatus();
          this.mapOfConcepts.clear();
          this.treeData.forEach(t => {
            this.mapOfConcepts.set(t.key, t);
          });
          this.isExpanded.forEach((v, k) => {
            if (v !== undefined && v === false) {
              const conceptHierarchyFlatNode = this.mapOfConcepts.get(k);
              if (conceptHierarchyFlatNode) {
                conceptHierarchyFlatNode.isExpanded = false;
              }
              if (conceptHierarchyFlatNode && conceptHierarchyFlatNode.concept.childrenConcepts) {
                conceptHierarchyFlatNode.concept.childrenConcepts.forEach(childConcept => {
                  this.hide(childConcept);
                });
              }
            }
          });
          this.treeData = this.treeData.filter(elem => {
            return elem.canShow;
          });
          return this.treeData;
        })
      )
    );
  }

  private hide(concept: ConceptHierarchy) {
    const conceptHierarchyFlatNode = this.mapOfConcepts.get(concept.uid);
    if (conceptHierarchyFlatNode) {
      conceptHierarchyFlatNode.canShow = false;
      if (conceptHierarchyFlatNode.concept.childrenConcepts) {
        conceptHierarchyFlatNode.concept.childrenConcepts.forEach(child => {
          this.hide(child);
        });
      }
    }
  }

  private initTreeExpandStatus() {
    this.treeData.forEach(elem => {
      const currentExpandedStatus = this.isExpanded.get(elem.key);
      elem.isExpanded = currentExpandedStatus === undefined ? true : currentExpandedStatus;
    });
  }
}
