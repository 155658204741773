import { SearchCriteria } from '@apiax/web-commons';
import { StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConceptApiService } from '../../../../../generated/v3';
import { TaxonomySearchOnRulesStateModel } from '../taxonomy-search-on-rules-state.service';
import { TaxonomySearchOnRulesMapper } from '../../../mappers/taxonomy-search-on-rules-mapper';
import { FileUtils } from '../../../utils/file.utils';

export class TaxonomySearchOnRulesStateUseCases {
  constructor(private taxonomyV3ApiService: ConceptApiService) {}

  // Search

  public searchOnRules(context: StateContext<TaxonomySearchOnRulesStateModel>, searchCriteria: SearchCriteria) {
    context.patchState({
      isLoadingSearch: true
    });
    if (!searchCriteria.searchFilter && Object.keys(searchCriteria.contentFilters).length === 0) {
      context.patchState({
        searchResults: [],
        isLoadingSearch: false,
        totalResults: 0,
        searchCriteria: searchCriteria
      });
      return;
    }

    return this.taxonomyV3ApiService
      .conceptSearchOnRules(TaxonomySearchOnRulesMapper.mapToConceptRuleSearchRequest(searchCriteria))
      .pipe(
        tap(response => {
          context.patchState({
            searchResults: response.list.map(result =>
              TaxonomySearchOnRulesMapper.mapToConceptSearchOnRulesResult(result)
            ),
            isLoadingSearch: false,
            totalResults: response.total,
            searchCriteria: searchCriteria
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Export
  public searchOnRulesExport(context: StateContext<TaxonomySearchOnRulesStateModel>, searchCriteria: SearchCriteria) {
    if (!searchCriteria.searchFilter && Object.keys(searchCriteria.contentFilters).length === 0) {
      return;
    }

    return this.taxonomyV3ApiService
      .conceptSearchOnRulesExport(TaxonomySearchOnRulesMapper.mapToConceptSearchOnRulesExportResult(searchCriteria))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'searchOnRulesList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
