import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollapsableTreeModule, WebCommonsModule } from '@apiax/web-commons';
import { NgxsModule } from '@ngxs/store';
import { AngularSplitModule } from 'angular-split';
import { QuillModule } from 'ngx-quill';

import { getConfig } from 'shared/app/config';
import { Configuration } from '../../../../generated/v3';
import { OrganizationsState } from '../../../domain/stores/organizations/organizations.state';
import { TaxonomyConceptState } from '../../../domain/stores/taxonomy-concept';
import { TaxonomyFiltersState } from '../../../domain/stores/taxonomy-filters/taxonomy-filters.state';
import { TaxonomyMyTermsState } from '../../../domain/stores/taxonomy-my-terms/taxonomy-my-terms.state';
import { ChangelogModule } from '../../shared-components/changelog/changelog.module';
import { ModalsModule } from '../../shared-components/modals/modals.module';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';
import { TaxonomyChangeLogComponent } from './taxonomy-concept/taxonomy-change-logs/taxonomy-change-log.component';
import { TaxonomyConceptComponent } from './taxonomy-concept/taxonomy-concept.component';
import { TaxonomyConceptHierarchyComponent } from './taxonomy-concept/taxonomy-hierarchy/taxonomy-concept-hierarchy.component';
import { TaxonomyRelationsComponent } from './taxonomy-concept/taxonomy-relations/taxonomy-relations.component';
import { TaxonomyTermEditorTranslationComponent } from './taxonomy-concept/taxonomy-term-editor-translation/taxonomy-term-editor-translation.component';
import { TaxonomyTermEditorComponent } from './taxonomy-concept/taxonomy-term-editor/taxonomy-term-editor.component';
import { TaxonomyUsagesComponent } from './taxonomy-concept/taxonomy-usages/taxonomy-usages.component';
import { TaxonomyIndexComponent } from './taxonomy-index.component';
import { TaxonomyMyTermsComponent } from './taxonomy-my-terms/taxonomy-my-terms.component';
import { TaxonomyRoutingModule } from './taxonomy.routing';
import { TaxonomyLatestChangesComponent } from './taxonomy-latest-changes/taxonomy-latest-changes.component';
import { TaxonomyLatestChangesState } from '../../../domain/stores/taxonomy-latest-changes/taxonomy-latest-changes.state';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TaxonomyExportDataModule } from './taxonomy-export-data/taxonomy-export-data.module';
import { TaxonomySearchOnRulesModule } from './taxonomy-search-on-rules/taxonomy-search-on-rules.module';
import { TaxonomyLlmDefinitionsComponent } from './taxonomy-concept/taxonomy-llm-definitions/taxonomy-llm-definitions.component';
import { ConceptLlmState } from '../../../domain/stores/llm-term-definitions/concept-llm.state';
import { MyCollectionsComponent } from './my-collections/my-collections.component';
import { CollectionContentComponent } from './my-collections/collection-content/collection-content.component';
import { MyCollectionContentComponent } from './my-collections/my-collection-content/my-collection-content.component';
import { CollectionsState } from '../../../domain/stores/collections/collections.state';
import { CollectionsCompareViewComponent } from './my-collections/collections-compare-view/collections-compare-view.component';
import { CollectionsCompareViewState } from '../../../domain/stores/collections-compare-view/collections-compare-view.state';
import { CollectionsCompareViewFiltersState } from '../../../domain/stores/collections-compare-view-filters/collections-compare-view-filters.state';

export function buildConfigTaxonomyV3() {
  const config = new Configuration();
  config.basePath = getConfig('apiUrl');
  return config;
}

@NgModule({
  declarations: [
    TaxonomyIndexComponent,
    TaxonomyMyTermsComponent,
    TaxonomyConceptComponent,
    TaxonomyTermEditorComponent,
    TaxonomyTermEditorTranslationComponent,
    TaxonomyUsagesComponent,
    TaxonomyChangeLogComponent,
    TaxonomyRelationsComponent,
    TaxonomyConceptHierarchyComponent,
    TaxonomyLatestChangesComponent,
    TaxonomyLlmDefinitionsComponent,
    MyCollectionsComponent,
    CollectionContentComponent,
    MyCollectionContentComponent,
    CollectionsCompareViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TaxonomyRoutingModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxsModule.forFeature([
      OrganizationsState,
      TaxonomyMyTermsState,
      TaxonomyConceptState,
      TaxonomyFiltersState,
      TaxonomyLatestChangesState,
      ConceptLlmState,
      CollectionsState,
      CollectionsCompareViewState,
      CollectionsCompareViewFiltersState
    ]),
    WebCommonsModule,
    QuillModule,
    ModalsModule,
    MatTabsModule,
    MatIconModule,
    ChangelogModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule,
    CdkTreeModule,
    MatProgressBarModule,
    AngularSplitModule,
    SharedComponentsModule,
    ScrollingModule,
    TaxonomyExportDataModule,
    TaxonomySearchOnRulesModule,
    CollapsableTreeModule
  ]
})
export class TaxonomyModule {}
