<apx-loading-spinner [isLoading]="isLoading$">
  <div class="llm-definitions-wrapper">
    <form [formGroup]="form">
      <div class="llm-fields">
        <apx-simple-input class="llm-field" [control]="form.get(formNames.TermId)" [placeholder]="'Term ID'">
        </apx-simple-input>
        <span class="info">Set a default term and definition to be applied to all LLM providers</span>
        <apx-simple-input class="llm-field" [control]="form.get(formNames.Term)" [placeholder]="'Term'">
        </apx-simple-input>
        <apx-simple-input
          class="llm-field-textarea"
          [ngClass]="{
            'custom-error-llm-field-textarea':
              form.get(formNames.Definition).invalid &&
              (form.get(formNames.Definition).dirty || form.get(formNames.Definition).touched)
          }"
          [isTextarea]="true"
          [control]="form.get(formNames.Definition)"
          [placeholder]="'Definition'"
        >
        </apx-simple-input>
      </div>
      <div formArrayName="conceptLLMList" *ngIf="form.get(formNames.ConceptLLMList)">
        <div *ngFor="let definitionGroup of llmDefinitionsControls; let i = index" [formGroupName]="i">
          <div class="llm-fields llmDefinitions-form">
            <span class="info">Set specific terms and definitions for the LLM providers</span>
            <apx-chips-selection
              class="llm-field-chips"
              [control]="definitionGroup.get(formNames.LLMS)"
              [placeholder]="'Select LLM provider(s)'"
              [removable]="true"
              [availableOptions$]="availableOptions$"
            >
            </apx-chips-selection>
            <apx-simple-input class="llm-field" [control]="definitionGroup.get(formNames.Term)" [placeholder]="'Term'">
            </apx-simple-input>
            <apx-simple-input
              class="llm-field-textarea"
              [ngClass]="{
                'custom-error-llm-field-textarea':
                  definitionGroup.get(formNames.Definition).invalid &&
                  (definitionGroup.get(formNames.Definition).dirty || definitionGroup.get(formNames.Definition).touched)
              }"
              [isTextarea]="true"
              [control]="definitionGroup.get(formNames.Definition)"
              [placeholder]="'Definition'"
            >
            </apx-simple-input>
            <apx-action-button
              *ngIf="hasEditAccess"
              [colorClass]="'secondary-action critical'"
              [name]="'Delete LLM definition'"
              [method]="onDeleteLLMDefinition(i)"
            >
            </apx-action-button>
          </div>
        </div>
      </div>
    </form>
    <div class="llm-button-add-definition-container" *ngIf="hasEditAccess" #addLLM>
      <apx-action-button
        [colorClass]="'primary-action rounded-action'"
        [enabled]="allOptionsProviders.length > 0 && isAddLLMDefinitionBtnEnabled"
        [name]="'Add LLM definition'"
        [icon]="'add_circle'"
        [method]="addLLMDefinition"
        (click)="scrollToAddLLMButton()"
      >
      </apx-action-button>
    </div>
    <div class="llm-button-update-definition" *ngIf="hasEditAccess">
      <apx-action-button
        [colorClass]="'primary-action'"
        [name]="'Update LLM definition'"
        [enabled]="form.valid && form.dirty && isFromValueChanged"
        [method]="updateLLMDefinition"
        [showLoading]="isLoadingUpdateLLMDefinition"
      ></apx-action-button>
    </div>
  </div>
</apx-loading-spinner>
