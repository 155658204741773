<div class="taxonomy-concept-wrapper">
  <div class="taxonomy-concept-header" [class.max]="isTermEditorTab() && !(taxonomyUtilsService.isCreateMode$ | async)">
    <span (click)="goBack()" class="back-to-taxonomy" *ngIf="hasAccess || hasViewTermPermissions">Back</span>
    <ng-template [ngIf]="isTermEditorTab() && !(taxonomyUtilsService.isCreateMode$ | async) && hasAccess">
      <div class="taxonomy-concept-header-container">
        <apx-action-button
          *ngIf="taxonomyUtilsService.canCloneConcept()"
          [colorClass]="'primary-action'"
          [loadingName]="'Cloning...'"
          [name]="'Clone this term'"
          [method]="onClone()"
        ></apx-action-button>
      </div>
    </ng-template>
  </div>
  <apx-loading-spinner [isLoading]="isConceptLoading$" style="height: 100%">
    <div class="taxonomy-concept-content">
      @if (hasAccess) {
        <mat-tab-group
          animationDuration="0ms"
          (selectedTabChange)="tabChanged($event)"
          [(selectedIndex)]="taxonomyUtilsService.conceptSelectedTabIndex"
          mat-stretch-tabs="false"
          mat-align-tabs="start"
        >
          <mat-tab *ngIf="isConceptLoading$ | async" label="Term Editor">
            <!--This tab is here just to make sure no other component is loaded while loading is not completed.
            We just want to initialize app-taxonomy-term-editor after load concept and translations-->
          </mat-tab>
          <mat-tab
            *ngIf="!(isConceptLoading$ | async) && !(taxonomyUtilsService.isTermEditorTranslationMode$ | async)"
            label="Term Editor"
          >
            <ng-template matTabContent>
              <app-taxonomy-term-editor></app-taxonomy-term-editor>
            </ng-template>
          </mat-tab>
          <mat-tab
            *ngIf="!(isConceptLoading$ | async) && (taxonomyUtilsService.isTermEditorTranslationMode$ | async)"
            label="Term Editor"
          >
            <ng-template matTabContent>
              <app-taxonomy-term-editor-translation></app-taxonomy-term-editor-translation>
            </ng-template>
          </mat-tab>
          <mat-tab
            *ngIf="!(isConceptLoading$ | async) && !(taxonomyUtilsService.isCreateMode$ | async) && hasAccessConceptLLM"
            label="LLM definitions"
          >
            <ng-template matTabContent>
              <app-taxonomy-llm-definitions></app-taxonomy-llm-definitions>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="!(taxonomyUtilsService.isCreateMode$ | async)" label="Relations">
            <ng-template matTabContent>
              <app-taxonomy-relations></app-taxonomy-relations>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="!(taxonomyUtilsService.isCreateMode$ | async)" label="Usage">
            <ng-template matTabContent>
              <app-taxonomy-usages></app-taxonomy-usages>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="!(taxonomyUtilsService.isCreateMode$ | async)" label="Change Log">
            <ng-template matTabContent>
              <app-taxonomy-change-log></app-taxonomy-change-log>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      } @else {
        <apx-no-access class="h-100" [message]="noAccessMessage"></apx-no-access>
      }
    </div>
  </apx-loading-spinner>
</div>
