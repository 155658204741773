import { Component, OnInit } from '@angular/core';
import {
  ActionInput,
  Column,
  Filter,
  FILTER_TYPE,
  NotificationService,
  RowIcon,
  SearchCriteria
} from '@apiax/web-commons';
import { FilterOption as CommonsFilterOption } from '@apiax/web-commons/lib/filters/filters.interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { getConfig } from 'shared/app/config';
import { Activity, AuthorizationService, WindowService } from 'shared/core';
import { ExportUsages, SearchUsages, TaxonomyConceptState } from '../../../../../domain/stores/taxonomy-concept';
import { LoadUsageFilterOptions } from '../../../../../domain/stores/taxonomy-filters/taxonomy-filters.action';
import { TaxonomyFiltersState } from '../../../../../domain/stores/taxonomy-filters/taxonomy-filters.state';
import { Concept, ConceptUsage, ConceptUsageRuleType, FilterOption } from '../../../../../models';
import { RuleSetFilterOption } from '../../../../../models/rule-set-filter-option';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-usages',
  templateUrl: './taxonomy-usages.component.html',
  styleUrls: ['./taxonomy-usages.component.scss']
})
export class TaxonomyUsagesComponent implements OnInit {
  public readonly PAGE_SIZE = 10;

  public filters: Filter[];
  public columns: Column[];
  public downloadPermission = Activity.ViewTerms;
  public isDownloading$ = new BehaviorSubject(false);
  public currentUserPermissions = new Map<Activity, boolean>();
  public searchResults: ConceptUsage[];
  public totalResults: number;
  public isLoading$: Observable<boolean>;
  public rowIcon: RowIcon;
  isLoadingConcept$: Observable<boolean>;
  isFiltersLoaded$: Observable<boolean>;
  concept: Concept;
  columnMode: ColumnMode = ColumnMode.force;

  idFetcher = (obj: any) => obj.id;

  constructor(
    private store: Store,
    private authorizationService: AuthorizationService,
    private window: WindowService,
    private notificationService: NotificationService
  ) {
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);
    this.isFiltersLoaded$ = this.store.select(TaxonomyFiltersState.isUsageFiltersLoaded);
  }

  ngOnInit() {
    this.setupLoadings();
    this.subscribeToConcept();
    this.loadFilterOptions();
    this.buildColumns();
    this.buildRowIcon();
    this.getCurrentUserPermissions();
    this.initSubscriptions();
  }

  private setupLoadings() {
    this.isLoading$ = combineLatest([this.isLoadingConcept$, this.isFiltersLoaded$]).pipe(
      untilDestroyed(this),
      map(([isLoadingConcept, isFiltersLoaded]) => {
        return isLoadingConcept || !isFiltersLoaded;
      })
    );
  }

  private usageText(row: ConceptUsage): string {
    let referenceLabel = '';
    if (row.referencedBy) {
      referenceLabel = `<span class="usage-ref"> -  Referenced in <a href="/taxonomy/concept?conceptId=${row.referencedBy.conceptId}" target="_blank" title="${row.referencedBy.label}">${row.referencedBy.term}</a></span>`;
    }

    return row.usageTypeLabel + referenceLabel;
  }

  private usageTooltipText(row: ConceptUsage): string {
    let referenceLabel = '';
    if (row.referencedBy) {
      referenceLabel = ` - Referenced in ${row.referencedBy.term}`;
    }

    return row.usageTypeLabel + referenceLabel;
  }

  private generateLink(row: ConceptUsage): string {
    switch (row.type) {
      case ConceptUsageRuleType.COUNTRY_RULE:
        return `/countryRules/${row.ruleId}/editor/0/options/search/${row.termId}/1`;
      case ConceptUsageRuleType.CUSTOM_RULE:
        return `/countryRules/${row.ruleId}/editor/0/options/search/${row.termId}/1`;
      case ConceptUsageRuleType.TEMPLATE:
        return `/templates/${row.ruleId}/template`;
    }
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'usageTypeLabel',
        label: 'Usage',
        transformValue: (_value, row) => this.usageText(row),
        sortable: false,
        tooltip: (_value, row) => this.usageTooltipText(row),
        resizeable: true,
        size: 1
      },
      {
        prop: 'ruleLabel',
        label: 'Rule',
        transformValue: value => (isEmpty(value) ? '-' : value),
        sortable: false,
        resizeable: true,
        size: 9
      },
      {
        prop: 'rowIcon',
        label: '',
        transformValue: value => value,
        tooltip: value => value,
        extraClasses: 'center-aligned',
        sortable: false,
        resizeable: false,
        width: 20,
        maxWidth: 20
      }
    ];
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  private loadFilterOptions() {
    if (!this.store.selectSnapshot(TaxonomyFiltersState.isUsageFiltersLoaded)) {
      this.store
        .dispatch(new LoadUsageFilterOptions())
        .pipe(first())
        .subscribe(() => this.buildFilters());
    } else {
      this.buildFilters();
    }
  }

  private buildFilters() {
    combineLatest([
      this.store.select(TaxonomyFiltersState.usageRuleSetFamilyOptions),
      this.store.select(TaxonomyFiltersState.usageRuleSetOptions),
      this.store.select(TaxonomyFiltersState.jurisdictionOptions),
      this.store.select(TaxonomyFiltersState.organizationOptions),
      this.store.select(TaxonomyFiltersState.usageTypeGroupOptions),
      this.store.select(TaxonomyFiltersState.activationStateOptions)
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([ruleSetFamilies, ruleSets, jurisdictions, organizations, usageGroupTypes, activationStates]) => {
        if (
          ruleSetFamilies.length > 0 &&
          ruleSets.length > 0 &&
          organizations.length > 0 &&
          jurisdictions.length > 0 &&
          usageGroupTypes.length > 0 &&
          activationStates.length > 0
        ) {
          const ruleSetFamiliesFilter = this.buildRuleSetFamiliesFilter(ruleSetFamilies);
          const ruleSetsFilter = this.buildRuleSetsFilter(ruleSets);
          this.initReactRuleSetToRuleSetFamiliesChanges(ruleSetFamiliesFilter, ruleSets, ruleSetsFilter);
          this.filters = [
            ruleSetFamiliesFilter,
            ruleSetsFilter,
            this.buildContentByFilter(organizations),
            this.buildJurisdictionsFilter(jurisdictions),
            this.buildUsageTypeGroupsFilter(usageGroupTypes),
            this.buildActivationStatesFilter(activationStates)
          ];
        }
      });
  }

  private initReactRuleSetToRuleSetFamiliesChanges(
    ruleSetFamiliesFilter: Filter,
    ruleSets: RuleSetFilterOption[],
    ruleSetsFilter: Filter
  ) {
    ruleSetFamiliesFilter.selectionChange$.pipe(untilDestroyed(this)).subscribe(selectedRuleSetFamilyFilterOptions => {
      const selectedRuleSetFamilies = isEmpty(selectedRuleSetFamilyFilterOptions)
        ? ruleSets
        : ruleSets.filter(rs =>
            selectedRuleSetFamilyFilterOptions.find(
              selectedRuleSetFamily => selectedRuleSetFamily.value === rs.ruleSetFamilyId
            )
          );

      const ruleSetsFilterAux = this.buildRuleSetsFilter(selectedRuleSetFamilies);
      ruleSetsFilter.options.values = ruleSetsFilterAux.options.values;
      ruleSetsFilter.rebuildOptions$.next(true);
    });
  }

  private buildRuleSetFamiliesFilter(ruleSetFamiliesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSetFamilies',
      type: 'ruleSetFamilies',
      noReorder: true,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      selectionChange$: new BehaviorSubject<CommonsFilterOption[]>([]),
      name: 'Rule set family'
    };
  }

  private buildRuleSetsFilter(ruleSetFamiliesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSets',
      type: 'ruleSets',
      noReorder: false,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      rebuildOptions$: new BehaviorSubject<boolean>(false),
      name: 'Rule set'
    };
  }

  private buildJurisdictionsFilter(jurisdictionOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'jurisdictions',
      type: 'jurisdictions',
      options: {
        searchable: true,
        values: jurisdictionOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Jurisdiction'
    };
  }

  private buildContentByFilter(organizationOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'contentBy',
      type: 'contentBy',
      options: {
        searchable: true,
        values: organizationOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Content By'
    };
  }

  private buildUsageTypeGroupsFilter(usageTypeGroups: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'usageTypeGroups',
      type: 'usageTypeGroups',
      options: {
        searchable: true,
        values: usageTypeGroups.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Usage'
    };
  }

  private buildActivationStatesFilter(activationStates: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'activationStates',
      type: 'activationStates',
      options: {
        searchable: true,
        values: activationStates.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Activation State'
    };
  }

  private buildRowIcon() {
    this.rowIcon = this.buildOpenRule();
  }

  private buildOpenRule(): RowIcon {
    return {
      permissions: [],
      icon: 'open_in_new',
      iconClass: 'material-icons-outlined',
      tooltip: 'Open in a new tab',
      showIcon: row => !row.referencedBy,
      actionFunction: (input: ActionInput) => {
        this.openRule(input.rowId);
        return of(true);
      }
    };
  }

  fetchData(searchCriteria: SearchCriteria) {
    this.store.dispatch(new SearchUsages({ searchCriteria: searchCriteria, conceptId: this.concept?.id }));
  }

  private openRule(id: string) {
    const record = this.searchResults.find(r => r.id === id);
    return this.window.open(`${getConfig('rulesWebUrl')}${this.generateLink(record)}`, '_blank');
  }

  downloadData(searchCriteria: SearchCriteria) {
    this.isDownloading$.next(true);
    this.store
      .dispatch(new ExportUsages({ searchCriteria: searchCriteria, conceptId: this.concept?.id }))
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert('Usage list downloaded successfully', 'success');
      });
  }

  private initSubscriptions() {
    this.store
      .select(TaxonomyConceptState.conceptUsages)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.searchResults = results));

    this.store
      .select(TaxonomyConceptState.conceptUsagesTotal)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalResults = results));
  }

  private subscribeToConcept() {
    this.store
      .select(TaxonomyConceptState.concept)
      .pipe(
        untilDestroyed(this),
        filter(concept => !!concept)
      )
      .subscribe(concept => {
        this.concept = concept;
      });
  }
}
